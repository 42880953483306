import React from "react";
import "element-theme-default";
import { Menu, Layout } from "element-react";
import "./ToolBar.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo_menu from '../../components/images/logo_menu.svg';

function ToolBar(props) {

	return (
		<div>
			<Menu
				defaultActive={props.active}
				className={
					props.stateToolBar ? "el-menu-vertical-demo toolbar-active" : "el-menu-vertical-demo toolbar-off"
				}
				style={{ width: "250px" }}
			>
				< Link to="/home" style={{ textDecoration: "none" }}>
					<Menu.Item index="0" style={{ fontSize: "20px", color: "#ff5a50" }}>
						<i className="fas fa-chart-line" style={{ fontSize: "20px", color: "#ff5a50" }}></i> DASHBOARD
					</Menu.Item>
				</Link>

				{Math.min(props.roles.levels) <= 3 ? (
					<Menu.SubMenu index="4"
						title={
							<span>
								<i
									className="fas fa-cogs"
									style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
								></i>{" "}
								Configuración
							</span>
						}
					>
						<Menu.ItemGroup title="Configuración">
							{/* Restringir el acceso a solo los usuarios de soporte y administrador */}
							{Math.min(props.roles.levels) <= 1 ? (
								<Link to='/request' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-6">
										<i
											className="fas fa-history"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Registros Json
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 1 ? (
								<Link to='/logs' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-5">
										<i
											className="fas fa-history"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Logs
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 2 ? (
								<Link to='/notification' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-7">
										<i
											className="fa fa-commenting-o"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Notificaciones
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 1 ? (
								<Link to='/profiles' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-4">
										<i
											className="fas fa-address-card"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Perfiles
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 1 ? (
								<Link to='/roles' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-3">
										<i
											className="fas fa-user-plus"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Roles
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 3 ? (
								<Link to='/users' style={{ textDecoration: "none" }}>
									<Menu.Item index="6-2">
										<i
											className="fas fa-user"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Usuarios del sistema
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 2 ? (
								< Link to="/organizacion" style={{ textDecoration: "none" }}>
									<Menu.Item index="4-1">
										<i
											className="fas fa-table"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Organización
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 2 ? (
								< Link to="/trademark" style={{ textDecoration: "none" }}>
									<Menu.Item index="4-4">
										<i
											className="fa fa-trademark"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Marca Medidor
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 2 ? (
								< Link to="/model" style={{ textDecoration: "none" }}>
									<Menu.Item index="4-5">
										<i
											className="fa fa-cube"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Modelo Medidor
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 3 ? (
								< Link to="/sector" style={{ textDecoration: "none" }}>
									<Menu.Item index="4-2">
										<i
											className="fas fa-map-marker-alt"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>
										Sector
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 3 ? (
								< Link to="/grupo_usuarios" style={{ textDecoration: "none" }}>
									<Menu.Item index="4-3">
										<i
											className="fas fa-users"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Grupos Usuarios
									</Menu.Item>
								</Link>
							) : null}
						</Menu.ItemGroup>
					</Menu.SubMenu>
				) : null}

				{Math.min(props.roles.levels) <= 3 ? (
					< Link to="/perfil_gateway" style={{ textDecoration: "none" }}>
						<Menu.Item index="1">
							<i
								className="fas fa-wifi"
								style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
							></i>{" "}
							Gateway
						</Menu.Item>
					</Link>
				) : null}
				{Math.min(props.roles.levels) <= 3 ? (
					< Link to="/dispositivo" style={{ textDecoration: "none" }}>
						<Menu.Item index="2">
							<i
								className="fas fa-tachometer-alt"
								style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
							></i>
							Dispositivo
						</Menu.Item>
					</Link>
				) : null}
				{Math.min(props.roles.levels) <= 3 ? (
					< Link to="/virtual_meter" style={{ textDecoration: "none" }}>
						<Menu.Item index="8">
							<i
								className="el-icon-time"
								style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
							></i>
							Medidor Virtual
						</Menu.Item>
					</Link>
				) : null}

				{Math.min(props.roles.levels) === 5 || Math.min(props.roles.levels) <= 3 ? (
					<Menu.SubMenu index="5"
						title={
							<span>
								<i
									className="fa fa-bar-chart"
									style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
								></i>{" "}
								Reportes
							</span>
						}
					>
						<Menu.ItemGroup title="Reportes">

							{Math.min(props.roles.levels) <= 3 ? (
								< Link to="/consumption_profile" style={{ textDecoration: "none" }}>
									<Menu.Item index="7">
										<i
											className="fas fa fa-line-chart"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>
										Comparativo de consumos
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 3 ? (
								< Link to="/factura/crear" style={{ textDecoration: "none" }}>
									<Menu.Item index="3-1">
										<i
											className="fas fa-plus"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>
										Reporte de factura
									</Menu.Item>
								</Link>
							) : null}
							{Math.min(props.roles.levels) <= 3 ? (
								< Link to="/factura/lista" style={{ textDecoration: "none" }}>
									<Menu.Item index="3-2">
										<i
											className="fas fa-table"
											style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
										></i>{" "}
										Lista Reportes de factura
									</Menu.Item>
								</Link>
							) : null}
						</Menu.ItemGroup>
					</Menu.SubMenu>
				) : null}

				{Math.min(props.roles.levels) === 5 || Math.min(props.roles.levels) <= 3 ? (
					<Menu.SubMenu
						index="3"
						title={
							<span>
								<i
									className="fas fa-file-invoice-dollar"
									style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
								></i>{" "}
								Facturación
							</span>
						}
					>
						<Menu.ItemGroup title="Facturación">
							< Link to="/holders" style={{ textDecoration: "none" }}>
								<Menu.Item index="3-3">
									<i
										className="fa fa-user-circle"
										style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
									></i>
									Titulares
								</Menu.Item>
							</Link>
							< Link to="/cycles" style={{ textDecoration: "none" }}>
								<Menu.Item index="3-5">
									<i
										className="fa fa-refresh"
										style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
									></i>
									Ciclos de Facturación
								</Menu.Item>
							</Link>
							< Link to="/contracts" style={{ textDecoration: "none" }}>
								<Menu.Item index="3-4">
									<i
										className="fa fa-book"
										style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
									></i>
									Contratos
								</Menu.Item>
							</Link>
						</Menu.ItemGroup>
					</Menu.SubMenu>
				) : null}

				{Math.min(props.roles.levels) <= 4 ? (
					<Menu.SubMenu
						index="9"
						title={
							<span>
								<i
									className="fas fa-file-invoice-dollar"
									style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
								></i>{" "}
								Alarmas
							</span>
						}
					>
						<Menu.ItemGroup title="Alarmas">
							< Link to="/alarm/list" style={{ textDecoration: "none" }}>
								<Menu.Item index="9-1">
									<i
										className="fas fa-bell"
										style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
									></i>{" "}
									Lista Alarmas
								</Menu.Item>
							</Link>
							{/*< Link to="/alarm/chart" style={{ textDecoration: "none" }}>
								<Menu.Item index="9-2">
									<i
										className="fas fa-bell"
										style={{ marginRight: "5px", width: "24px", textAlign: "center" }}
									></i>{" "}
									Gráfico Alarmas
								</Menu.Item>
							</Link>*/}
						</Menu.ItemGroup>
					</Menu.SubMenu>
				) : null}
				<Layout.Row type="flex" justify="center" align="center">
					<img src={logo_menu} className="menu-logo" />
				</Layout.Row>
			</Menu>

		</div >
	);
}

const mapStateToProps = (state) => ({
	roles: state.auth.roles,
	organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(ToolBar);
