import { Message } from "element-react";
import axios from "../../utilities/axiosInstance";

/**
 * Obtiene la lista de las organizaciones
 */
export async function organizationsByUser(id) {
    try {
        let response = await axios.get(`/organizations_by_user?id=${id}`);
        return response.data.data;
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener las organizaciones",
            type: 'error',
            customClass :"message-position"
        });
    }
}