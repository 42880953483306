import axios from "../../utilities/axiosInstance";
import { Message } from "element-react";
import handleResponse from "../../utilities/responseReceiver";

/*---------------------------------------------------
*****OBTENER VALORES DE CONSUMO SECTOR****************
----------------------------------------------------*/
export async function consumptionSectorRequest(value) {
	try {
		let response = await axios.get("/sector/consumption", {
			params: value,
		});

		return response.data;
	} catch (error) {
		console.log(error);
		return {
			data: null,
			error: true,
			message: "Hubo un problema al obtener el consumo del sector",
		};
	}
}

/*------------------------------------
********MUESTRA EL CONSUMO TOTAL POR EMPRESA***********    
-------------------------------------*/
export async function getTotalConsumption(value) {
	try {
		let { data } = await axios.get("/totalConsumptionByOrganization");

		if (handleResponse(data)) {
			let values = data.data;
			return values;
		} else {
			return false;
		}
	} catch (error) {
		Message({
			showClose: true,
			message: "Hubo un problema al obtener el consumo total por empresa",
			type: 'error',
			customClass: "message-position"
		});
	}
}

/*------------------------------------
********MUESTRA EL CONSUMO TOTAL POR SECTOR***********    
-------------------------------------*/
export async function getConsumptionBySector(value) {
	try {
		let { data } = await axios.get("/totalConsumptionBySector");
		
		if (handleResponse(data)) {
			let values = data.data;
			return values;
		} else {
			return false;
		}
	} catch (error) {
		Message({
			showClose: true,
			message: "Hubo un problema al obtener el consumo total por sectores",
			type: 'error',
			customClass: "message-position"
		});
	}
}

/*------------------------------------
********GRAFICO DE DISPOSITIVOS ACTIVOS EN LAS ULTIMAS 24 HORAS***********    
-------------------------------------*/
export async function getActiveDevices(value) {
	try {
		let { data } = await axios.get("/getActiveDevices");
		
		if (handleResponse(data)) {
			let values = data.data;
			return values;
		} else {
			return false;
		}
	} catch (error) {
		Message({
			showClose: true,
			message: "Hubo un problema al obtener el los dispositivos activos",
			type: 'error',
			customClass: "message-position"
		});
	}
}

/*------------------------------------
********OBTIENE LAS ALARMAS PENDIENTES POR REVISIÓN***********    
-------------------------------------*/
export async function getPendingAlarms() {
	try {
		let { data } = await axios.get("/pendingAlarms");
		
		if (handleResponse(data)) {
			let values = data.data;
			return values;
		} else {
			return false;
		}
	} catch (error) {
		Message({
			showClose: true,
			message: "Hubo un problema al obtener las alarmas pendientes",
			type: 'error',
			customClass: "message-position"
		});
	}
}
