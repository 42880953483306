import React from 'react';
import 'element-theme-default';
import NavBar from '../../../components/navBar/NavBar';
import Login from '../../../components/login/Login';
import './LoginPage.css'

function LoginPage(props) {

  return (
    <div className="container">
       
       <NavBar showDataProfile={false}/>
       <Login/>

    </div>
  );
}

export default LoginPage;