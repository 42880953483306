import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Breadcrumb, Table, Button, Form, Input, Select, Dialog, MessageBox, Switch } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './UserGroups.css';
import { userGroupCreateRequest, userGroupUpdateRequest, userGroupDeleteRequest, userGroupPaginationRequest, userByOrganizationRequest } from './UserGroupsRequest';
import { getAlarmStatus } from '../Alarms/AlarmList/AlarmListRequest';

function UserGroups(props) {

  // Variables de Estado de Alimentación
  const [userList, setUsersList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [data, setData] = useState([]);
  const [dataDetailUser, setDataDetailUser] = useState([]);
  const [dataDetailAlarm, setDataDetailAlarm] = useState([]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [loadingSearch, setLoading] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [dialogDetailVisible, setDialogDetailVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createUserGroup, setCreateUserGroup] = useState({
    name: "",
    description: "",
    users: [],
    arrayUsers: [],
    status: [],
    arrayStatus: [],
    sent_instantly: true,
    interval_days: 0,
    is_active: true
  });

  const [editUserGroup, setEditUserGroup] = useState({
    name: "",
    description: "",
    users: [],
    arrayUsers: [],
    status: [],
    arrayStatus: [],
    sent_instantly: true,
    interval_days: 0,
    is_active: true
  });

  var columns = [
    {
      label: "Nombre del Grupo",
      prop: "name",
    },
    {
      label: "Descripción",
      prop: "description",
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {

        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => userGroupDeleteApi(item.id)} ></Button>
          <Button disabled={!props.roles.profiles.profile_see} type="primary" className="btn-edit" icon="search" size="mini" onClick={() => showDetail(item)}>Ver Detalle</Button>
        </Button.Group>);

      }
    }
  ];

  var columnsDetailUser = [
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Apellido",
      prop: "last_name",
    },
  ];

  var columnsDetailAlarm = [
    {
      label: "Código",
      prop: "code",
    },
    {
      label: "Nombre",
      prop: "name",
    },
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    group_name: "",
    user_name: "",
    alarm: "",
  });

  useEffect(async () => {
    var resultPagination = await userGroupPaginationRequest(pagination);
    if (resultPagination) {
      pagination.total = resultPagination.count;
      setData(resultPagination.rows);
    }

    var resultUsers = await userByOrganizationRequest();
    if (resultUsers) {
      setUsersList(resultUsers);
    }

    var resultStatus = await getAlarmStatus();
    if (resultStatus) {
      setStatusList(resultStatus);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createUserGroup };
    newValues[key] = value;
    setCreateUserGroup(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editUserGroup };
    newValues[key] = value;
    setEditUserGroup(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function userGroupCreateApi() {
    let values = createUserGroup;
    var result = await userGroupCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await userGroupPaginationRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  function copyDataUpdate(item) {
    let newItem = Object.assign({}, item);
    newItem.arrayUsers = [];
    newItem.arrayStatus = [];
    item.users.forEach((user) => {
      newItem.arrayUsers.push(user.id);
    });

    item.status.forEach((s) => {
      newItem.arrayStatus.push(s.id);
    });

    setEditUserGroup(newItem);
    setDialogEditVisible(true);
  }

  async function userGroupUpdateApi() {
    let values = editUserGroup;
    var result = await userGroupUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await userGroupPaginationRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function userGroupDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este grupo de usuarios?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await userGroupDeleteRequest(id);
      if (result) {
        var result = await userGroupPaginationRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateUserGroup({
      name: "",
      description: "",
      users: [],
      arrayUsers: [],
      status: [],
      arrayStatus: [],
      sent_instantly: true,
      interval_days: 0,
      is_active: true
    });
    setEditUserGroup({
      name: "",
      description: "",
      users: [],
      arrayUsers: [],
      status: [],
      arrayStatus: [],
      sent_instantly: true,
      interval_days: 0,
      is_active: true
    });
    setQueryData({
      group_name: "",
      user_name: "",
      alarm: "",
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchUserGroup();
  }

  async function searchUserGroup() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;
      var result = await userGroupPaginationRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listUserGroups() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await userGroupPaginationRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  function showDetail(item) {
    setDataDetailUser(item.users);
    setDataDetailAlarm(item.status);
    setDialogDetailVisible(true);
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="4-3" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Grupos de usuarios</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Grupo</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="6" xs="24">
                    <Form.Item className="label-filter" label="Nombre grupo">
                      <Input value={queryData.group_name} onChange={(value) => handleQueryChange('group_name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="3" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchUserGroup()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="3" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listUserGroups() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron grupos de usuarios"
            />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal agregar */}
      <Dialog
        size="small"
        title="CREAR GRUPO DE USUARIOS"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createUserGroup} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre del grupo">
              <Input className="input-style" placeholder="Ingrese un nombre de grupo" value={createUserGroup.name} onChange={(value) => { onChangeCreate("name", value) }} />
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="input-style" type="textarea" placeholder="Ingrese una descripción" value={createUserGroup.description} onChange={(value) => { onChangeCreate("description", value) }} />
            </Form.Item>
            <Form.Item label="Integrantes">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                value={createUserGroup.users}
                multiple={true}
                onChange={(value) => onChangeCreate('users', value)}>
                {
                  userList.map((user) => {
                    return <Select.Option key={user.id} label={user.name + ' ' + user.last_name} value={user.id}></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Alarmas">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                value={createUserGroup.status}
                multiple={true}
                onChange={(value) => onChangeCreate('status', value)}>
                {
                  statusList.map((entry) => {
                    return <Select.Option key={entry.id} label={`${entry.code} / ${entry.name}`} value={entry.id} ></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Layout.Row style={{ padding: "20px" }}>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Activo?">
                  <Switch onText="Si" offText="No" value={createUserGroup.is_active} onChange={(value) => onChangeCreate('is_active', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Enviar al instante (8:00 AM-5:00 PM)">
                  <Switch tch value={createUserGroup.sent_instantly} onText="Si" offText="No" onChange={(value) => onChangeCreate('sent_instantly', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Enviar cada: (Valor en días, Maximo 30)">
                  <Input className="input-style" type="Number" min="0" max="30" placeholder="Ingrese un valor" value={createUserGroup.interval_days} disabled={createUserGroup.sent_instantly} onChange={(value) => { onChangeCreate("interval_days", value) }} />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => userGroupCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        size="small"
        title="EDITAR GRUPO DE USUARIOS"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editUserGroup} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre del grupore">
              <Input className="input-style" placeholder="Ingrese un nombre de grupo" value={editUserGroup.name} onChange={(value) => { onChangeEdit("name", value) }} />
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="input-style" type="textarea" placeholder="Ingrese una descripción" value={editUserGroup.description} onChange={(value) => { onChangeEdit("description", value) }} />
            </Form.Item>
            <Form.Item label="Integrantes">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                value={editUserGroup.arrayUsers}
                multiple={true}
                onChange={(value) => onChangeEdit('users', value)}>
                {
                  userList.map((user) => {
                    return <Select.Option key={user.id} label={user.name + ' ' + user.last_name} value={user.id}></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              className="input-style"
              label="Alarmas">
              <Select style={{ width: "100%" }}
                value={editUserGroup.arrayStatus}
                multiple={true}
                onChange={(value) => onChangeEdit('status', value)}>
                {
                  statusList.map((entry) => {
                    return <Select.Option key={entry.id} label={`${entry.code} / ${entry.name}`} value={entry.id} ></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Layout.Row style={{ padding: "20px" }}>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Activo?">
                  <Switch onText="Si" offText="No" value={editUserGroup.is_active} onChange={(value) => onChangeEdit('is_active', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Enviar al instante (8:00 AM-5:00 PM)">
                  <Switch tch value={editUserGroup.sent_instantly} onText="Si" offText="No" onChange={(value) => onChangeEdit('sent_instantly', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Enviar cada: (Valor en días, Maximo 30)">
                  <Input className="input-style" type="Number" min="0" max="30" placeholder="Ingrese un valor" value={editUserGroup.interval_days} disabled={editUserGroup.sent_instantly} onChange={(value) => { onChangeEdit("interval_days", value) }} />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => userGroupUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal detalle */}
      <Dialog
        size="small"
        title="DETALLES DEL GRUPO"
        visible={dialogDetailVisible}
        onCancel={() => setDialogDetailVisible(false)}
        lockScroll={true}
      >
        <Dialog.Body>
          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Row style={{ marginBottom: "41px" }}>
              <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>INTEGRANTES</p>
            </Layout.Row>
            <Table
              style={{ width: '100%' }}
              columns={columnsDetailUser}
              data={dataDetailUser}
              stripe={true}
              highlightCurrentRow={true}
              emptyText="No se encontraron integrantes"
            />
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Row style={{ marginBottom: "41px" }}>
              <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>ESTADOS DE ALARMA</p>
            </Layout.Row>
            <Table
              style={{ width: '100%' }}
              columns={columnsDetailAlarm}
              data={dataDetailAlarm}
              stripe={true}
              highlightCurrentRow={true}
              emptyText="No se encontraron estados de alarma"
            />
          </Layout.Row>

        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-cancel" onClick={() => setDialogDetailVisible(false)}>Cerrar</Button>
        </Dialog.Footer>
      </Dialog>

    </div >
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(UserGroups);
