import React, { useState, useEffect } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import "element-theme-default";
import { connect } from "react-redux";
import {
  Layout,
  Loading,
  Breadcrumb,
  Badge,
  Button,
  Notification,
} from "element-react";
import { Link } from "react-router-dom";
import element1 from "../../components/images/element1.svg";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import "./HomePage.css";
import {
  getTotalConsumption,
  getConsumptionBySector,
  getActiveDevices,
  getPendingAlarms,
} from "../../pages/home/HomePageRequest";
import { getConsumptionPeriods } from "../consumptionProfile/ConsumptionProfileRequest";
import { getNotificationByOrganization } from "../Notifications/NotificationsRequest";

function HomePage(props) {
  // Variables de Estado de Alimentación
  const [totalConsumption, setTotalConsumption] = useState(0);
  const [consumptionBySector, setConsumptionBySector] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const [accumulatedByHour, setAccumulatedByHour] = useState([]);
  const [totalPendingAlarms, setTotalPendingAlarms] = useState(0);
  const [showBtnInactiveDevices, setShowBtnInactiveDevices] = useState(false);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            style: "italic",
          },
        },
      },
      title: {
        display: true,
        text: "Consumo por sector",
        color: "#000000",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const optionsDoughnut = {
    responsive: true,
    events: ["click"],
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            style: "italic",
          },
        },
      },
      title: {
        display: true,
        text: "Dispositivos",
        color: "#000000",
      },
    },
  };

  const optionsConsumptionPeriods = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            style: "italic",
          },
        },
      },
      title: {
        display: true,
        text: "Consumo 24 horas",
        color: "#000000",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(async () => {
    var totalConsumption = await getTotalConsumption();
    if (totalConsumption && totalConsumption[0].total_volume) {
      let volume = Math.trunc(totalConsumption[0].total_volume * 10) / 10;
      setTotalConsumption(volume);
    }

    var totalConsumptionBySector = await getConsumptionBySector();
    if (totalConsumptionBySector) {
      setConsumptionBySector(totalConsumptionBySector);
    }

    var activeDevices = await getActiveDevices();
    if (activeDevices) {
      setActiveDevices(activeDevices);
      if (activeDevices.datasets[0].data[1] > 0) {
        setShowBtnInactiveDevices(true);
      }
    }

    var consumptionPeriods = await getConsumptionPeriods();
    console.log(consumptionPeriods);
    if (consumptionPeriods) {
      setAccumulatedByHour(consumptionPeriods);
    }

    var pendingAlarms = await getPendingAlarms();
    if (pendingAlarms) {
      setTotalPendingAlarms(pendingAlarms.count);
    }

    var notifications = await getNotificationByOrganization();
    if (notifications) {
      notifications.forEach((notification) => {
        Notification({
          title: notification.title,
          message: notification.message,
          type: notification.type,
          duration: 0,
        });
      });
    }
  }, []);

  const myData = [
    { angle: 1, innerRadius: 0.4 },
    { angle: 2, innerRadius: 0.5 },
  ];

  return (
    <div className="container">
      <Loading text="Cargando..." loading={isLoading}>
        <NavBar showDataProfile={true} showToolBar={showToolBar} />
        <ToolBar stateToolBar={stateToolBar} active="" />

        <Layout.Row type="flex" justify="center">
          <Layout.Col
            xs="0"
            sm={stateToolBar ? 5 : 0}
            md={stateToolBar ? 5 : 0}
            lg={stateToolBar ? 5 : 0}
          >
            <br />
          </Layout.Col>

          <Layout.Col
            xs="24"
            sm={stateToolBar ? 19 : 24}
            md={stateToolBar ? 19 : 24}
            lg={stateToolBar ? 19 : 24}
          >
            <Layout.Row className="row-bg-dashboard" justify="center">
              <Layout.Row className="row-bg" justify="space-around" gutter="20">
                <Layout.Col xs="24" sm="6" md="6" lg="6">
                  <Layout.Row type="flex" justify="start">
                    <p
                      style={{
                        fontSize: "40px",
                        color: "#ff5a50",
                        marginLeft: "20px",
                        marginBottom: "0px",
                      }}
                    >
                      {totalConsumption} m³
                    </p>
                  </Layout.Row>
                  <Layout.Row type="flex" justify="start">
                    <p
                      style={{
                        fontSize: "15px",
                        color: "#000000",
                        marginLeft: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Consumo últimos 30 días{" "}
                    </p>
                  </Layout.Row>
                  <Layout.Row
                    type="flex"
                    justify="end"
                    style={{ marginTop: "70px" }}
                  >
                    <Badge value={totalPendingAlarms} max={10}>
                      {
                        <Link to="/alarm/list">
                          <Button type="primary" className="btn-alarm">
                            <i
                              className="fa fa-bell-o"
                              style={{ color: "white" }}
                            ></i>
                          </Button>
                        </Link>
                      }
                    </Badge>
                  </Layout.Row>
                </Layout.Col>
                <Layout.Col xs="24" sm="18" md="18" lg="18">
                  <Layout.Row
                    justify="center"
                    style={{ height: "300px", width: "100%" }}
                  >
                    <Bar
                      data={consumptionBySector}
                      options={options}
                      height={80}
                    />
                  </Layout.Row>
                </Layout.Col>
              </Layout.Row>

              <Layout.Row className="row-bg" justify="space-around" gutter="20">
                <Layout.Col xs="24" sm="6" md="6" lg="6">
                  <Layout.Row type="flex" justify="end">
                    {showBtnInactiveDevices ? (
                      <Link
                        to={{
                          pathname: "/dispositivo",
                          tab: { activeTab: "2" },
                        }}
                      >
                        <Button
                          type="primary"
                          icon="search"
                          size="mini"
                          className="btn-inactive"
                        >
                          Ver inactivos
                        </Button>
                      </Link>
                    ) : null}
                  </Layout.Row>

                  <Layout.Row
                    type="flex"
                    justify="start"
                    align="middle"
                    style={{ marginTop: "-27px", marginRight: "80px" }}
                  >
                    <Doughnut
                      className="doughnut-style"
                      data={activeDevices}
                      options={optionsDoughnut}
                    />
                  </Layout.Row>

                  <Layout.Row
                    type="flex"
                    justify="end"
                    align="middle"
                    style={{ marginTop: "-30px" }}
                  >
                    <img src={element1} className="element-1" />
                  </Layout.Row>
                </Layout.Col>
                <Layout.Col xs="24" sm="18" md="18" lg="18">
                  <Layout.Row justify="center">
                    <Layout.Col
                      xs="24"
                      sm="24"
                      md="24"
                      lg="24"
                      style={{ height: "400px", width: "100%" }}
                    >
                      <Bar
                        data={accumulatedByHour}
                        options={optionsConsumptionPeriods}
                        height={80}
                      />
                    </Layout.Col>
                  </Layout.Row>
                </Layout.Col>
              </Layout.Row>
            </Layout.Row>
            <Footer />
          </Layout.Col>
        </Layout.Row>
      </Loading>
    </div>
  );
}

const mapStateToProps = (state) => ({
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(HomePage);
