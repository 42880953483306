import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import {Provider} from 'react-redux';
import { saveState } from './localStorage';
import throttle from 'lodash/throttle';

store.subscribe(throttle(() => {
  saveState({
    auth:store.getState().auth
  })
}, 1000));

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

