import { Message } from "element-react";
import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";

export async function getPaginationRequestBin(values) {
    try {
        let { data } = await axios.get('/requestbin/pagination', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener los Json",
            type: 'error',
            customClass :"message-position"
        });
    }
}

