import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import Axios from 'axios';
import { connect } from "react-redux";
import { URL_API } from '../../constant';
import { Pagination, Layout, Breadcrumb, Table, Button, Form, Input, InputNumber, Select, Dialog, Alert, Card, MessageBox, Message, Tabs } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import GoogleMap from '../../components/map/Map';
import date from 'date-and-time';
import './GatewayProfile.css';
import { gatewayProfileCreateRequest, gatewayProfileUpdateRequest, gatewayDeleteRequest, paginationGatewayListRequest, fileDeleteRequest, getFilesByGateway } from './GatewayProfileRequest';
import { sectorListRequest } from '../Sector/SectorRequest';

function GatewayProfile(props) {

  // variables para el encabezado de envio de la imagen del gateway
  const [headers, setHeaders] = useState(
    {
      'x-access-token': localStorage.getItem('jwt'),
      'organizationId': localStorage.getItem('organizationId'),
    }
  );

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) };
  const [loadingSearch, setLoading] = useState(false);
  const [haveErrors, setHaveErrors] = useState(false);
  const [textErrors, setTextErrors] = useState("");
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [dialogAddressVisible, setDialogAddressVisible] = useState(false);
  const [dialogMapVisible, setDialogMapVisible] = useState(false);
  const [dialogShowFileVisible, setDialogShowFileVisible] = useState(false);

  const [regionList, setRegionList] = useState([
    { id: "AU915" , name: "AU915" },
    { id: "US915" , name: "US915" },
  ]);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 14,
    total: 0
  });

  // Variables de Estado de Alimentación
  const [sectors, setSectors] = useState([]);
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]);

  // Variables de Estado de Formulario
  const [roadList, setRoadList] = useState(["Anillo", "Autopista", "Avenida", "Calle", "Carrera", "Circular", "Diagonal", "Transversal", "Kilometro", "Otro"]);
  const [propertyType, setPropertyType] = useState(["Apartamento", "Bloque", "Casa", "Oficina"]);
  const [connectionType, setConnectionType] = useState([{ id: 1, type: 'SIM' }, { id: 2, type: 'WIFI' }, { id: 3, type: 'LAN' },]);
  const [selectedFile, setSelectedFile] = useState();
  const [disabledFile, setDisabledFile] = useState(false);
  const [gatewayId, setGatewayId] = useState(null);
  const [urlApi, setUrlApi] = useState(URL_API + '/uploadGatewayImage');
  const [showImage, setShowImage] = useState({
    id: null,
    name: '',
    url: '',
    date: null
  });

  const [createGateway, setCreateGateway] = useState({
    eui: "",
    name: "",
    organization_id: null,
    sector_id: null,
    address: "",
    additional_address: "",
    postal_code: 0,
    latitud: 0,
    longitud: 0,
    description: "",
    total_devices: 0,
    connection_type: null,
    operator: "",
    sim_number: 0,
    region : ""
  });

  const [editGateway, setEditGateway] = useState({
    eui: "",
    name: "",
    organization_id: null,
    sector_id: null,
    address: "",
    additional_address: "",
    postal_code: 0,
    latitud: 0,
    longitud: 0,
    description: "",
    total_devices: 0,
    connection_type: null,
    operator: "",
    sim_number: 0,
    region : ""
  });

  const [mapGateway, setMapGateway] = useState({
    latitud: 0,
    longitud: 0,
  });

  const [createAddress, setCreateAddress] = useState({
    road: "",
    propertyType: "",
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    apto: "",
  });

  var columns = [
    {
      label: "EUI",
      prop: "eui",
    },
    {
      label: "Región",
      prop: "region",
    },
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Sector",
      prop: "sector.name",
    }, {
      label: "Tipo de Conexión",
      render: (data, column, index) => {
        if (data.connection_type == 1) {
          return 'SIM';
        } else if (data.connection_type == 2) {
          return 'WIFI';
        } else if (data.connection_type == 3) {
          return 'LAN';
        }
      }
    },
    {
      label: "Tramas en 24 h",
      prop: "gateway_count",
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => gatewayDeleteApi(item.id)} ></Button>
          <Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" size="mini" onClick={() => copyShowMap(item)}><i className="fa fa-map-marker"></i> Ubicación </Button>
        </Button.Group>);

      }
    }
  ];

  var FileColumns = [
    {
      label: "Nombre",
      prop: "name",
      width: "350px"
    },
    {
      label: "Imagen",
      width: "350px",
      render: (data, column) => {
        if (data.url) {
          return (<img src={data.url} className="image" alt='' height="50px" width="50px" />)
        } else {
          return "-";
        }

      }
    },
    {
      label: "Acciones",
      prop: "actions",
      width: "130px",
      render: (data) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => fileDeleteApi(data.id)} ></Button>
          <Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" icon="search" size="mini" onClick={() => showFile(data)}> </Button>
        </Button.Group>);

      }
    }
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    eui: "",
    name: "",
    sector_id: null,
  });

  useEffect(async () => {
    var result = await paginationGatewayListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
    var result = await sectorListRequest();
    if (result) {
      setSectors(result);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createGateway };
    newValues[key] = value;
    setCreateGateway(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editGateway };
    newValues[key] = value;
    setEditGateway(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  // Validación para el formulario de direcciones
  function validation(id) {
    createAddress.number1 = createAddress.number1.replace(/ /g, "");
    createAddress.number2 = createAddress.number2.replace(/ /g, "");
    createAddress.number3 = createAddress.number3.replace(/ /g, "");
    createAddress.propertyType = createAddress.propertyType.replace(/ /g, "");
    createAddress.number4 = createAddress.number4.replace(/ /g, "");
    createAddress.apto = createAddress.apto.replace(/ /g, "");

    if (createAddress.road.length == 0) {
      setTextErrors("La via no puede estar vacía.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number1.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number2.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number3.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else {
      setTextErrors("");
      setHaveErrors(false);
      return true;
    }

  }

  async function GatewayCreateApi() {
    let values = createGateway;
    var result = await gatewayProfileCreateRequest(values);
    if (result) {
      onFileUpload(result.id)
      cleanForm();
      var result = await paginationGatewayListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function copyDataUpdate(item) {

    let newItem = Object.assign({}, item);
    let eui = newItem.eui.replaceAll(':', '');
    newItem.eui = eui;
    newItem.latitud = parseFloat(item.latitud);
    newItem.longitud = parseFloat(item.longitud);
    newItem.connection_type ? newItem.connection_type = item.connection_type : newItem.connection_type = "";

    let result = await getFilesByGateway({ gateway_id: newItem.id });
    setGatewayId(newItem.id);
    if (result) {
      setFileList(result);
      if (result.length >= 2) {
        setDisabledFile(true);
      }
      setEditGateway(newItem);
      setDialogEditVisible(true);
    }
  }

  function copyShowMap(item) {
    let newItem = Object.assign({}, item);
    setMapGateway(newItem);
    setDialogMapVisible(true);
  }

  async function gatewayUpdateApi() {
    let values = editGateway;
    var result = await gatewayProfileUpdateRequest(values);
    if (result) {
      onFileUpload(result[1][0].id)
      cleanForm();
      var result = await paginationGatewayListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function gatewayDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este gateway?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await gatewayDeleteRequest(id);
      if (result) {
        var result = await paginationGatewayListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      } else {
        setTextErrors(result.message);
        setHaveErrors(true);
      }
    }).catch(() => {
    });
  }

  function onChangeAddres(key, value) {
    let newValues = { ...createAddress };
    newValues[key] = value;
    setCreateAddress(newValues);
  }

  function saveAddress() {
    if (validation()) {
      createGateway.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      editGateway.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      cleanFormAdres();
    }
  }

  function copyAdresToUpdate(address) {

    if (address) {
      let stringAddress = address
      let arrrayAddress = stringAddress.split(' ')

      setCreateAddress({
        road: arrrayAddress[0],
        number1: arrrayAddress[1],
        number2: arrrayAddress[3],
        number3: arrrayAddress[5],
        propertyType: arrrayAddress[6],
        number4: arrrayAddress[7],
        apto: arrrayAddress[8],
      });
    }

    setDialogAddressVisible(true);
  }

  function cleanFormAdres() {
    setCreateAddress({
      road: "",
      propertyType: "",
      number1: "",
      number2: "",
      number3: "",
      number4: "",
      apto: "",
    });

    setDialogAddressVisible(false)
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setTextErrors("");
    setHaveErrors(false);
    setCreateGateway({
      eui: "",
      name: "",
      organization_id: null,
      sector_id: null,
      address: "",
      additional_address: "",
      postal_code: 0,
      latitud: 0,
      longitud: 0,
      description: "",
      total_devices: 0,
      connection_type: null,
      operator: "",
      sim_number: 0,
      region : ""
    });
    setEditGateway({
      eui: "",
      name: "",
      organization_id: null,
      sector_id: null,
      address: "",
      additional_address: "",
      postal_code: 0,
      latitud: 0,
      longitud: 0,
      description: "",
      total_devices: 0,
      connection_type: null,
      operator: "",
      sim_number: 0,
      region : ""
    });
    setQueryData({
      eui: "",
      name: "",
      sector_id: null,
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchGateway();
  }

  async function searchGateway() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;
      var result = await paginationGatewayListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listGateway() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationGatewayListRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  function onFileUpload(gateway_id) {
    if (selectedFile) {
      const formData = new FormData();

      formData.append(
        "gateway_id",
        gateway_id
      );

      for (let index = 0; index < selectedFile.length; index++) {
        let file = selectedFile[index];
        formData.append(
          "file",
          file,
          file.name,
        );
      }

      // Request made to the backend api 
      // Send formData object 
      Axios.post(urlApi, formData, {
        headers: headers
      });
    } else {
      return
    }
  }

  function fileDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este archivo?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await fileDeleteRequest(id);
      if (result) {
        let result = await getFilesByGateway({ gateway_id: gatewayId });
        if (result) {
          setFileList(result);
          if (result.length >= 2) {
            setDisabledFile(true);
          } else {
            setDisabledFile(false);
          }
        }
      } else {
        setTextErrors(result.message);
        setHaveErrors(true);
      }
    }).catch(() => {
    });
  }

  function showFile(data) {
    let newDate = date.addHours(new Date(data.date), -5);
    data.date = date.format(newDate, "YYYY-MM-DD");
    setShowImage(data);
    setDialogShowFileVisible(true)
  }

  function resetInput(e) {
    MessageBox.alert('Solo se permiten dos imagenes por gateway', 'Alerta')
    e.target.value = null;
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="1" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Gateways</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Gateway</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="6" xs="24">
                    <Form.Item className="label-filter" label="EUI">
                      <Input value={queryData.eui} onChange={(value) => handleQueryChange('eui', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="6" xs="24">
                    <Form.Item className="label-filter" label="Nombre">
                      <Input value={queryData.name} onChange={(value) => handleQueryChange('name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="6" xs="24">
                    <Form.Item className="label-filter" label="Sector">
                      <Select style={{ width: "100%" }} placeholder="Seleccione un sector" clearable={true} filterable={true} value={queryData.sector_id} onChange={(value) => { handleQueryChange("sector_id", value) }}>
                        {
                          sectors.map(item => (
                            <Select.Option key={item.id} label={`${item.name} | ${item.city.name}`} value={item.id} />
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="3" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchGateway()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="3" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listGateway() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron Gateways"
            />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal agregar */}
      <Dialog
        size="small"
        title="CREAR GATEWAY"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Tabs activeName="gateway-create" onTabClick={(tab) => console.log(tab.props.name)}>
            <Tabs.Pane label="Gateway" name="gateway-create">
              <Form className="es-CO" model={createGateway} labelWidth="120" labelPosition="top">
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="EUI">
                      <Input className="input-style" placeholder="Ingrese un valor" value={createGateway.eui} onChange={(value) => { onChangeCreate("eui", value) }} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Nombre">
                      <Input className="input-style" placeholder="Ingrese un nombre" value={createGateway.name} onChange={(value) => { onChangeCreate("name", value) }} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                <Layout.Col xs="24" sm="8">
                    <Form.Item label="Región">
                      <Select className="input-style" style={{ width: "100%" }} placeholder="Seleccione una región" clearable={true} value={createGateway.region} onChange={(value) => { onChangeCreate("region", value) }}>
                        {
                          regionList.map(item => (
                            <Select.Option key={item.id} label={`${item.name}`} value={item.id} />
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Número de dispositivos">
                      <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Ingrese un valor" value={createGateway.total_devices} onChange={(value) => { onChangeCreate("total_devices", value) }} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Tipo de conexión">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Seleccione tipo de conexión"
                        value={createGateway.connection_type}
                        onChange={(value) => {
                          onChangeCreate("connection_type", value);
                        }}>
                        {connectionType.map((item) => (
                          <Select.Option key={item.id} label={item.type} value={item.id} />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                {(createGateway.connection_type == 1) ? (
                  <Layout.Row gutter="20">
                    <Layout.Col xs="24" sm="12">
                      <Form.Item label="Operador">
                        <Input className="input-style" placeholder="Ingrese un operador" value={createGateway.operator} onChange={(value) => { onChangeCreate("operator", value) }} />
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="12">
                      <Form.Item label="Número de SIM">
                        <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Ingrese un valor" value={createGateway.sim_number} onChange={(value) => { onChangeCreate("sim_number", value) }} />
                      </Form.Item>
                    </Layout.Col>
                  </Layout.Row>
                ) : (null)}

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Sector">
                      <Select className="input-style" style={{ width: "100%" }} placeholder="Seleccione un sector" clearable={true} filterable={true} value={createGateway.sector_id} onChange={(value) => { onChangeCreate("sector_id", value) }}>
                        {
                          sectors.map(item => (
                            <Select.Option key={item.id} label={`${item.name} | ${item.city.name}`} value={item.id} />
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Dirección">
                      <Input className="input-style" onChange={(value) => { onChangeCreate("address", value) }} value={createGateway.address} />
                    </Form.Item>
                  </Layout.Col>
                  {/* <Layout.Col xs="24" sm="2">
                    <Button className="btn-create" type="primary" icon="edit" onClick={() => setDialogAddressVisible(true)} style={{ marginTop: '23px' }} ></Button>
                  </Layout.Col> */}
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="18">
                    <Form.Item label="Dirección adicional">
                      <Input className="input-style" onChange={(value) => { onChangeCreate("additional_address", value) }} value={createGateway.additional_address} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="6">
                    <Form.Item label="Código Postal">
                      <InputNumber className="input-style" onChange={(value) => { onChangeCreate("postal_code", value) }} value={createGateway.postal_code} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20" style={{ marginTop: "10px" }}>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Latitud">
                      <Input type="number"  className="input-style" style={{ width: "100%" }} placeholder="Ingrese una valor"  step="0.00000001" min={-99.99999999} max={99.99999999} onChange={(value) => { onChangeCreate("latitud", value) }} defaultValue={createGateway.longitud} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Longitud">
                      <Input type="number"  className="input-style" style={{ width: "100%" }} placeholder="Ingrese una valor" step="0.00000001" min={-99.99999999} max={99.99999999} onChange={(value) => { onChangeCreate("longitud", value) }} defaultValue={createGateway.latitud} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Form.Item label="Descripción">
                  <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createGateway.description} onChange={(value) => onChangeCreate('description', value)}></Input>
                </Form.Item>
              </Form>
            </Tabs.Pane>
            <Tabs.Pane label="Imágenes" name="Image-create">

              <Layout.Row style={{ padding: "20px" }} >
                <input style={{ color: "#ffffff" }} type="file" accept="image/png, image/jpeg" multiple disabled={disabledFile} onChange={(e) => (e.target.files.length + fileList.length < 3) ? setSelectedFile(e.target.files) : resetInput(e)} />
              </Layout.Row>

              <Layout.Row style={{ padding: "20px" }}>

                <Table
                  style={{ width: '100%' }}
                  columns={FileColumns}
                  data={fileList}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={300}
                  emptyText="No se encontraron imágenes"
                />

              </Layout.Row>
            </Tabs.Pane>
            <Tabs.Pane label="Ubicación" name="ubicacion-create">
              <Layout.Row style={{ padding: "20px" }}>
                <GoogleMap coordinates={{ lat: createGateway.latitud, lng: createGateway.longitud }} />
              </Layout.Row>
            </Tabs.Pane>
          </Tabs>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => GatewayCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        size="small"
        title="EDITAR GATEWAY"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Tabs activeName="gateway-edit" onTabClick={(tab) => console.log(tab.props.name)}>
            <Tabs.Pane label="Gateway" name="gateway-edit">
              <Form className="es-CO" model={editGateway} labelWidth="120" labelPosition="top">
                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="EUI">
                      <Input className="input-style" placeholder="Ingrese un valor" value={editGateway.eui} onChange={(value) => { onChangeEdit("eui", value) }} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Nombre">
                      <Input className="input-style" placeholder="Ingrese un nombre" value={editGateway.name} onChange={(value) => { onChangeEdit("name", value) }} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                <Layout.Col xs="24" sm="8">
                    <Form.Item label="Región">
                      <Select className="input-style" style={{ width: "100%" }} placeholder="Seleccione una región" clearable={true} value={editGateway.region} onChange={(value) => { onChangeEdit("region", value) }}>
                        {
                          regionList.map(item => (
                            <Select.Option key={item.id} label={`${item.name}`} value={item.id} />
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Número de dispositivos">
                      <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Ingrese un valor" value={editGateway.total_devices} onChange={(value) => { onChangeEdit("total_devices", value) }} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="8">
                    <Form.Item label="Tipo de conexión">
                      <Select
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Seleccione tipo de conexión"
                        value={editGateway.connection_type}
                        onChange={(value) => {
                          onChangeEdit("connection_type", value);
                        }}>
                        {connectionType.map((item) => (
                          <Select.Option key={item.id} label={item.type} value={item.id} />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                {(editGateway.connection_type == 1) ? (
                  <Layout.Row gutter="20">
                    <Layout.Col xs="24" sm="12">
                      <Form.Item label="Operador">
                        <Input className="input-style" placeholder="Ingrese un operador" value={editGateway.operator} onChange={(value) => { onChangeEdit("operator", value) }} />
                      </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="12">
                      <Form.Item label="Número de SIM">
                        <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Ingrese un valor" value={editGateway.sim_number} onChange={(value) => { onChangeEdit("sim_number", value) }} />
                      </Form.Item>
                    </Layout.Col>
                  </Layout.Row>
                ) : (null)}

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Sector">
                      <Select className="input-style" style={{ width: "100%" }} placeholder="Seleccione un sector" clearable={true} filterable={true} value={editGateway.sector_id} onChange={(value) => { onChangeEdit("sector_id", value) }}>
                        {
                          sectors.map(item => (
                            <Select.Option key={item.id} label={`${item.name} | ${item.city.name}`} value={item.id} />
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Dirección">
                      <Input className="input-style" onChange={(value) => { onChangeEdit("address", value) }} value={editGateway.address} />
                    </Form.Item>
                  </Layout.Col>
                  {/* <Layout.Col xs="24" sm="2">
                    <Button className="btn-create" type="primary" icon="edit" onClick={() => copyAdresToUpdate(editGateway.address)} style={{ marginTop: '23px' }} ></Button>
                  </Layout.Col> */}
                </Layout.Row>

                <Layout.Row gutter="20">
                  <Layout.Col xs="24" sm="18">
                    <Form.Item label="Dirección adicional">
                      <Input className="input-style" onChange={(value) => { onChangeEdit("additional_address", value) }} value={editGateway.additional_address} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="6">
                    <Form.Item label="Código Postal">
                      <InputNumber className="input-style" onChange={(value) => { onChangeEdit("postal_code", value) }} value={editGateway.postal_code} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20" style={{ marginTop: "10px" }}>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Latitud">
                      <Input type="number" className="input-style" style={{ width: "100%" }} placeholder="Ingrese una valor" step="0.00000001"  min={-99.99999999} max={99.99999999} value={editGateway.latitud} onChange={(value) => { onChangeEdit("latitud", value) }} defaultValue={editGateway.longitud} />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="12">
                    <Form.Item label="Longitud">
                      <Input type="number" className="input-style" style={{ width: "100%" }} placeholder="Ingrese una valor" step="0.00000001"  min={-99.99999999} max={99.99999999} value={editGateway.longitud} onChange={(value) => { onChangeEdit("longitud", value) }} defaultValue={editGateway.latitud} />
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Form.Item label="Descripción">
                  <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={editGateway.description} onChange={(value) => onChangeEdit('description', value)}></Input>
                </Form.Item>
              </Form>
            </Tabs.Pane>
            <Tabs.Pane label="Imágenes" name="image-edit">

              <Layout.Row style={{ padding: "20px" }} >
                <input style={{ color: "#ffffff" }} type="file" accept="image/png, image/jpeg" multiple disabled={disabledFile} onChange={(e) => (e.target.files.length + fileList.length < 3) ? setSelectedFile(e.target.files) : resetInput(e)} />
              </Layout.Row>

              <Layout.Row style={{ padding: "20px" }}>

                <Table
                  style={{ width: '100%' }}
                  columns={FileColumns}
                  data={fileList}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={300}
                  emptyText="No se encontraron imágenes"
                />

              </Layout.Row>
            </Tabs.Pane>
            <Tabs.Pane label="Ubicación" name="ubicacion-edit">
              <Layout.Row style={{ padding: "20px" }}>
                <GoogleMap coordinates={{ lat: editGateway.latitud, lng: editGateway.longitud }} />
              </Layout.Row>
            </Tabs.Pane>
          </Tabs>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => gatewayUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal formato de dirección */}
      <Dialog
        title={haveErrors ?
          <Alert title={textErrors} type="error" showIcon={true} style={{ background: "#FF6A6A", width: "340px", float: "right" }} /> : ""
        }
        size="small"
        visible={dialogAddressVisible}
        onCancel={() => setDialogAddressVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Layout.Row style={{ marginBottom: "41px" }}>
            <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>INGRESE LA DIRECCIÓN</p>
          </Layout.Row>
          <Form className="es-CO" model={createAddress} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Vía">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.road}
                    onChange={(value) => {
                      onChangeAddres("road", value);
                    }}>
                    {roadList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number1} onChange={(value) => { onChangeAddres("number1", value); }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="1" sm="1">
                <label style={{ marginTop: "300px" }} >#</label>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number2} onChange={(value) => { onChangeAddres("number2", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="1">
                <Form.Item label="-">
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number3} onChange={(value) => { onChangeAddres("number3", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Tipo inmueble">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.propertyType}
                    onChange={(value) => {
                      onChangeAddres("propertyType", value);
                    }}>
                    {propertyType.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2" >
                <Form.Item label="Número">
                  <Input className="input-style" disabled={createAddress.propertyType != "Bloque"} value={createAddress.number4} onChange={(value) => { onChangeAddres("number4", value) }} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Interior">
                  <Input className="input-style" value={createAddress.apto} onChange={(value) => { onChangeAddres("apto", value) }}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => saveAddress()}>Crear</Button>
          <Button className="btn-cancel" onClick={() => cleanFormAdres()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal ampliar imagenes */}
      <Dialog
        size="small"
        visible={dialogShowFileVisible}
        onCancel={() => setDialogShowFileVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Card className="box-card" style={{ borderRadius: "20px" }}>
            <img src={showImage.url} className="image" height="540px" width="900px" />
            <div style={{ padding: 14 }}>
              <span>{showImage.name}</span>
              <div className="bottom clearfix">
                <time className="time">{showImage.date}</time>
              </div>
            </div>
          </Card>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-cancel" onClick={() => setDialogShowFileVisible(false)}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal Google Maps*/}
      <Dialog
        title="UBICACIÓN"
        size="small"
        visible={dialogMapVisible}
        onCancel={() => setDialogMapVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Layout.Row style={{ padding: "20px" }}>
            <GoogleMap coordinates={{ lat: mapGateway.latitud, lng: mapGateway.longitud }} />
          </Layout.Row>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-cancel" onClick={() => setDialogMapVisible(false)}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(GatewayProfile);
