import { useRouteMatch, Switch, Route } from "react-router-dom";
import UserList from "../pages/Users/User";

export default function Users(){
    var {path, url} = useRouteMatch();
    return (
        <div>
            <Switch>
                {/* Lista de usuarios */}
                <Route path={`${path}`}>
                    <UserList/>
                </Route>
                {/* Detalle de usuario */}
                <Route path={`${path}/:userId`}>

                </Route>
            </Switch>
        </div>
    )
}