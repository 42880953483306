import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR MODELOS***************
-------------------------------------*/
export async function modelListRequest(value) {
    try {
        let { data } = await axios.get(`/model`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de modelos",
            type: 'error',
            customClass: "message-position"
        });
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationModelListRequest(value) {
    try {
        let { data } = await axios.get("/model/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de modelos",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********CREAR MODELO******************
-------------------------------------*/
export async function modelCreateRequest(value) {
    try {
        let { data } = await axios.post(`/model`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el modelo",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR MODELO***************
-------------------------------------*/
export async function modelUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/model`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el modelo",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR MODELO***************
-------------------------------------*/
export async function modelDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/model?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el modelo",
            type: 'error',
            customClass: "message-position"
        });
    }
}