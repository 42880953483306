import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import {
  Layout,
  Breadcrumb,
  Table,
  Button,
  Tag,
  Pagination,
} from "element-react";
import date from "date-and-time";
import { Link } from "react-router-dom";
import { URL_API } from "../../../constant";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import "./BillList.css";
import { paginationBillListRequest } from "./BillListRequest";
import moment from "moment-timezone";

function BillList(props) {
  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
  });

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);

  var columns = [
    {
      label: "Factura #",
      prop: "id",
      render: (data, column) => {
        return (
          <Tag style={{ width: "30%", textAlign: "center" }}>{data.id}</Tag>
        );
      },
    },
    // {
    //   label: "Organización",
    //   prop: "organization.displayname",
    // },
    {
      label: "Sector",
      prop: "sector.name",
    },
    {
      label: "Fecha Inicial",
      prop: "date_start",
      render: (data) => {
        // let tz = moment.tz.guess();
        // var date = moment.tz(data.date_start, tz);
        // return setDate(date.format("YYYY-MM-DD HH:mm:ss"));

        // let tz = moment.tz.guess();
        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.date_start), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        //   return setDate(dateFormat);
        // } else {
        //   let date = moment.tz(data.date_start, tz);
        //   return setDate(date.format("YYYY-MM-DD HH:mm:ss"));
        // }

        let dateObj = new Date(data.date_start);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return setDate(dateFormat);
      },
    },
    {
      label: "Fecha Final",
      prop: "date_end",
      render: (data) => {
        // let tz = moment.tz.guess();
        // var date = moment.tz(data.date_end, tz);
        // return setDate(date.format("YYYY-MM-DD HH:mm:ss"));

        // let tz = moment.tz.guess();
        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.date_end), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        //   return setDate(dateFormat);
        // } else {
        //   let date = moment.tz(data.date_end, tz);
        //   return setDate(date.format("YYYY-MM-DD HH:mm:ss"));
        // }

        let dateObj = new Date(data.date_end);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return setDate(dateFormat);
      },
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (data) => {
        return (
          <Button.Group>
            {/* <Button type="primary" icon="edit" size="mini"></Button>
                        <Button type="primary" icon="delete" size="mini"></Button> 
          <Button disabled={!props.roles.profiles.profile_see} type="primary" size="mini" onClick={() => downloadBill(data.id)}><i className="fas fa-file-csv"></i> CSV </Button>*/}
            {props.roles.profiles.profile_see ? (
              <Link
                to={{
                  pathname: "/factura/detalle",
                  row: data,
                }}
              >
                <Button
                  className="btn-edit"
                  type="primary"
                  icon="search"
                  size="mini"
                >
                  Ver Detalle
                </Button>
              </Link>
            ) : null}
          </Button.Group>
        );
      },
    },
  ];

  function setDate(dateNew) {
    var dateObj = new Date(dateNew);
    var dateString = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");

    return (
      <p style={{ margin: "0px" }}>
        <i className="far fa-calendar-alt fa-lg"></i>
        {dateString}
      </p>
    );
  }

  async function downloadBill(id) {
    if (id != null) {
      var win = window.open(URL_API + "/bill/download?id=" + id, "_blank");
      win.focus();
    }
  }

  useEffect(async () => {
    var result = await paginationBillListRequest(pagination);
    if (!result.error) {
      pagination.total = result.data.count;
      if (result.data.rows != null) {
        setData(result.data.rows);
      }
    }
  }, []);

  async function onCurrentChange(page) {
    pagination.page = page;
    let value = {
      page: pagination.page,
      pageSize: pagination.pageSize,
    };

    var result = await paginationBillListRequest(value);
    if (!result.error) {
      setData(result.data.rows);
    }
  }

  return (
    <div className="container">
      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="3-2" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
          <br />
        </Layout.Col>

        <Layout.Col
          xs="24"
          sm={stateToolBar ? 19 : 24}
          className="toolbar-margins"
        >
          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Reportes</Breadcrumb.Item>
              <Breadcrumb.Item>Lista Reporte de Factura</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              {props.roles.profiles.profil_create ? (
                <Link
                  to={{
                    pathname: "/factura/crear",
                    row: data,
                  }}
                >
                  <Button type="primary">Nueva Facturación</Button>
                </Link>
              ) : null}
            </Layout.Col>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Table
              style={{ width: "100%" }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={500}
              emptyText="No se encontraron Facturas"
            />
          </Layout.Row>

          <Layout.Row>
            <Pagination
              style={{ float: "right" }}
              layout="total, prev, pager, next"
              total={pagination.total}
              pageSize={pagination.pageSize}
              onCurrentChange={(page) => onCurrentChange(page - 1)}
            />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(BillList);
