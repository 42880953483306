import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*---------------------------------------------------
*****OBTENER CONTRATOS POR ORGANIZACIÓN****************
----------------------------------------------------*/
export async function contractListRequest(value) {
    try {
        let { data } = await axios.get("/contract", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista de contratos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationContractListRequest(value) {
    try {
        let { data } = await axios.get("/contract/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de contrato",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE TITULARES POR EMPRESA****************
----------------------------------------------------*/
export async function getHolderList() {
    try {
        let { data } = await axios.get("/contract/holders");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener los titulares",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CREAR CONTRATO******************
-------------------------------------*/
export async function contractCreateRequest(value) {
    try {
        let { data } = await axios.post(`/contract`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el contrato",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR CONTRATO***************
-------------------------------------*/
export async function contractUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/contract`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el contrato",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR CONTRATO***************
-------------------------------------*/
export async function contractDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/contract?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el contrato",
            type: 'error',
            customClass :"message-position"
        });
    }

}

