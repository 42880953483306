import React, { useState, useEffect } from "react";
import "element-theme-default";
import {
  Button,
  Layout,
  Card,
  Breadcrumb,
  Alert,
  Loading,
  Tag,
  Table,
} from "element-react";
import date from "date-and-time";
import { URL_API } from "../../../constant";
import { connect } from "react-redux";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import "./BillDetail.css";
import { billOneRequest } from "../../Bill/BillList/BillListRequest";
import moment from "moment-timezone";

function BillDetail(props) {
  // Varibles de Estado de la Interfaz
  const [isLoading, setIsLoading] = useState(false);
  const [haveErrors, setHaveErrors] = useState(false);
  const [textErrors, setTextErrors] = useState("");
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const Back = () => {
    window.location = "/factura/lista";
  };
  const [loadingSearch, setLoading] = useState(false);

  // Variables de Estado de Alimentación
  const [bill, setBill] = useState(null);
  const [data, setData] = useState({
    bill: {
      organization_id: null,
      sector_id: null,
      date_start: null,
      date_end: null,
    },
  });

  var columns = [
    {
      label: "EUI",
      prop: "device.eui",
    },
    {
      label: "Dispositivo",
      prop: "device.name",
    },
    {
      label: "Fecha inicio",
      prop: "date_start",
      render: (data, column) => {
        // let tz = moment.tz.guess();
        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.date_start), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        //   return data.date_start ? setDate(date.format(dateFormat)) : "N/A";
        // } else {
        //   let date = moment.tz(data.date_start, tz);
        //   return data.date_start
        //     ? setDate(date.format("YYYY-MM-DD HH:mm:ss"))
        //     : "N/A";
        // }

        let dateObj = date.addHours(new Date(data.date_start), 0);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return (
          <p style={{ margin: "0px" }}>
            <i className="far fa-calendar-alt fa-lg"></i> {dateFormat}
          </p>
        );
      },
    },
    {
      label: "Fecha fin",
      prop: "date_end",
      render: (data, column) => {
        // let tz = moment.tz.guess();
        // var date = moment.tz(data.date_end, tz);
        // return data.date_end
        //   ? setDate(date.format("YYYY-MM-DD HH:mm:ss"))
        //   : "N/A";

        // let tz = moment.tz.guess();
        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.date_end), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        //   return data.date_end ? setDate(date.format(dateFormat)) : "N/A";
        // } else {
        //   let date = moment.tz(data.date_end, tz);
        //   return data.date_end
        //     ? setDate(date.format("YYYY-MM-DD HH:mm:ss"))
        //     : "N/A";
        // }

        let dateObj = new Date(data.date_end);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return (
          <p style={{ margin: "0px" }}>
            <i className="far fa-calendar-alt fa-lg"></i> {dateFormat}
          </p>
        );
      },
    },
    {
      label: "Consumo total",
      prop: "volume",
      render: (data, column) => {
        var volume =
          Math.round(data.volume * Math.pow(10, 3)) / Math.pow(10, 3);

        if (data.volume > 0) {
          return (
            <Tag
              style={{
                width: "50%",
                textAlign: "center",
                backgroundColor: "#25B03C",
              }}
            >
              <b>{volume} m³</b>
            </Tag>
          );
        } else if (data.volume < 0) {
          return (
            <Tag
              style={{
                width: "50%",
                textAlign: "center",
                backgroundColor: "#C70039",
              }}
            >
              <b>{volume} m³</b>
            </Tag>
          );
        } else if (data.volume === 0) {
          return (
            <Tag
              style={{
                width: "50%",
                textAlign: "center",
                backgroundColor: "#D7A53A",
              }}
            >
              <b>{volume} m³</b>
            </Tag>
          );
        }
      },
    },
  ];

  useEffect(async () => {
    setIsLoading(true);
    if (props.location.row === null) {
      window.location.href = "/factura/lista";
    } else {
      await billOneRequest(props.location.row.id)
        .then((bill_res) => {
          setBill(bill_res.data);
        })
        .catch((error) => {
          setBill(null);
        });
    }
    setIsLoading(false);
  }, []);

  function setDate(dateNew) {
    var dateObj = new Date(dateNew);
    var dateString = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");

    return (
      <p style={{ margin: "0px" }}>
        <i className="far fa-calendar-alt fa-lg"></i> {dateString}
      </p>
    );
  }

  async function downloadBillDetail() {
    let id =
      props.location.row && props.location.row.id
        ? props.location.row.id
        : null;
    var win = window.open(URL_API + "/bill/download?id=" + id, "_blank");
    win.focus();
  }

  return (
    <div className="container">
      <Loading text="Cargando..." loading={isLoading}>
        <NavBar showDataProfile={true} showToolBar={showToolBar} />
        <ToolBar stateToolBar={stateToolBar} active="3-2" />

        <Layout.Row>
          <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
            <br />
          </Layout.Col>

          <Layout.Col
            xs="24"
            sm={stateToolBar ? 19 : 24}
            className="toolbar-margins"
          >
            <Layout.Row style={{ padding: "20px" }}>
              <Breadcrumb separator="/">
                <Breadcrumb.Item>Reportes</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="/factura/lista">Reportes de Factura</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Detalle Reporte Factura</Breadcrumb.Item>
              </Breadcrumb>
              {haveErrors ? (
                <Alert
                  title={textErrors}
                  type="error"
                  showIcon={true}
                  style={{
                    background: "#FF6A6A",
                    width: "340px",
                    float: "right",
                  }}
                />
              ) : (
                ""
              )}
            </Layout.Row>

            {bill == null ? (
              ""
            ) : (
              <Layout.Row style={{ padding: "20px" }}>
                <Card
                  className="box-card"
                  style={{
                    overflow: "visible",
                    borderRadius: "20px",
                    height: "600px",
                  }}
                  header={
                    <div>
                      <p
                        style={{
                          fontSize: "24px",
                          color: "#00d1ff",
                          margin: "0",
                        }}
                      >
                        Reporte # <Tag>{bill.id}</Tag>
                      </p>
                      <Layout.Row>
                        <Layout.Col xs="24" sm="6">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#00d1ff",
                              float: "left",
                            }}
                          >
                            Sector:
                          </p>
                          <p
                            style={{
                              fontSize: "15px",
                              float: "left",
                              marginLeft: "10px",
                              color: "#000000",
                            }}
                          >
                            {bill ? bill.sector.name : "N/A"}
                          </p>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="6">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#00d1ff",
                              float: "left",
                            }}
                          >
                            Fecha inicio:
                          </p>
                          <div
                            style={{
                              fontSize: "15px",
                              float: "left",
                              marginLeft: "10px",
                              color: "#000000",
                              marginTop: "15px",
                            }}
                          >
                            {bill ? setDate(bill.date_start) : "N/A"}
                          </div>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="6">
                          <p
                            style={{
                              fontSize: "15px",
                              color: "#00d1ff",
                              float: "left",
                            }}
                          >
                            Fecha fin:
                          </p>
                          <div
                            style={{
                              fontSize: "15px",
                              float: "left",
                              marginLeft: "10px",
                              color: "#000000",
                              marginTop: "15px",
                            }}
                          >
                            {bill ? setDate(bill.date_end) : "N/A"}
                          </div>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="6">
                          <Button
                            disabled={!props.roles.profiles.profile_see}
                            className="btn-create"
                            type="primary"
                            loading={loadingSearch}
                            onClick={() => downloadBillDetail(data)}
                          >
                            <i className="fa fa-file-excel-o"></i> Excel{" "}
                          </Button>
                        </Layout.Col>
                      </Layout.Row>
                    </div>
                  }
                >
                  <Layout.Row style={{ padding: "20px" }}>
                    <Table
                      style={{ width: "100%" }}
                      columns={columns}
                      data={bill.billDevices}
                      stripe={true}
                      height={400}
                      emptyText="No se encontraron Registros"
                    />
                  </Layout.Row>
                </Card>
              </Layout.Row>
            )}
            <Footer />
          </Layout.Col>
        </Layout.Row>
      </Loading>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(BillDetail);
