import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Input, Table, Button, MessageBox, Dialog, } from 'element-react';
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import './BillingCycle';
import { cycleCreateRequest, cycleUpdateRequest, cycleDeleteRequest, paginationCycleListRequest } from './BillingCycleRequest';

function BillingCycle(props) {

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 14,
    total: 0
  });

  const [createCycle, setCreateCycle] = useState({
    name: "",
    start_day: 0,
    end_day: 0,
    description: ""
  });

  const [editCycle, setEditCycle] = useState({
    id: null,
    name: "",
    start_day: 0,
    end_day: 0,
    description: ""
  });

  var columns = [
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Inicio",
      prop: "start_day",
    },
    {
      label: "Fin",
      prop: "end_day",
    },
    {
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((data['description'] == null || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => cycleDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  useEffect(async () => {
    var result = await paginationCycleListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createCycle };
    newValues[key] = value;
    setCreateCycle(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editCycle };
    newValues[key] = value;
    setEditCycle(newValues);
  }

  async function copyDataUpdate(item) {
    setEditCycle(item);
    setDialogEditVisible(true);
  }

  async function cycleCreateApi() {
    let values = createCycle;
    var result = await cycleCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationCycleListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function cycleUpdateApi(value) {
    var values = editCycle;
    var result = await cycleUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationCycleListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function cycleDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este sector?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await cycleDeleteRequest(id);
      if (result) {
        var result = await paginationCycleListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateCycle({
      name: "",
      start_day: 0,
      end_day: 0,
      description: ""
    });
    setEditCycle({
      id: null,
      name: "",
      start_day: 0,
      end_day: 0,
      description: ""
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    let value = {
      page: pagination.page,
      pageSize: pagination.pageSize,
    }

    var result = await paginationCycleListRequest(value);
    if (result) {
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="3-5" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Facturación</Breadcrumb.Item>
              <Breadcrumb.Item>Ciclos de Facturación</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Ciclo</Button>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={500}
              emptyText="No se encontraron ciclos de facturación" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      <Dialog
        size="small"
        title="CREAR CICLO"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createCycle} labelWidth="120" color="#ffffff" labelPosition="top">
            <Form.Item label="Nombre del ciclo">
              <Input className="input-style" placeholder="Ingrese un nombre" value={createCycle.name} onChange={(value) => onChangeCreate('name', value)}></Input>
            </Form.Item>
            <Layout.Row gutter="10">
              <Layout.Col sm="12" xs="24">
                <Form.Item label="Día inicio">
                  <Input className="input-style" type="Number" min="0" max="31" placeholder="Ingrese un día" value={createCycle.start_day} onChange={(value) => onChangeCreate('start_day', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col sm="12" xs="24">
                <Form.Item label="Día fin">
                  <Input className="input-style" type="Number" min="0" max="31" placeholder="Ingrese un día" value={createCycle.end_day} onChange={(value) => onChangeCreate('end_day', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createCycle.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => cycleCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog
        size="small"
        title="EDITAR CICLO"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editCycle} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre del ciclo" >
              <Input className="input-style" placeholder="Ingrese un nombre" value={editCycle.name} onChange={(value) => onChangeEdit('name', value)}></Input>
            </Form.Item>
            <Layout.Row gutter="10">
              <Layout.Col sm="12" xs="24">
                <Form.Item label="Día inicio">
                  <Input className="input-style" type="Number" min="0" max="31" placeholder="Ingrese un día" value={editCycle.start_day} onChange={(value) => onChangeCreate('start_day', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col sm="12" xs="24">
                <Form.Item label="Día fin">
                  <Input className="input-style" type="Number" min="0" max="31" placeholder="Ingrese un día" value={editCycle.end_day} onChange={(value) => onChangeCreate('end_day', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Descripción">
              <Input className="text-area-style" type="textarea" placeholder="Ingrese una descripción" value={editCycle.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => cycleUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(BillingCycle);
