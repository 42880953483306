import axios from "axios";
import { Message } from "element-react";
import { LOG_OUT } from "../redux/auth/types";
import store from "../store";
import moment from "moment-timezone";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      var jwt = localStorage.getItem("jwt");
      var organizationId = localStorage.getItem("organizationId");
      var myDate = new Date();
      var myOffset = myDate.getTimezoneOffset() / -60;
      var timeZone = myOffset;

      if (jwt) {
        headers["x-access-token"] = jwt;
      }

      if (organizationId) {
        headers["organizationId"] = organizationId;
      }

      if (timeZone) {
        headers["timeZone"] = timeZone;
      }

      return JSON.stringify(data);
    },
  ],
});

// Interceptar respuesta para cuándo la sesión haya expirado
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var data = error.response.data.data;

    if (
      data.name == "TokenExpiredError" ||
      data.name == "JsonWebTokenError" ||
      data.name == "SessionVerificationError"
    ) {
      Message({
        message: "La sesión ha expirado, por favor inicie sesión de nuevo",
        type: "error",
        showClose: true,
        customClass: "message-position",
      });
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("organizationId");
      store.dispatch({
        type: LOG_OUT,
      });
      Promise.reject("La sesión ha expirado");
    }
    return error;
  }
);

export default axiosInstance;
