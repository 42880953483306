import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import 'element-theme-default';
import { Pagination, Layout, Form, Card, Breadcrumb, Input, Table, Button, Dialog, Switch } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Profiles.css';
import { paginationProfileListRequest, profileCreateRequest, profileUpdateRequest } from './ProfilesRequest';

function Profile(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [isLoading, setIsLoading] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createProfile, setCreateProfile] = useState({
    name: "",
    profile_see: false,
    profile_create: false,
    profile_edit: false,
    profile_delete: false,
    description: ""
  });

  const [editProfile, setEditProfile] = useState({
    name: "",
    profile_see: false,
    profile_create: false,
    profile_edit: false,
    profile_delete: false,
    description: ""
  });

  var columns = [
    {
      label: "Nombre",
      prop: "name",
    }, {
      label: "Privilegios",
      subColumns: [
        {
          label: "Ver",
          prop: "profile_see",
          render: (data, column) => {
            return (data.profile_see) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
          }
        },
        {
          label: "Crear",
          prop: "profile_create",
          render: (data, column) => {
            return (data.profile_create) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
          }
        },
        {
          label: "Editar",
          prop: "profile_edit",
          render: (data, column) => {
            return (data.profile_edit) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
          }
        },
        {
          label: "Borrar",
          prop: "profile_delete",
          render: (data, column) => {
            return (data.profile_delete) ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
          }
        }
      ]
    },
    {
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((data['description'] == null || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
        </Button.Group>);

      }
    }
  ];

  useEffect(async () => {
    var result = await paginationProfileListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createProfile };
    newValues[key] = value;
    setCreateProfile(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editProfile };
    newValues[key] = value;
    setEditProfile(newValues);
  }

  async function copyDataUpdate(item) {
    setEditProfile(item);
    setDialogEditVisible(true);
  }

  async function profileCreateApi() {
    let values = createProfile;
    var result = await profileCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationProfileListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function profileUpdateApi(value) {
    var values = editProfile;
    var result = await profileUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationProfileListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateProfile({
      name: "",
      profile_see: false,
      profile_create: false,
      profile_edit: false,
      profile_delete: false,
      description: ""
    });
    setEditProfile({
      name: "",
      profile_see: false,
      profile_create: false,
      profile_edit: false,
      profile_delete: false,
      description: ""
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    let value = {
      page: pagination.page,
      pageSize: pagination.pageSize,
    }

    var result = await paginationProfileListRequest(value);
    if (result) {
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="6-4" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Perfiles</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Perfil</Button>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={500}
              border={true}
              emptyText="No se encontraron Roles" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal crear */}
      <Dialog
        size="small"
        title="CREAR PERFIL"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createProfile} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre">
              <Input className="input-style" placeholder="Ingrese un nombre" value={createProfile.name} onChange={(value) => onChangeCreate('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createProfile.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
            <Card className="box-card" style={{ overflow: "visible" }} header={
              <p style={{ margin: "0", color: "#000000" }}>Privilegios</p>
            } >
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Ver">
                    <Switch value={createProfile.profile_see} onText="Si" offText="No" onChange={(value) => onChangeCreate('profile_see', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Crear">
                    <Switch value={createProfile.profile_create} onText="Si" offText="No" onChange={(value) => onChangeCreate('profile_create', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Editar">
                    <Switch value={createProfile.profile_edit} onText="Si" offText="No" onChange={(value) => onChangeCreate('profile_edit', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Borrar">
                    <Switch value={createProfile.profile_delete} onText="Si" offText="No" onChange={(value) => onChangeCreate('profile_delete', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            </Card>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button disabled={!props.roles.profiles.profile_create} className="btn-save" type="primary" onClick={() => profileCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog >

      {/* Modal editar */}
      < Dialog
        size="small"
        title="EDITAR PERFIL"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)
        }
        lockScroll={false}
        showClose={false} >
        <Dialog.Body>
          <Form className="es-CO" model={editProfile} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre">
              <Input placeholder="Ingrese un nombre" className="text-area-style" value={editProfile.name} onChange={(value) => onChangeEdit('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input placeholder="Ingrese una descripción" className="text-area-style" type="textarea" value={editProfile.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
            <Card className="box-card" style={{ overflow: "visible" }} header={
              <p style={{ margin: "0", color: "#000000" }}>Privilegios</p>
            } >
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Ver">
                    <Switch value={editProfile.profile_see} onText="Si" offText="No" onChange={(value) => onChangeEdit('profile_see', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Crear">
                    <Switch value={editProfile.profile_create} onText="Si" offText="No" onChange={(value) => onChangeEdit('profile_create', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Editar">
                    <Switch value={editProfile.profile_edit} onText="Si" offText="No" onChange={(value) => onChangeEdit('profile_edit', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Borrar">
                    <Switch value={editProfile.profile_delete} onText="Si" offText="No" onChange={(value) => onChangeEdit('profile_delete', value)}></Switch>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            </Card>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => profileUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog >

    </div >
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Profile);

