import { useEffect, useState } from "react";
import "element-theme-default";
import { Button, Layout, Select, } from "element-react";
import "./NavBar.css";
import { connect } from "react-redux";
import { logOut, setOrganization } from "../../redux/auth/actions";
import { organizationsByUser } from "./NavBarRequest";
import logo_navbar from '../../components/images/logo_navbar.svg';

function NavBar(props) {

	// Variables de Estado de Alimentación
	const [organizationList, setOrganizationList] = useState([]);

	// Obtener la lista organizaciones por usuario
	useEffect(async () => {
		if (props.showDataProfile) {
			if (props.userId) {
				var result = await organizationsByUser(props.userId);
				if (result) {
					setOrganizationList(result);
					if (result.length === 1 && result[0].organization) {
						props.setOrganization(result[0].organization.id);
					}
				} else {
					setOrganizationList([]);
				}
			}
		}
	}, []);

	function onChange(value) {
		if (value) {
			props.setOrganization(value);
			window.location.reload();
		}
	}


	return (
		<div>
			<Layout.Row className="nav-fixed" gutter="10">
				<Layout.Col xs="14" sm="14" md="14" lg="14">
					{(props.showDataProfile) ? (
						<div>
							<a onClick={props.showToolBar}>
								<i
									className="fas fa-bars fa-2"
									style={{ float: "left", padding: "15px", marginRight: "15px", color: "white", marginTop: "10px" }}
								></i>
							</a>
							<div style={{ float: "left", padding: "5px", marginTop: "10px" }}>
								<div className="clearfix">
									<p style={{ fontSize: "25px", margin: "0px", color: "white" }}>{(props.isLoggedIn) ? props.userFullName : ' '}</p>
								</div>
							</div>
						</div>
					) : ("' '")}
				</Layout.Col>

				<Layout.Col xs="4" sm="4" md="4" lg="4">
					<img src={logo_navbar} className="logo_navbar" />
				</Layout.Col>

				<Layout.Col xs="4" sm="4" md="4" lg="4">
					{(props.showDataProfile) ? (
						<div>
							{(props.isLoggedIn) ?
								<Select className="input-style" style={{ width: "100%", marginTop: "10px", marginRight: "-15px" }} value={props.organizationId} placeholder="Seleccione una organización" onChange={(value) => onChange(value)}>
									<Select.Option key={null} label={'Seleccione una organización'} value={null}></Select.Option>
									{
										organizationList.map((item) => {
											return <Select.Option key={item.organization.id} label={item.organization.name} value={item.organization.id}></Select.Option>
										})
									}
								</Select>
								: "         "}
						</div>
					) : ("' '")}
				</Layout.Col>

				<Layout.Col xs="2" sm="2" md="2" lg="2">
					{(props.isLoggedIn) ?
						<Button type="primary" onClick={() => { props.logOut() }} className="btn-logout"><i className="fas fa-sign-out-alt fa-2x" style={{ float: "right", padding: "5px", marginRight: "10px", color: "white" }}></i></Button>
						: "           "}
					{/* Botón de Cierre de Sesión  */}
				</Layout.Col>
			</Layout.Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	userFullName: state.auth.userFullName,
	isLoggedIn: state.auth.isLoggedIn,
	organizationId: state.auth.organizationId,
	userId: state.auth.userId
});


export default connect(mapStateToProps, { logOut, setOrganization })(NavBar);
