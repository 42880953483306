import { createStore,applyMiddleware } from 'redux';
import rootReducer from './redux/reducers/index';
import thunk from 'redux-thunk';
import { loadState } from './localStorage';

const initialState = loadState();

const middleware = [thunk];


const store = createStore(rootReducer,initialState,applyMiddleware(...middleware));

export default store;