import React from "react";
import GoogleMapReact from 'google-map-react';
import { API_KEY_MAPS } from "../../constant";


function GoogleMap(props) {
    if (props.coordinates && props.coordinates.lat && props.coordinates.lng) {
        console.log(props.coordinates.lat);
        console.log(props.coordinates.lng);
        let lat = parseFloat(props.coordinates.lat);
        let lng = parseFloat(props.coordinates.lng);

        console.log(lat);
        console.log(lng);

        const renderMarkers = (map, maps) => {
            if (props.coordinates) {
                let marker = new maps.Marker({
                    position: { lat: lat, lng: lng },
                    map,
                    title: ' '
                });
                return marker;
            }
        };

        return (

            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY_MAPS }}
                    defaultCenter={{ lat: lat, lng: lng }}
                    defaultZoom={18}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            </div>
        );
    } else {
        return <div> <p style={{ color: "#ffffff" }}> No se encontraron coordenadas...</p> </div>
    }
}

export default GoogleMap;
