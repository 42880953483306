import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Select, Input, Table, Button, MessageBox, Dialog, } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Sector.css';
import { sectorCreateRequest, sectorUpdateRequest, sectorDeleteRequest, departmentListRequest, citiesByDepartment, paginationSectorListRequest, cityListRequest } from './SectorRequest';

function Sector(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }  
  const [loadingSearch, setLoading] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createSector, setCreateSector] = useState({
    code:"",
    name: "",
    city_id: null,
    description: ""
  });

  const [editSector, setEditSector] = useState({
    code:"",
    name: "",
    city_id: null,
    department_id: null,
    description: ""
  });

  var columns = [
    {
      label: "Código",
      prop: "code",
    },
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Ciudad",
      prop: "city.name",
    },
    {
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((data['description'] == null || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => sectorDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    name: "",
    city_id: null,
  });

  useEffect(async () => {

    var result = await paginationSectorListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var result = await departmentListRequest();
    if (result) {
      setDepartments(result);
    }

    var result = await cityListRequest();
    if (result) {
      setCitiesList(result);
    }

  }, []);

  async function listCities(key, value) {
    var result = await citiesByDepartment(value);
    if (result) {
      setCities(result);
    }
  }

  function onChangeCreate(key, value) {
    let newValues = { ...createSector };
    newValues[key] = value;
    setCreateSector(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editSector };
    newValues[key] = value;
    setEditSector(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function copyDataUpdate(item) {
    await listCities("id", item.city.department_id);
    item.department_id = item.city.department_id;
    setEditSector(item);
    setDialogEditVisible(true);
  }

  async function sectorCreateApi() {
    let values = createSector;
    var result = await sectorCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationSectorListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function sectorUpdateApi(value) {
    var values = editSector;
    var result = await sectorUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationSectorListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function sectorDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este sector?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await sectorDeleteRequest(id);
      if (result) {
        var result = await paginationSectorListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateSector({
      code:"",
      name: "",
      city_id: null,
      description: ""
    });
    setEditSector({
      code:"",
      name: "",
      city_id: null,
      department_id: null,
      description: ""
    });
    setQueryData({
      name: "",
      city_id: null,
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchSector();
  }

  async function searchSector() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;
      var result = await paginationSectorListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listSectors() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationSectorListRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="4-2" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Sector</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Sector</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="10" xs="24">
                    <Form.Item className="label-filter" label="Nombre">
                      <Input value={queryData.name} onChange={(value) => handleQueryChange('name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="10" xs="24">
                    <Form.Item className="label-filter" label="Ciudad">
                      <Select
                        filterable={true}
                        style={{ width: "100%" }}
                        clearable={true}
                        placeholder="Seleccione una ciudad"
                        value={queryData.city_id}
                        onChange={(value) => {
                          handleQueryChange("city_id", value);
                        }}>
                        {citiesList.map((item) => (
                          <Select.Option key={item.id} label={item.name} value={item.id} />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchSector()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listSectors() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron Sectores" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      <Dialog
        size="small"
        title="CREAR SECTOR"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createSector} labelWidth="120" labelPosition="top">
            <Form.Item label="Departamento">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                clearable={true} filterable={true}
                placeholder="Seleccione un departamento"
                onChange={(value) => {
                  listCities("id", value);
                }}>
                {departments.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Ciudad">
              <Select
                className="input-style"
                filterable={true}
                style={{ width: "100%" }}
                clearable={true}
                placeholder="Seleccione una ciudad"
                value={createSector.city_id}
                onChange={(value) => {
                  onChangeCreate("city_id", value);
                }}>
                {cities.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Código">
              <Input className="input-style" placeholder="Ingrese un código" value={createSector.code} onChange={(value) => onChangeCreate('code', value)}></Input>
            </Form.Item>
            <Form.Item label="Nombre del sector">
              <Input className="input-style" placeholder="Ingrese un nombre" value={createSector.name} onChange={(value) => onChangeCreate('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createSector.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => sectorCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog
        size="small"
        title="EDITAR SECTOR"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editSector} labelWidth="120" labelPosition="top">
            <Form.Item label="Departamento">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                clearable={true} filterable={true}
                placeholder="Seleccione un departamento"
                value={editSector.department_id}
                onChange={(value) => {
                  listCities("id", value);
                }}>
                {departments.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Ciudad">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                placeholder="Seleccione una ciudad"                
                clearable={true} filterable={true}
                value={editSector.city_id}
                onChange={(value) => {
                  onChangeEdit("city_id", value);
                }}>
                {cities.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Código" >
              <Input className="input-style" placeholder="Ingrese un código" value={editSector.code} onChange={(value) => onChangeEdit('code', value)}></Input>
            </Form.Item>
            <Form.Item label="Nombre del sector" >
              <Input className="input-style" placeholder="Ingrese un nombre" value={editSector.name} onChange={(value) => onChangeEdit('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" type="textarea" placeholder="Ingrese una descripción" value={editSector.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => sectorUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Sector);
