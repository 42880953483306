import axios from "../../../utilities/axiosInstance";
import handleResponse from "../../../utilities/responseReceiver";
import { Message } from "element-react";

/**
 * Obtener los valores para el grafico de alarmas
 */
export async function getAlarmsChart() {
    try {
        let { data } = await axios.get('/alarmschart');

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener los datos para gráficar",
            type: 'error',
            customClass :"message-position"
        });
    }
}

