import { Message } from "element-react";
import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";

export async function getUsers(values) {
    try {
        let { data } = await axios.get('/auth/users', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener los usuarios",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Enviar los datos para actualizar usuario
 */
export async function updateUser(values) {
    try {
        let { data } = await axios.post('/auth/update', values);

        if (handleResponse(data, true)) {
            let result = data.data;
            return result;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al actualizar el usuario",
            type: 'error',
            customClass :"message-position"
        })
    }
}

/**
 * Enviar los datos del nuevo usuario
 */
export async function registerUser(values) {
    try {
        let { data } = await axios.post('/auth/signup', values);

        if (handleResponse(data, true)) {
            let result = data.data;
            return result;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al crear el usuario",
            type: 'error',
            customClass :"message-position"
        })
    }
}

/**
 * Obtiene la lista de roles
 */
 export async function getRoles(){
    try {
        let {data} = await axios.get("/auth/roles");
      
        if (handleResponse(data)){
            let values = data.data;
            return values;
        }else{
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message:"Hubo un error al obtener los roles",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Obtiene la lista de las organizaciones
 */
 export async function getOrganizations(){
    try {
        let {data} = await axios.get("/organizations");
        if (handleResponse(data)){
            let values = data.data;
            return values;
        }else{
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message:"Hubo un error al obtener las organizaciones",
            type: 'error',
            customClass :"message-position"
        });
    }
}