import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Form, Button, Layout, Breadcrumb, Table, DateRangePicker, Select, Input, Dialog, Card, Switch, Pagination } from "element-react";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import date from 'date-and-time';
import Footer from "../../components/footer/Footer";
import { getLogs, getHistoryLogs } from "./LogsRequest";


function Logs(props) {

	// Variables de Estado de Formulario de Busqueda
	const [queryData, setQueryData] = useState({
		"action_Type": null,
		"model": null,
		"attribute": null,
		"date": null,
		"date_start": null,
		"date_end": null
	});

	// Variables de Estado de Alimentación
	const [logsList, setLogsList] = useState([]);
	const [historyLogsList, setHistoryLogsList] = useState([]);

	// Variables de Estado de Formulario
	const [attributeName, setAttributeName] = useState([""]);
	const [actionList, setActionList] = useState(["Crear", "Editar", "Eliminar"]);

	// Varibles de Estado de la Interfaz
	const [loadingSearch, setLoading] = useState(false);
	const [dialogiHistoryVisible, setDialogiHistoryVisible] = useState(false);
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};
	const [pagination, setPagination] = useState({
		page: 0,
		pageSize: 10,
		total: 0
	});
	const [rangeDate, setRangeDate] = useState({
		date_start: null,
		date_end: null
	});
	const columns = [
		{
			label: "Acción",
			prop: "action_Type",
		},
		{
			label: "Modelo",
			prop: "model",
		},
		{
			label: "Campo",
			prop: "attribute",
		},
		{
			label: "Valor anterior",
			prop: "previous_values",
		},
		{
			label: "Valor actual",
			prop: "new_value",
		},
		{
			label: "Usuario",
			prop: "fullname",
			render: (data, column) => {
				return (data.user.name + ' ' + data.user.last_name)
			}
		},
		{
			label: "Fecha",
			render: (data, column) => {
				if (data.created_at) {
					//let newDate = date.addHours(new Date(data.created_at), -5);
					let newDate = new Date(data.created_at);
					let dateFormat = date.format(newDate, "YYYY-MM-DD HH:mm:ss");
					return dateFormat
				} else {
					return "-";
				}

			}
		},
		{
			label: "Acciones",
			prop: "actions",
			render: (item) => {
				return (<Button.Group>
					<Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" icon="search" size="mini" onClick={() => showHistoryAttribute(item)}>Historial</Button>
				</Button.Group>);

			}
		}
	];

	const columnsHistory = [
		{
			label: "Acción",
			prop: "action_Type",
		},
		{
			label: "Modelo",
			prop: "model",
		},
		{
			label: "Valor anterior",
			prop: "previous_values",
		},
		{
			label: "Valor actual",
			prop: "new_value",
		},
		{
			label: "Usuario",
			prop: "fullname",
			render: (data, column) => {
				return (data.user.name + ' ' + data.user.last_name)
			}
		},
		{
			label: "Fecha",
			render: (data, column) => {
				if (data.created_at) {
					let newDate = new Date(data.created_at);
					let dateFormat = date.format(newDate, "YYYY-MM-DD HH:mm:ss");
					return dateFormat
				} else {
					return "-";
				}

			}
		},
	];

	function handleQueryChange(key, value) {
		let newQuery = { ...queryData };
		newQuery[key] = (key != "date") ? value : null;
		newQuery['date_start'] = (key == "date" && value) ? value[0] : null;
		newQuery['date_end'] = (key == "date" && value) ? value[1] : null;
		setQueryData(newQuery);
	}

	async function searchLogs() {
		try {
			setLoading(true);
			var values = { ...queryData };
			values.page = pagination.page;
			values.pageSize = pagination.pageSize;

			if (values.date_start instanceof Date && values.date_end instanceof Date) {
				values.date_start = date.format(values.date_start, "YYYY-MM-DD HH:mm:ss.SSS");
				values.date_end = date.format(values.date_end, "YYYY-MM-DD HH:mm:ss.SSS");
			}

			var logs = await getLogs(values);
			if (logs) {
				pagination.total = logs.count;
				setLogsList(logs.rows);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function showHistoryAttribute(item) {
		setAttributeName(item.attribute)
		var values = {
			attribute: item.attribute,
			entity_id: item.entity_id,
			model: item.model
		}
		var history = await getHistoryLogs(values);
		if (history) {
			setHistoryLogsList(history);
			setDialogiHistoryVisible(true);
		}
	}

	async function cleanForm() {
		setPagination({
			page: 0,
			pageSize: 10,
			total: 0
		});
		setDialogiHistoryVisible(false);
		setQueryData({
			"action_Type": null,
			"model": null,
			"attribute": null,
			"date": null,
			"date_start": null,
			"date_end": null
		})
		setRangeDate({
			date_start: null,
			date_end: null
		});
		setLogsList([]);
	}

	async function onCurrentChange(page) {
		pagination.page = page;
		searchLogs();

	}

	return (
		<div className="container">
			<NavBar showDataProfile={true} showToolBar={showToolBar} />
			<ToolBar stateToolBar={stateToolBar} active="6-5" />

			<Layout.Row>
				<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
					<br />
				</Layout.Col>

				<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
					<Layout.Row style={{ padding: "20px" }}>
						<Breadcrumb separator="/">
							<Breadcrumb.Item>Logs</Breadcrumb.Item>
						</Breadcrumb>
					</Layout.Row>

					{/* Formulario de busqueda */}
					<Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
						<Layout.Row>
							<Layout.Col span="22" offset="1">
								<Layout.Row gutter="10">
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="Acción">
											<Select
												style={{ width: "100%" }}
												placeholder="Seleccione una acción"
												value={queryData.action_Type}
												onChange={(value) => {
													handleQueryChange("action_Type", value);
												}}>
												{actionList.map((item) => (
													<Select.Option key={item} label={item} value={item} />
												))}
											</Select>
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="Modelo">
											<Input value={queryData.model} onChange={(value) => handleQueryChange('model', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="Campo">
											<Input value={queryData.attribute} onChange={(value) => handleQueryChange('attribute', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="Fecha" style={{ marginTop: '-10px' }}>
											<DateRangePicker
												isShowTime={true}
												value={rangeDate}
												onChange={(date) => {
													handleQueryChange('date', date);
													setRangeDate(date);
												}}
											/>
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="2" xs="24">
										<Button
											disabled={!props.roles.profiles.profile_see}
											className="btn-create"
											type="primary"
											icon="search"
											loading={loadingSearch}
											onClick={() => searchLogs()}
											style={{ marginTop: '23px' }}>
											Buscar
										</Button>
									</Layout.Col>
									<Layout.Col span="2" xs="24">
										<Button
											disabled={!props.roles.profiles.profile_see}
											className="btn-delete"
											type="primary"
											icon="close"
											onClick={() => cleanForm()}
											style={{ marginTop: '23px' }}>
										</Button>
									</Layout.Col>
								</Layout.Row>
							</Layout.Col>
						</Layout.Row>
					</Form>

					<Layout.Row style={{ padding: "10px" }}>
						<Table
							style={{ width: "100%" }}
							columns={columns}
							data={logsList}
							stripe={true}
							height={500}
							highlightCurrentRow={true}
							emptyText="No se encontraron logs"
						/>
					</Layout.Row>
					<Layout.Row>
						<Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
					</Layout.Row>
					<Footer />
				</Layout.Col>
			</Layout.Row>

			{/* Modal Historico */}
			<Dialog
				size="large"
				visible={dialogiHistoryVisible}
				onCancel={() => setDialogiHistoryVisible(false)}
				lockScroll={true}
				showClose={false}>
				<Dialog.Body>
					<Card className="box-card" style={{ overflow: "visible" }}
						header={
							<p style={{ fontSize: "24px", color: "#20a0ff", margin: "0" }}>Historial del Campo {attributeName}</p>
						}>
						<Layout.Row style={{ padding: "10px" }}>
							<Table
								style={{ width: "100%" }}
								columns={columnsHistory}
								data={historyLogsList}
								stripe={true}
								height={500}
								highlightCurrentRow={true}
								emptyText="No se encontraron logs"
							/>
						</Layout.Row>

					</Card>
				</Dialog.Body>
				<Dialog.Footer className="dialog-footer">
					<Button onClick={() => cleanForm()}>Cancelar</Button>
				</Dialog.Footer>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => ({
	organizationId: state.auth.organizationId,
	roles: state.auth.roles,
});

export default connect(mapStateToProps)(Logs);
