import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR DISPOSITIVOS***********
-------------------------------------*/
export async function deviceListRequest(value) {
    try {
        let { data } = await axios.get("/devices");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CREAR DISPOSITIVO************
-------------------------------------*/
export async function deviceCreateRequest(value) {
    try {
        let { data } = await axios.post('/devices', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el dispositivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR DISPOSITIVO***************
-------------------------------------*/
export async function deviceUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/devices`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el dispositivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR DISPOSITIVO***************
-------------------------------------*/
export async function deviceDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/devices?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el dispositivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationDeviceListRequest(value) {
    try {
        let { data } = await axios.get("/devices/pagination", { params: value }); 

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER DISPOSITIVOS PARA HHU****************
----------------------------------------------------*/
export async function getDevicesHhu(value) {
    try {
        let { data } = await axios.get("/devices/deviceshhu", { params: value }); 

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista de dispositivos para HHU",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********LISTAR LOS USUARIOS FINALES***********
-------------------------------------*/
export async function endUsersListRequest(value) {
    try {
        let { data } = await axios.get("/auth/end_users");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de usuarios finales",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********GUARDAR IMAGEN DE DISPOSITIVO************
-------------------------------------*/
export async function uploadDeviceImage(value) {
    try {
        let { data } = await axios.post('/uploadDeviceImage', value);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el dispositivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTIENE LOS ARCHIVOS POR DISPOSITIVO****************
----------------------------------------------------*/
export async function getFilesByDevice(value) {
    try {
        let { data } = await axios.get("/getFilesByDevice", { params: value }); 

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener los archivos por dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR IMAGEN***************
-------------------------------------*/
export async function fileDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/devices/delete_file?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el archivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********LISTAR DISPOSITIVOS INACTIVOS***********
-------------------------------------*/
export async function inactiveDevicesListRequest(value) {
    try {
        let { data } = await axios.get("/inactive_devices");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de dispositivos inactivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CONTROL DE VALVULA************
-------------------------------------*/

/*------------------------------------
***LISTAR COMANDOS POR MODELO***
-------------------------------------*/
export async function commandsByModel(value) {
    try {
        let { data } = await axios.get(`/commands?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de comandos por modelos",
            type: 'error',
            customClass: "message-position"
        });
    }
}

export async function statusValveRequest(value) {
    try {
        let { data } = await axios.post('/status_valve', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al cambiar el estado de la válvula",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********LISTAR PERFIL DE DISPOSITIVO AWS***********
-------------------------------------*/
export async function deviceProfileRequest() {
    try {
        let { data } = await axios.get("/devices/device_profile");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado deperfiles de dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********LISTAR PERFIL DE SERVICIOS AWS***********
-------------------------------------*/
export async function serviceProfileRequest() {
    try {
        let { data } = await axios.get("/devices/service_profile");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado perfiles de servicio",
            type: 'error',
            customClass :"message-position"
        });
    }
}
