import axios from '../../../utilities/axiosInstance';


/*------------------------------------
***LISTAR HISTORIAL DISPOSITIVO*******
-------------------------------------*/
export async function deviceHistoryRequest(value){


    try{

        let response = await axios.get(`/devices/history?id=${value}`);

        return response.data;
    }catch(error){
        console.log(error);
        return {
            data:null,
            error:true,
            message:"Hubo un problema al obtener el listado de dispositivos"
        }
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationDeviceHistoryRequest(value) {
	try {
		let response = await axios.get("/devices/pagination_history", { params: value });

        return response.data;
	} catch (error) {
		console.log(error);
		return {
			data: null,
			error: true,
			message: "Hubo un problema al obtener la lista paginada de dispositivos",
		};
	}
}

/*---------------------------------------------------
*****OBTENER CONTRATOS POR DISPOSITIVOS****************
----------------------------------------------------*/
export async function getContractByDevice(value) {
    console.log(value);
	try {
		let response = await axios.get("/devices/get_contract", { params: value });

        return response.data;
	} catch (error) {
		console.log(error);
		return {
			data: null,
			error: true,
			message: "Hubo un problema al obtener la lista de contratos por dispositivo",
		};
	}
}