import React from "react";


function SignalSVG(props) {
    return (
        <div>
            <svg version="1.1" id={props.id} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 12.5 9.1" style={{ enableBackground: "new 0 0 12.5 9.1", height: "20px" }} xmlSpace="preserve">
                <g id={props.id}>
                    <g>
                        <path style={{ fill: props.fill0 }} d="M0.6,5C0.3,5,0,5.3,0,5.6v2.9c0,0.3,0.3,0.6,0.6,0.6h1.1c0.3,0,0.6-0.2,0.6-0.6V5.6C2.3,5.3,2.1,5,1.7,5H0.6z
                        "/>
                        <path style={{ fill: props.fill1 }} d="M5.7,8.5c0,0.3-0.3,0.6-0.6,0.6H4c-0.3,0-0.6-0.2-0.6-0.6V4c0-0.3,0.3-0.6,0.6-0.6h1.1c0.3,0,0.6,0.3,0.6,0.6
			            V8.5z"/>
                        <path style={{ fill: props.fill2 }} d="M9.1,8.5c0,0.3-0.3,0.6-0.6,0.6H7.4c-0.3,0-0.6-0.2-0.6-0.6V2.3c0-0.3,0.3-0.6,0.6-0.6h1.1
			            c0.3,0,0.6,0.3,0.6,0.6V8.5z"/>
                        <path style={{ fill: props.fill3 }} d="M12.5,8.5c0,0.3-0.3,0.6-0.6,0.6h-1.1c-0.3,0-0.6-0.2-0.6-0.6V0.6c0-0.3,0.3-0.6,0.6-0.6h1.1
			            c0.3,0,0.6,0.3,0.6,0.6V8.5z"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default SignalSVG;