import axios from "../../../utilities/axiosInstance";
import handleResponse from "../../../utilities/responseReceiver";
import { Message } from "element-react";

/**
 * Obtener los valores de las alarmas
 * @param {*} values
 */
export async function getAlarms(values) {
    try {
        let { data } = await axios.get('/alarms', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de alarmas",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Obtiene los posibles estados de alarma
 */
export async function getAlarmStatus() {
    try {
        let { data } = await axios.get('/alarmstatus');

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener los estados de alarma",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Obtiene los nombres de los dispositivos
 */
export async function getDevices() {
    try {
        let { data } = await axios.get('/devices');

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CREA LA ACCIÓN DE RESPUESTA A LA ALARMA******************
-------------------------------------*/
export async function createAlarmAction(value) {
    try {
        let { data } = await axios.post(`/createAlarmAction`, { data: value });
    
        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear la acción de respuesta a la alarma",
            type: 'error',
            customClass :"message-position"
        });
    }
}
