import { Message } from "element-react";

export default function handleResponse({ error, message }, displayMessage = false) {
    if (error) {
        Message({
            showClose: true,
            message: message,
            type: 'error',
            customClass :"message-position"
        });
        return false;
    } else if (displayMessage) {
        Message({
            showClose: true,
            message: message,
            type: 'success',
            customClass :"message-position"
        });
        return true;
    } else {
        return true;
    }
}