import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Select, Input, Table, Button, MessageBox, Dialog, } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Trademark.css';
import { trademarkCreateRequest, trademarkUpdateRequest, trademarkDeleteRequest, paginationTrademarkListRequest } from './TrademarkRequest';

function Trademark(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }  
  const [loadingSearch, setLoading] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createTrademark, setCreateTrademark] = useState({
    name: "",
    description: ""
  });

  const [editTrademark, setEditTrademark] = useState({
    id: null,
    name: "",
    description: ""
  });

  var columns = [
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((data['description'] == null || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => trademarkDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    name: "",
  });

  useEffect(async () => {

    var result = await paginationTrademarkListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createTrademark };
    newValues[key] = value;
    setCreateTrademark(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editTrademark };
    newValues[key] = value;
    setEditTrademark(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function copyDataUpdate(item) {
    setEditTrademark(item);
    setDialogEditVisible(true);
  }

  async function trademarkCreateApi() {
    let values = createTrademark;
    var result = await trademarkCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationTrademarkListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function trademarkUpdateApi(value) {
    var values = editTrademark;
    var result = await trademarkUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationTrademarkListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function trademarkDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar esta marca de medidor?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await trademarkDeleteRequest(id);
      if (result) {
        var result = await paginationTrademarkListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateTrademark({
      name: "",
      description: ""
    });
    setEditTrademark({
      id: null,
      name: "",
      description: ""
    });
    setQueryData({
      name: "",
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchTrademark();
  }

  async function searchTrademark() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;
      var result = await paginationTrademarkListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listTrademark() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationTrademarkListRequest(values);
    if (result) {
      pagination.total = result.count; 
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="4-4" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Marca Medidor</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nueva Marca</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="10" xs="24">
                    <Form.Item className="label-filter" label="Nombre">
                      <Input value={queryData.name} onChange={(value) => handleQueryChange('name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="10" xs="24">
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchTrademark()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listTrademark() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron marcas de dispositivos" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      <Dialog
        size="small"
        title="CREAR MARCA"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createTrademark} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre">
              <Input className="input-style" placeholder="Ingrese un nombre" value={createTrademark.name} onChange={(value) => onChangeCreate('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createTrademark.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => trademarkCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog
        size="small"
        title="EDITAR MARCA"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editTrademark} labelWidth="120" labelPosition="top">
            <Form.Item label="Nombre" >
              <Input className="input-style" placeholder="Ingrese un nombre" value={editTrademark.name} onChange={(value) => onChangeEdit('name', value)}></Input>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" type="textarea" placeholder="Ingrese una descripción" value={editTrademark.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => trademarkUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Trademark);
