import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Select, Input, Table, Button, MessageBox, Dialog, Switch } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Notifications.css';
import { notificationCreateRequest, notificationUpdateRequest, notificationDeleteRequest, paginationNotificationListRequest } from './NotificationsRequest';
import { organizationListRequest } from '../Organization/OrganizationRequest';

function Notification(props) {

  	// Variables de Estado de Alimentación
  const [organizationList, setOrganizationList] = useState([]);
  const [data, setData] = useState([]);

  // Variables de Estado de Formulario
  const [messageType, setMessageType] = useState([
    { id: 'info', type: 'Info' },
    { id: 'success', type: 'Success' },
    { id: 'warning', type: 'Warning' },
    { id: 'error', type: 'Error' },
  ]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createNotification, setCreateNotification] = useState({
    type: "",
    title: "",
    is_active: false,
    message: "",
    organizations: [],
    arrayOrganizations: [],
  });

  const [editNotification, setEditNotification] = useState({
    type: "",
    title: "",
    is_active: false,
    message: "",
    organizations: [],
    arrayOrganizations: [],
  });

  var columns = [
    {
      className: "table-text",
      label: "Tipo",
      prop: "type",
    },
    {
      label: "Título",
      prop: "title",
      className: "table-text",
    },
    {
      label: "Mensaje",
      prop: "message",
      className: "table-text",
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => notificationDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  useEffect(async () => {

    var result = await paginationNotificationListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var result = await organizationListRequest();
    if (result) {
      setOrganizationList(result);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createNotification };
    newValues[key] = value;
    setCreateNotification(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editNotification };
    newValues[key] = value;
    setEditNotification(newValues);
  }

  async function copyDataUpdate(item) {

    let newItem = Object.assign({}, item);
    newItem.arrayOrganizations = [];
    item.organizations.forEach((organization) => {
      newItem.arrayOrganizations.push(organization.id);
    });

    setEditNotification(newItem);
    setDialogEditVisible(true);
  }

  async function notificationCreateApi() {
    let values = createNotification;
    var result = await notificationCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationNotificationListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function notificationUpdateApi(value) {
    var values = editNotification;
    var result = await notificationUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationNotificationListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function notificationDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar esta notificación?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await notificationDeleteRequest(id);
      if (result) {
        var result = await paginationNotificationListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateNotification({
      type: "",
      title: "",
      is_active: false,
      message: "",
      organizations: [],
      arrayOrganizations: [],
    });
    setEditNotification({
      type: "",
      title: "",
      is_active: false,
      message: "",
      organizations: [],
      arrayOrganizations: [],
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    let value = {
      page: pagination.page,
      pageSize: pagination.pageSize,
    }

    var result = await paginationNotificationListRequest(value);
    if (result) {
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="6-7" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/" >
              <Breadcrumb.Item >Configuración</Breadcrumb.Item>
              <Breadcrumb.Item >Notificación</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nueva Notificación</Button>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              columns={columns}
              data={data}
              stripe={true}
              height={500}
              emptyText="No se encontraron Notificaciones" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/*Modal para crear */}
      <Dialog
        size="small"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}
        title="CREAR NOTIFICACIÓN"
      >
        <Dialog.Body>
          <Form className="es-CO" model={createNotification} labelWidth="120" labelPosition="top">
            <Form.Item label="Tipo">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                placeholder="Seleccione tipo"
                value={createNotification.type}
                onChange={(value) => {
                  onChangeCreate("type", value);
                }}>
                {messageType.map((item) => (
                  <Select.Option key={item.id} label={item.type} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Organizaciones">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                value={createNotification.organizations}
                multiple={true}
                onChange={(value) => onChangeCreate('organizations', value)}>
                {
                  organizationList.map((organization) => {
                    return <Select.Option key={organization.id} label={organization.name} value={organization.id}></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Título">
              <Input className="input-style" placeholder="Ingrese un título" value={createNotification.title} onChange={(value) => onChangeCreate('title', value)}></Input>
            </Form.Item>
            <Form.Item label="Activa">
              <Switch value={createNotification.is_active} onText="Si" offText="No" onChange={(value) => onChangeCreate('is_active', value)}></Switch>
            </Form.Item>
            <Form.Item label="Mensaje">
              <Input className="text-area-style" placeholder="Ingrese un mensaje" type="textarea" autosize={{ minRows: 4, maxRows: 8 }} value={createNotification.message} onChange={(value) => onChangeCreate('message', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => notificationCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog
        size="small"
        title="EDITAR NOTIFICACIÓN"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editNotification} labelWidth="120" labelPosition="top">
            <Form.Item label="Tipo">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                placeholder="Seleccione tipo"
                value={editNotification.type}
                onChange={(value) => {
                  onChangeEdit("type", value);
                }}>
                {messageType.map((item) => (
                  <Select.Option key={item.id} label={item.type} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Organizaciones">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                value={editNotification.arrayOrganizations}
                multiple={true}
                onChange={(value) => onChangeEdit('organizations', value)}>
                {
                  organizationList.map((organization) => {
                    return <Select.Option key={organization.id} label={organization.name} value={organization.id}></Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Título">
              <Input className="input-style" placeholder="Ingrese un título" value={editNotification.title} onChange={(value) => onChangeEdit('title', value)}></Input>
            </Form.Item>
            <Form.Item label="Activa">
              <Switch className="input-style" value={editNotification.is_active} onText="Si" offText="No" onChange={(value) => onChangeEdit('is_active', value)}></Switch>
            </Form.Item>
            <Form.Item label="Mensaje">
              <Input className="text-area-style" placeholder="Ingrese un mensaje" type="textarea" autosize={{ minRows: 4, maxRows: 8 }} value={editNotification.message} onChange={(value) => onChangeEdit('message', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => notificationUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Notification);
