import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Layout, Breadcrumb, Tree } from "element-react";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import './VirtualMeter.css'
import { getTreeTotalValues, getChartAlarmByStatus, getChartConsumptionByHour } from "./VirtualMeterRequest";
import { Bar, Line } from 'react-chartjs-2';


function VirtualMeter(props) {

	// Variables de Estado de arbol
	const [treeData, setTreeData] = useState({});

	// Variables de Estado de los graficos
	const [alarmData, setAlarmData] = useState({});
	const [deviceData, setDeviceData] = useState({});
	const [device, setDevice] = useState({});

	// Varibles de Estado de la Interfaz
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};
	const options = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				position: 'top',
				display: false,
			},
			title: {
				display: true,
				text: 'Alarmas ultimos 30 días'
			}
		},
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		},
	};

	const optionsConsumptionByDevice = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Consumo 24 horas'
			}
		},
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},


			],
		},

	};

	useEffect(async () => {
		var result = await getTreeTotalValues();
		if (result) {
			setTreeData(result);
		}

	}, []);

	async function getTotalVolumeApi(data, reactElement) {
	
		switch (data.type) {
			// Selecciono dispositivo
			case 5:
				var device_id = data.id;
				break;

			// Selecciono sector
			case 4:
				var sector_id = data.id;
				break;

			default:
				break;
		}
		if (device_id || sector_id) {

			setDevice(data);

			var resultChart = await getChartAlarmByStatus({ device_id: device_id, sector_id: sector_id });
			if (resultChart) {
				setAlarmData(resultChart);
			}

			var resultChartDevice = await getChartConsumptionByHour({ device_id: device_id, sector_id: sector_id });
			if (resultChartDevice) {
				setDeviceData(resultChartDevice);
			}
		}
	}

	return (
		<div className="container">
			<NavBar showDataProfile={true} showToolBar={showToolBar} />
			<ToolBar stateToolBar={stateToolBar} active="8" />

			<Layout.Row>
				<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
					<br />
				</Layout.Col>
				<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
					<Layout.Row style={{ padding: "20px" }}>
						<Breadcrumb separator="/">
							<Breadcrumb.Item>Medidor Virtual</Breadcrumb.Item>
						</Breadcrumb>
					</Layout.Row>

					<Layout.Row gutter="10" type="flex" className="prueba" >
						<Layout.Col xs="24" sm="8" md="8" lg="8">
							<div className="div-tree-style">
								<Tree
									data={treeData.data}
									options={treeData.options}
									highlightCurrent={true}
									nodeKey="id"
									emptyText="Esperando datos..."
									onCheckChange={(data, checked, indeterminate) => {
										console.debug('onCheckChange: ', data, checked, indeterminate)
									}
									}
									onNodeClicked={(data, reactElement,) => {
										getTotalVolumeApi(data, reactElement);
									}}
								/>
							</div>
						</Layout.Col>
						<Layout.Col xs="24" sm="16" md="16" lg="16">
							<Layout.Row className="div-card-style">
								<Layout.Col xs="24" sm="24" md="24" lg="24">
									<Layout.Row className="div-header-style" >
										<Layout.Col xs="24" sm="2" md="2" lg="2">
											<p className="label-item">Nombre:</p>
										</Layout.Col>
										<Layout.Col xs="24" sm="4" md="4" lg="4">
											<p className="item-value">{(device.name) ? device.name : ""}</p>
										</Layout.Col>
										<Layout.Col xs="24" sm="5" md="5" lg="5">
											<p className="item-value">{(device.eui) ? device.eui : ""}</p>
										</Layout.Col>
										<Layout.Col xs="24" sm="3" md="3" lg="3">
											<p className="label-item">Última lectura:</p>
										</Layout.Col>
										<Layout.Col xs="24" sm="3" md="3" lg="3">
											<p className="item-value">{(device.volume) ? device.volume : ""} m³</p>
										</Layout.Col>
									</Layout.Row>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="24" lg="24">
									<Layout.Row className="row-bg">
										<Bar data={alarmData} options={options} height={80} />
									</Layout.Row>
								</Layout.Col>
								<Layout.Col xs="24" sm="24" md="24" lg="24">
									<Layout.Row className="row-bg" style={{ height: "300px" }}>
										<Bar data={deviceData} options={optionsConsumptionByDevice} />
									</Layout.Row>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>
					</Layout.Row>
				</Layout.Col>
			</Layout.Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	organizationId: state.auth.organizationId,
	roles: state.auth.roles,
});

export default connect(mapStateToProps)(VirtualMeter);
