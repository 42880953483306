import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Layout, Breadcrumb, Table, Button, Dialog, Alert, Select, Form, Input, Switch, Pagination, MessageBox } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Organization.css';
import { organizationCreateRequest, paginationOrganizationListRequest, organizationUpdateRequest, organizationDeleteRequest } from './OrganizationRequest';
import { getIdentificationTypeList } from '../Holders/HoldersRequest';
import { departmentListRequest, citiesByDepartment, } from '../Sector/SectorRequest';

function Organization(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [identificationTypeList, setIdentificationTypeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [dv, setDV] = useState(null);
  const [detailOrganization, setDetailOrganization] = useState([]);

  // Variables de Estado de Formulario
  const [personList, setPersonList] = useState([{ id: 0, type: 'NATURAL' }, { id: 1, type: 'JURIDICA' }]);
  const [roadList, setRoadList] = useState(["Anillo", "Autopista", "Avenida", "Calle", "Carrera", "Circular", "Diagonal", "Transversal", "Kilometro"]);
  const [propertyType, setPropertyType] = useState(["Apartamento", "Bloque", "Casa", "Oficina"]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) };
  const [loadingSearch, setLoading] = useState(false);
  const [haveErrors, setHaveErrors] = useState(false);
  const [textErrors, setTextErrors] = useState("");
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [dialogAddressVisible, setDialogAddressVisible] = useState(false);
  const [dialogDetailVisible, setDialogDetailVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });
  const [createOrganization, setCreateOrganization] = useState({
    person_id: null,
    identification_type_id: null,
    identification: null,
    displayname: "",
    name: "",
    address: '',
    department_id: null,
    city_id: null,
    postal_code: null,
    contact: '',
    email: '',
    phone: '',
    is_active: false
  });
  const [editOrganization, setEditOrganization] = useState({
    person_id: null,
    identification_type_id: null,
    identification: null,
    displayname: "",
    name: "",
    address: '',
    department_id: null,
    city_id: null,
    postal_code: null,
    contact: '',
    email: '',
    phone: '',
    is_active: false
  });

  const [createAddress, setCreateAddress] = useState({
    road: "",
    propertyType: "",
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    apto: "",
  });
  var columns = [
    {
      label: "identificación",
      render: (data, column) => {
        var statusString = `${data.identification_type.abbreviation} - ${data.identification}`;
        return statusString;
      },
    },
    {
      label: "Nombre",
      prop: "displayname",
    },
    {
      label: "Razón Social",
      prop: "name",
    },
    {
      label: "Ciudad - Departamento",
      render: (data, column) => {
        var statusString = `${data.city.name} - ${data.department.name}`;
        return statusString;
      },
    },
    {
      label: "Contacto",
      prop: "contact",
    },
    {
      label: "Activo",
      prop: "is_active",
      render: (data, column) => {
        if (data.is_active) {
          let estatus = 'Si';
          return estatus;
        } else if (!data.is_active) {
          let estatus = 'No';
          return estatus;
        }
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {

        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)}></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => organizationDeleteApi(item.id)}></Button>
          <Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" icon="search" size="mini" onClick={() => showDetail(item)}>Ver Detalle</Button>
        </Button.Group>);

      }
    }
  ];

  var columnsDetailOrganization = [
    {
      label: "Tipo Persona",
      prop: "person_id",
      render: (data, column) => {
        if (data.person_id == 0) {
          let personString = 'NATURAL';
          return personString;
        } else if (data.person_id) {
          let personString = 'JURIDICA';
          return personString;
        }
      }
    },
    {
      label: "Dirección",
      prop: "address",
    },
    {
      label: "Cod.Postal",
      prop: "postal_code",
    },
    {
      label: "E-mail",
      prop: "email",
    },
    {
      label: "Telefono",
      prop: "phone",
    },
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    identification: "",
    displayname: "",
    name: "",
  });

  useEffect(async () => {
    var result = await paginationOrganizationListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var departments = await departmentListRequest();
    if (departments) {
      setDepartmentList(departments);
    }

  }, []);

  async function listIdentificationType(value) {
    var identificationType = await getIdentificationTypeList(value);
    if (identificationType) {
      setIdentificationTypeList(identificationType);
    }
  }

  async function listCities(value) {
    var result = await citiesByDepartment(value);
    if (result) {
      setCityList(result);
    }
  }

  function onChangeCreate(key, value) {
    let newValues = { ...createOrganization };
    newValues[key] = value;
    setCreateOrganization(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editOrganization };
    newValues[key] = value;
    setEditOrganization(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function OrganizationCreateApi() {
    let values = createOrganization;
    setLoading(true);
    var result = await organizationCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationOrganizationListRequest(pagination);
      if (result) {
        setLoading(false);
        pagination.total = result.count;
        setData(result.rows);
      }
    } else {
      setLoading(false);
      setTextErrors("Ocurrio un error inesperado");
      setHaveErrors(true);
    }
  }

  function copyDataUpdate(item) {
    let newItem = Object.assign({}, item);
    verificationCode(item.identification, item.person_id);
    listIdentificationType(newItem.person_id);
    if (newItem.department_id) {
      listCities(newItem.department_id);
    }
    setEditOrganization(newItem);
    setDialogEditVisible(true);
  }

  async function organizationUpdateApi() {
    let values = editOrganization;
    setLoading(true);
    var result = await organizationUpdateRequest(values);
    if (result) {
      setLoading(false);
      cleanForm();
      var result = await paginationOrganizationListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    } else {
      setLoading(false);
      setTextErrors("Ocurrio un error inesperado");
      setHaveErrors(true);
    }
  }

  async function organizationDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar esta organización?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await organizationDeleteRequest(id);
      if (result) {
        var result = await paginationOrganizationListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      } else {
        setTextErrors(result.message);
        setHaveErrors(true);
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setTextErrors("");
    setHaveErrors(false);
    setCreateOrganization({
      person_id: null,
      identification_type_id: null,
      identification: null,
      displayname: "",
      name: "",
      address: '',
      department_id: null,
      city_id: null,
      postal_code: null,
      contact: '',
      email: '',
      phone: '',
      is_active: false
    });
    setEditOrganization({
      person_id: null,
      identification_type_id: null,
      identification: null,
      displayname: "",
      name: "",
      address: '',
      department_id: null,
      city_id: null,
      postal_code: null,
      contact: '',
      email: '',
      phone: '',
      is_active: false
    });

    setQueryData({
      identification: "",
      displayname: "",
      name: "",
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchOrganization();
  }

  //calcula el digito de verificación de la Dian
  function verificationCode(nit, person_id) {
    if (nit && nit.length <= 9) {
      if (createOrganization.person_id == 1 || editOrganization.person_id == 1 || person_id == 1) {
        var vpri, x, y, z;
        vpri = new Array(16);
        z = nit.length;

        vpri[1] = 3;
        vpri[2] = 7;
        vpri[3] = 13;
        vpri[4] = 17;
        vpri[5] = 19;
        vpri[6] = 23;
        vpri[7] = 29;
        vpri[8] = 37;
        vpri[9] = 41;
        vpri[10] = 43;
        vpri[11] = 47;
        vpri[12] = 53;
        vpri[13] = 59;
        vpri[14] = 67;
        vpri[15] = 71;

        x = 0;
        y = 0;
        for (var i = 0; i < z; i++) {
          y = (nit.substr(i, 1));
          x += (y * vpri[z - i]);
        }

        y = x % 11;
        let dv = (y > 1) ? 11 - y : y;

        setDV(dv);
        setTextErrors("");
        setHaveErrors(false);
      }
    } else if (nit && nit.length > 9) {
      setTextErrors("El Nit no puede tener mas de 9 números");
      setHaveErrors(true);
    }
  }

  function onChangeAddres(key, value) {
    let newValues = { ...createAddress };
    newValues[key] = value;
    setCreateAddress(newValues);
  }

  function saveAddress() {
    if (validation()) {
      createOrganization.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      editOrganization.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      cleanFormAdres();
    }
  }

  function validation() {
    createAddress.number1 = createAddress.number1.replace(/ /g, "");
    createAddress.number2 = createAddress.number2.replace(/ /g, "");
    createAddress.number3 = createAddress.number3.replace(/ /g, "");
    createAddress.propertyType = createAddress.propertyType.replace(/ /g, "");
    createAddress.number4 = createAddress.number4.replace(/ /g, "");
    createAddress.apto = createAddress.apto.replace(/ /g, "");

    if (createAddress.road.length == 0) {
      setTextErrors("La via no puede estar vacía.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number1.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number2.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number3.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else {
      setTextErrors("");
      setHaveErrors(false);
      return true;

    }
  }

  function copyAdresToUpdate(address) {
    if (address) {
      let stringAddress = address
      let arrrayAddress = stringAddress.split(' ')

      setCreateAddress({
        road: arrrayAddress[0],
        number1: arrrayAddress[1],
        number2: arrrayAddress[3],
        number3: arrrayAddress[5],
        propertyType: arrrayAddress[6],
        number4: arrrayAddress[7],
        apto: arrrayAddress[8],
      });
    }

    setDialogAddressVisible(true);
  }

  function cleanFormAdres() {
    setCreateAddress({
      road: "",
      propertyType: "",
      number1: "",
      number2: "",
      number3: "",
      number4: "",
      apto: "",
    });

    setDialogAddressVisible(false)
  }

  function showDetail(item) {
    let array = [];
    array.push(item);
    setDetailOrganization(array);
    setDialogDetailVisible(true);
  }

  async function searchOrganization() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;

      var result = await paginationOrganizationListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listOrganizations() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationOrganizationListRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="4-1" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Organización</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nueva Organización</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Identificación">
                      <Input value={queryData.identification} onChange={(value) => handleQueryChange('identification', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="8" xs="24">
                    <Form.Item className="label-filter" label="Nombre">
                      <Input value={queryData.displayname} onChange={(value) => handleQueryChange('displayname', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="8" xs="24">
                    <Form.Item className="label-filter" label="Razon social">
                      <Input value={queryData.name} onChange={(value) => handleQueryChange('name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchOrganization()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listOrganizations() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              height={400}
              highlightCurrentRow={true}
              emptyText="No se encontraron Organizaciones"
            />

          </Layout.Row>
          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal agregar */}
      <Dialog
        title="CREAR ORGANIZACIÓN"
        size="small"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createOrganization} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="7">
                <Form.Item label="Persona">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione persona"
                    value={createOrganization.person_id}
                    onChange={(value) => {
                      listIdentificationType(value);
                      onChangeCreate("person_id", value);
                    }}>
                    {personList.map((item) => (
                      <Select.Option key={item.id} label={item.type} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="7">
                <Form.Item label="Tipo ID">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione Tipo ID"
                    value={createOrganization.identification_type_id}
                    onChange={(value) => {
                      onChangeCreate("identification_type_id", value);
                    }}>
                    {identificationTypeList.map((item) => (
                      <Select.Option key={item.id} label={`(${item.abbreviation}) ${item.description}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              {(createOrganization.identification_type_id == 6 && createOrganization.person_id == 1) ? (
                <Layout.Col xs="24" sm="7">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" type="number" value={createOrganization.identification} onChange={(value) => {
                      verificationCode(value, null);
                      onChangeCreate("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              ) : (
                <Layout.Col xs="24" sm="7">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" maxLength={10} value={createOrganization.identification} onChange={(value) => {
                      onChangeCreate("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              )}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="D.V">
                  <Input className="input-style" placeholder="D.V" value={dv} readOnly></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nombre de la Organización">
                  <Input className="input-style" placeholder="Ingrese un nombre" name="displayName" value={createOrganization.displayname} onChange={(value) => { onChangeCreate("displayname", value) }} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Razón Social">
                  <Input className="input-style" placeholder="Ingrese una razón social" name="name" value={createOrganization.name} onChange={(value) => { onChangeCreate("name", value) }} />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Departamento">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione un departamento"
                    value={createOrganization.department_id}
                    onChange={(value) => {
                      listCities(value);
                      onChangeCreate("department_id", value);
                    }}>
                    {departmentList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Ciudad">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione una ciudad"
                    value={createOrganization.city_id}
                    onChange={(value) => {
                      onChangeCreate("city_id", value);
                    }}>
                    {cityList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Dirección">
                  <Input className="input-style" onChange={(value) => { onChangeCreate("address", value) }} value={createOrganization.address} />
                </Form.Item>
              </Layout.Col>
              {/* <Layout.Col xs="24" sm="2">
                <Button className="btn-create" type="primary" icon="edit" onClick={() => setDialogAddressVisible(true)} style={{ marginTop: '23px' }} ></Button>
              </Layout.Col> */}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Código Postal">
                  <Input className="input-style" placeholder="Ingrese código postal" type="number" value={createOrganization.postal_code} onChange={(value) => onChangeCreate('postal_code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Contacto">
                  <Input className="input-style" placeholder="Ingrese contacto" value={createOrganization.contact} onChange={(value) => onChangeCreate('contact', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail">
                  <Input className="input-style" placeholder="Ingrese email" type="email" value={createOrganization.email} onChange={(value) => onChangeCreate('email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Telefono">
                  <Input className="input-style" placeholder="Ingrese telefono" type="number" value={createOrganization.phone} onChange={(value) => onChangeCreate('phone', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="4">
                <Form.Item label="¿Activo?">
                  <Switch onText="Si" offText="No" value={createOrganization.is_active} onChange={(value) => onChangeCreate('is_active', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => OrganizationCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        title="EDITAR ORGANIZACIÓN"
        size="small"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editOrganization} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="7">
                <Form.Item label="Persona">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione persona"
                    value={editOrganization.person_id}
                    onChange={(value) => {
                      listIdentificationType(value);
                      onChangeEdit("person_id", value);
                    }}>
                    {personList.map((item) => (
                      <Select.Option key={item.id} label={item.type} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="7">
                <Form.Item label="Tipo ID">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione Tipo ID"
                    value={editOrganization.identification_type_id}
                    onChange={(value) => {
                      onChangeEdit("identification_type_id", value);
                    }}>
                    {identificationTypeList.map((item) => (
                      <Select.Option key={item.id} label={`(${item.abbreviation}) ${item.description}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              {(editOrganization.identification_type_id == 6 && editOrganization.person_id == 1) ? (
                <Layout.Col xs="24" sm="7">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" type="number" value={editOrganization.identification} onChange={(value) => {
                      verificationCode(value, null);
                      onChangeEdit("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              ) : (
                <Layout.Col xs="24" sm="7">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" maxLength={10} value={editOrganization.identification} onChange={(value) => {
                      onChangeEdit("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              )}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="D.V">
                  <Input className="input-style" placeholder="D.V" value={dv} readOnly></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nombre de la Organización">
                  <Input className="input-style" placeholder="Ingrese un nombre" name="displayName" value={editOrganization.displayname} onChange={(value) => { onChangeEdit("displayname", value) }} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Razón Social">
                  <Input className="input-style" placeholder="Ingrese una razón social" name="name" value={editOrganization.name} onChange={(value) => { onChangeEdit("name", value) }} />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Departamento">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione un departamento"
                    value={editOrganization.department_id}
                    onChange={(value) => {
                      listCities(value);
                      onChangeEdit("department_id", value);
                    }}>
                    {departmentList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Ciudad">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione una ciudad"
                    value={editOrganization.city_id}
                    onChange={(value) => {
                      onChangeEdit("city_id", value);
                    }}>
                    {cityList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Dirección">
                  <Input className="input-style" onChange={(value) => { onChangeEdit("address", value) }} value={editOrganization.address} />
                </Form.Item>
              </Layout.Col>
              {/* <Layout.Col xs="24" sm="2">
                <Button className="btn-create" type="primary" icon="edit" onClick={() => copyAdresToUpdate(editOrganization.address)} style={{ marginTop: '23px' }} ></Button>
              </Layout.Col> */}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Código Postal">
                  <Input className="input-style" placeholder="Ingrese código postal" type="number" value={editOrganization.postal_code} onChange={(value) => onChangeEdit('postal_code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Contacto">
                  <Input className="input-style" placeholder="Ingrese contacto" value={editOrganization.contact} onChange={(value) => onChangeEdit('contact', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail">
                  <Input className="input-style" placeholder="Ingrese email" type="email" value={editOrganization.email} onChange={(value) => onChangeEdit('email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Telefono">
                  <Input className="input-style" placeholder="Ingrese telefono" type="number" value={editOrganization.phone} onChange={(value) => onChangeEdit('phone', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="4">
                <Form.Item label="Activo?">
                  <Switch onText="Si" offText="No" value={editOrganization.is_active} onChange={(value) => onChangeEdit('is_active', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => organizationUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal formato de dirección */}
      <Dialog
        title={haveErrors ?
          <Alert title={textErrors} type="error" showIcon={true} style={{ background: "#FF6A6A", width: "340px", float: "right" }} /> : ""
        }
        size="small"
        visible={dialogAddressVisible}
        onCancel={() => setDialogAddressVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Layout.Row style={{ marginBottom: "41px" }}>
            <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>INGRESE LA DIRECCIÓN</p>
          </Layout.Row>
          <Form className="es-CO" model={createAddress} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Vía">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.road}
                    onChange={(value) => {
                      onChangeAddres("road", value);
                    }}>
                    {roadList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number1} onChange={(value) => { onChangeAddres("number1", value); }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="1" sm="1">
                <label style={{ marginTop: "300px" }} >#</label>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number2} onChange={(value) => { onChangeAddres("number2", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="1">
                <Form.Item label="-">
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number3} onChange={(value) => { onChangeAddres("number3", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Tipo inmueble">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.propertyType}
                    onChange={(value) => {
                      onChangeAddres("propertyType", value);
                    }}>
                    {propertyType.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2" >
                <Form.Item label="Número">
                  <Input className="input-style" disabled={createAddress.propertyType != "Bloque"} value={createAddress.number4} onChange={(value) => { onChangeAddres("number4", value) }} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Interior">
                  <Input className="input-style" value={createAddress.apto} onChange={(value) => { onChangeAddres("apto", value) }}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => saveAddress()}>Crear</Button>
          <Button className="btn-cancel" onClick={() => cleanFormAdres()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal detalle */}
      <Dialog
        title="DETALLES DE LA ORGANIZACIÓN"
        size="large"
        visible={dialogDetailVisible}
        onCancel={() => setDialogDetailVisible(false)}
        lockScroll={true}>
        <Dialog.Body>
          <Table
            style={{ width: '100%' }}
            columns={columnsDetailOrganization}
            data={detailOrganization}
            stripe={true}
            highlightCurrentRow={true}
            height={400}
            emptyText="No se encontraron detalles"
          />
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-cancel" onClick={() => setDialogDetailVisible(false)}>Cerrar</Button>
        </Dialog.Footer>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Organization);
