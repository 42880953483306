import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR ORGANIZACIONES**********
-------------------------------------*/
export async function organizationListRequest(value) {
    try {
        let { data } = await axios.get(`/organizations`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de organizaciones",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********CREAR ORGANIZACION************
-------------------------------------*/
export async function organizationCreateRequest(value) {
    try {
        let { data } = await axios.post(`/organizations`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }

    } catch (error) {
        console.log(error);
        return {
            data: null,
            error: true,
            message: "Hubo un problema al crear la organización"
        }
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationOrganizationListRequest(value) {
    try {
        let { data } = await axios.get("/organizations/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de organizaciones",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR ORGANIZACIÓN************  
-------------------------------------*/
export async function organizationUpdateRequest(value) {
    try {
        let { data } = await axios.put('/organizations', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar la organización",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR ORGANIZACIÓN***************
-------------------------------------*/
export async function organizationDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/organizations?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar la organización",
            type: 'error',
            customClass: "message-position"
        });
    }

}
