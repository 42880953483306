import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Layout, Card, Breadcrumb, Select, DateRangePicker, Button, Alert, Loading, Tag, Table, Form } from "element-react";
import date from "date-and-time";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import "./BillCreate.css";
import { sectorListRequest } from "../../Sector/SectorRequest";
import { billCreateRequest } from "../../Bill/BillCreate/BillCreateRequest";
import { billOneRequest } from "../../Bill/BillList/BillListRequest";


function BillCreate(props) {

	// Varibles de Estado de la Interfaz
	const [isLoading, setIsLoading] = useState(false);
	const [haveErrors, setHaveErrors] = useState(false);
	const [textErrors, setTextErrors] = useState("");
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};
	const Back = () => {
		window.location = "/factura/lista";
	};

	// Variables de Estado de Alimentación
	const [sectors, setSectors] = useState([]);
	const [rangeDate, setRangeDate] = useState(null);
	const [bill, setBill] = useState(null);

	const [data, setData] = useState({
		bill: {
			organization_id: null,
			sector_id: null,
			date_start: null,
			date_end: null,
		},
	});

	var columns = [
		{
			label: "EUI",
			prop: "device.eui",
		},
		{
			label: "Dispositivo",
			prop: "device.name",
		},
		{
			label: "Fecha inicio",
			prop: "date_start",
			render: (data, column) => {
				return data.date_start ? setDate(data.date_start) : "N/A";
			},
		},
		{
			label: "Fecha fin",
			prop: "date_end",
			render: (data, column) => {
				return data.date_end ? setDate(data.date_end) : "N/A";
			},
		},
		{
			label: "Consumo total",
			prop: "volume",
			render: (data, column) => {
				var volume = Math.round(data.volume * Math.pow(10, 3)) / Math.pow(10, 3);
				if (data.volume > 0) {
					return (
						<Tag style={{ width: "50%", textAlign: "center", backgroundColor: "#25B03C" }}>
							<b>{volume} m³</b>
						</Tag>
					);
				} else if (data.volume < 0) {
					return (
						<Tag style={{ width: "50%", textAlign: "center", backgroundColor: "#C70039" }}>
							<b>{volume} m³</b>
						</Tag>
					);
				} else if (data.volume === 0) {
					return (
						<Tag style={{ width: "50%", textAlign: "center", backgroundColor: "#D7A53A" }}>
							<b>{volume} m³</b>
						</Tag>
					);
				}
			},
		},
	];

	useEffect(async () => {
		var result = await sectorListRequest();
		if (result) {
			setSectors(result);
		}
	}, []);

	const handleInputChange = (key, value) => {
		setData({
			bill: {
				...data.bill,
				[key]: value,
			},
		});
	};

	const handleInputChangeDate = (value) => {
		setData({
			bill: {
				...data.bill,
				["date_start"]: value ? value[0] : null,
				["date_end"]: value ? value[1] : null,
			},
		});
	};

	function validation() {
		if (data.bill.sector_id === null) {
			setTextErrors("Seleccione un sector.");
			setHaveErrors(true);
			return false;
		} else if (rangeDate === null) {
			setTextErrors("Seleccione un rango de fechas");
			setHaveErrors(true);
			return false;
		} else {
			setTextErrors("");
			setHaveErrors(false);
			return true;
		}
	}

	async function facturar() {
		setIsLoading(true);
		if (validation()) {
			var result = await billCreateRequest(data);

			if (!result.error) {
				await billOneRequest(result.data.bill.id)
					.then((bill_res) => {
						setBill(bill_res.data);
					})
					.catch((error) => {
						setBill(null);
					});
			} else {
				setTextErrors("Ocurrio un error inesperado");
				setHaveErrors(true);
			}
		}
		setIsLoading(false);
	}

	function setDate(dateNew) {
		var dateObj = new Date(dateNew);
		var dateString = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");

		return (
			<p style={{ margin: "0px" }}>
				<i className="far fa-calendar-alt fa-lg"></i> {dateString}
			</p>
		);
	}

	return (
		<div className="container">
			<Loading text="Cargando..." loading={isLoading}>
				<NavBar showDataProfile={true} showToolBar={showToolBar} />
				<ToolBar stateToolBar={stateToolBar} active="3-1" />

				<Layout.Row>
					<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
						<br />
					</Layout.Col>

					<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
						<Layout.Row style={{ padding: "20px" }}>
							<Breadcrumb separator="/">
								<Breadcrumb.Item>Reportes</Breadcrumb.Item>
								<Breadcrumb.Item>Reporte de Facturas</Breadcrumb.Item>
							</Breadcrumb>

							{haveErrors ? (
								<Alert
									title={textErrors}
									type="error"
									showIcon={true}
									style={{ background: "#FF6A6A", width: "340px", float: "right" }}
								/>
							) : (
								""
							)}
						</Layout.Row>
						{/*<Layout.Row style={{ padding: "20px" }}>
							<Layout.Row style={{ marginBottom: "41px" }}>
								<p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>GENERAR FACTURACIÓN</p>
							</Layout.Row>
							<Layout.Row gutter="20">

								<Layout.Col xs="24" sm="12">
									<small className="label-filter">Sector: </small>
									<Select
										className="input-style"
										style={{ width: "100%", marginTop: "10px" }}
										placeholder="Seleccione un sector"
										onChange={(value) => {
											handleInputChange("sector_id", value);
										}}
									>
										{sectors.map((item) => (
											<Select.Option key={item.id} label={item.name} value={item.id} />
										))}
									</Select>
								</Layout.Col>

								<Layout.Col xs="24" sm="12">
									<small>Seleccione un rango de fecha: </small>
									<DateRangePicker
										className="input-style"
										isShowTime={true}
										value={rangeDate}
										placeholder="Rango de facturación"
										onChange={(date) => {
											handleInputChangeDate(date);
											setRangeDate(date);
										}}
									/>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row gutter="20" style={{ marginTop: "20px" }}>
								<Layout.Col xs="24" sm="12">
									<Button className="btn-save" disabled={!props.roles.profiles.profile_create} type="primary" onClick={facturar}>
										Facturar <i className="fas fa-save" style={{ color: "white" }}></i>
									</Button>
									<Button className="btn-cancel" onClick={Back}>
										Cancelar <i className="fas fa-window-close"></i>
									</Button>
								</Layout.Col>
							</Layout.Row>
						</Layout.Row> */}

						<Form className="es-CO" labelWidth="120" labelPosition="top">
							<Layout.Row>
								<Layout.Col span="22" offset="1">
									<Layout.Row gutter="10">
										<Layout.Col span="9" xs="24">
											<Form.Item className="label-filter" label="Sector">
												<Select
													className="input-style"
													style={{ width: "100%", marginTop: "10px" }}
													placeholder="Seleccione un sector"
													onChange={(value) => {
														handleInputChange("sector_id", value);
													}}
												>
													{sectors.map((item) => (
														<Select.Option key={item.id} label={item.name} value={item.id} />
													))}
												</Select>											</Form.Item>
										</Layout.Col>
										<Layout.Col span="9" xs="24">
											<Form.Item className="label-filter" label="Seleccione un rango de fecha:">
												<DateRangePicker
													className="input-style"
													isShowTime={true}
													value={rangeDate}
													placeholder="Rango de facturación"
													onChange={(date) => {
														handleInputChangeDate(date);
														setRangeDate(date);
													}}
												/>											</Form.Item>
										</Layout.Col>
										<Layout.Col span="3" xs="24">
											<Button className="btn-create" style={{ marginTop: "35px" }} disabled={!props.roles.profiles.profile_create} type="primary" onClick={facturar}>
												Facturar <i className="fas fa-save" style={{ color: "white" }}></i>
											</Button>
										</Layout.Col>
										<Layout.Col span="3" xs="24">
											<Button className="btn-cancel" style={{ marginTop: "35px" }} onClick={Back}>
												Cancelar <i className="fas fa-window-close"></i>
											</Button>
										</Layout.Col>
									</Layout.Row>
								</Layout.Col>
							</Layout.Row>
						</Form>

						{bill == null ? (
							""
						) : (
							<Layout.Row style={{ padding: "20px" }}>
								<Card
									className="box-card"
									style={{ overflow: "visible", borderRadius: "20px", height: "600px" }}
									header={
										<div>
											<p style={{ fontSize: "24px", color: "#00d1ff", margin: "0" }}>
												Factura # <Tag>{bill.id}</Tag>
											</p>
											<Layout.Row>

												<Layout.Col xs="24" sm="8">
													<p style={{ fontSize: "15px", color: "#00d1ff", float: "left" }}>
														Sector:
													</p>
													<p
														style={{
															fontSize: "15px",
															float: "left",
															marginLeft: "10px",
															color: "#000000",
														}}
													>
														{bill ? bill.sector.name : "N/A"}
													</p>
												</Layout.Col>
												<Layout.Col xs="24" sm="7">
													<p style={{ fontSize: "15px", color: "#00d1ff", float: "left" }}>
														Fecha inicio:
													</p>
													<p
														style={{
															fontSize: "15px",
															float: "left",
															marginLeft: "10px",
															color: "#000000",
														}}
													>
														{bill ? setDate(bill.date_start) : "N/A"}
													</p>
												</Layout.Col>
												<Layout.Col xs="24" sm="9">
													<p style={{ fontSize: "15px", color: "#00d1ff", float: "left" }}>
														Fecha fin:
													</p>
													<p
														style={{
															fontSize: "15px",
															float: "left",
															marginLeft: "10px",
															color: "#000000",
														}}
													>
														{bill ? setDate(bill.date_end) : "N/A"}
													</p>
												</Layout.Col>
											</Layout.Row>
										</div>
									}
								>
									<Layout.Row style={{ padding: "20px" }}>
										<Table
											style={{ width: "100%" }}
											columns={columns}
											data={bill.billDevices}
											stripe={true}
											height={400}
											emptyText="No se encontraron Registros"
										/>
									</Layout.Row>
								</Card>
							</Layout.Row>
						)}
						<Footer />
					</Layout.Col>
				</Layout.Row>
			</Loading>
		</div>
	);
}

const mapStateToProps = (state) => ({
	roles: state.auth.roles,
});

export default connect(mapStateToProps)(BillCreate);
