import { Message } from 'element-react';
import axios from '../../utilities/axiosInstance';
import handleResponse from '../../utilities/responseReceiver';
import { CHANGE_PASSWORD, LOG_IN, LOG_OUT, SET_ORGANIZATION } from './types';


/**
 * Inicia sesión en la plataforma
 * @param {string} values.email E-mail de ingreso
 * @param {string} values.username Nombre de usuario de ingreso
 * @param {string} values.password Contraseña de ingreso para el usuario
 */
export const logIn = values => async dispatch => {
    try {
        let response = await axios.post('/auth/login', values);

        if (handleResponse(response.data)) {
            var data = response.data.data;

            // Guardar token e información de usuario en localStorage
            localStorage.setItem("jwt", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));

            // Procesar roles y guardar un arreglo por atributo
            var codes = [];
            var names = [];
            var levels = [];
            var profiles = {};

            data.user.roles.forEach((role) => {
                codes.push(role.code);
                names.push(role.name);
                levels.push(role.level);
                profiles = {
                    profile_create: role.profile.profile_create,
                    profile_delete: role.profile.profile_delete,
                    profile_edit: role.profile.profile_edit,
                    profile_see: role.profile.profile_see,
                }
            });

            dispatch({
                type: LOG_IN,
                payload: {
                    userToken: data.token,
                    userId: data.user.id,
                    userFullName: `${data.user.name} ${data.user.last_name}`,
                    needsPasswordChange: data.user.password_reset,
                    roles: {
                        codes,
                        names,
                        levels,
                        profiles
                    }
                }
            });
            return data;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al validar las credenciales",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Desecha los datos de sesión para finalizarla
 */
export const logOut = () => async dispatch => {
    try {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        localStorage.removeItem('organizationId');
        dispatch({
            type: LOG_OUT
        });
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al cerrar la sesión",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Toma los datos de la organización activa
 */
export const setOrganization = (values) => async dispatch => {
    try {
        localStorage.setItem("organizationId", values);
        dispatch({
            type: SET_ORGANIZATION,
            payload: {
                organizationId: values
            }
        });
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener la organización",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/**
 * Envía la petición para realizar el cambio de contraseña y cambia el estado de 'needsPasswordChange'
 * @param {string} password Nueva contraseña
 */
export const changePassword = password => async dispatch => {
    try {
        let { data } = await axios.post('/auth/changepassword', { password });

        if (handleResponse(data, true)) {
            dispatch({
                type: CHANGE_PASSWORD
            });
            return data.data;
        } else {
            return false
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al cambiar la contraseña",
            type: 'error',
            customClass :"message-position"
        });
    }
}