import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Select, Input, Table, Button, Dialog } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Roles.css';
import { paginationRoleListRequest, roleCreateRequest, roleUpdateRequest, getProfiles } from './RolesRequest';

function Role(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [profileList, setProfileList] = useState([]);

  // Variables de Estado de Formulario
  const [levelList, setLevelList] = useState([1, 2, 3, 4, 5, 6, 7, 8]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createRole, setCreateRole] = useState({
    code: null,
    name: "",
    alias: "",
    level: null,
    profile_id: null,
    description: ""
  });

  const [editRole, setEditRole] = useState({
    code: null,
    name: "",
    alias: "",
    level: null,
    profile_id: null,
    description: ""
  });

  var columns = [
    {
      label: "Código",
      prop: "code",
    },
    {
      label: "Nombre",
      prop: "name",
    },
    {
      label: "Alias",
      prop: "alias",
    },
    {
      label: "Nivel",
      prop: "level",
    },
    {
      label: "Perfil",
      prop: "profile.name",
      render: (data, column) => {
        return ((data.profile.name == null) ? "No asignado" : data.profile.name)
      }
    },
    {
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((!data['description'] || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
        </Button.Group>);

      }
    }
  ];

  useEffect(async () => {
    var result = await paginationRoleListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var profiles = await getProfiles();
    if (profiles) {
      setProfileList(profiles);
    }
  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createRole };
    newValues[key] = value;
    setCreateRole(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editRole };
    newValues[key] = value;
    setEditRole(newValues);
  }

  async function copyDataUpdate(item) {
    setEditRole(item);
    setDialogEditVisible(true);
  }

  async function roleCreateApi() {
    let values = createRole;
    var result = await roleCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationRoleListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function roleUpdateApi(value) {
    var values = editRole;
    var result = await roleUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationRoleListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateRole({
      code: null,
      name: "",
      alias: "",
      level: null,
      profile_id: null,
      description: ""
    });
    setEditRole({
      code: null,
      name: "",
      alias: "",
      level: null,
      profile_id: null,
      description: ""
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    let value = {
      page: pagination.page,
      pageSize: pagination.pageSize,
    }

    var result = await paginationRoleListRequest(value);
    if (result) {
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="6-3" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Roles</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Rol</Button>
            </Layout.Col>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={500}
              emptyText="No se encontraron Roles" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal crear */}
      <Dialog
        size="small"
        title="CREAR ROL"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createRole} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Código">
                  <Input className="input-style" type="Number" placeholder="Ingrese un código" value={createRole.code} onChange={(value) => onChangeCreate('code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nombre">
                  <Input className="input-style" placeholder="Ingrese un nombre" value={createRole.name} onChange={(value) => onChangeCreate('name', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Alias">
                  <Input className="input-style" placeholder="Ingrese un alias" value={createRole.alias} onChange={(value) => onChangeCreate('alias', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nivel">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione un nivel"
                    onChange={(value) => {
                      onChangeCreate("level", value);
                    }}>
                    {levelList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Perfil de Privilegios">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                placeholder="Seleccione un perfil"
                onChange={(value) => {
                  onChangeCreate("profile_id", value);
                }}>
                {profileList.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createRole.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => roleCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        size="small"
        title="EDITAR ROL"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editRole} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Código">
                  <Input className="input-style" type="Number" placeholder="Ingrese un código" value={editRole.code} onChange={(value) => onChangeEdit('code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nombre">
                  <Input className="input-style" placeholder="Ingrese un nombre" value={editRole.name} onChange={(value) => onChangeEdit('name', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Alias">
                  <Input className="input-style" placeholder="Ingrese un alias" value={editRole.alias} onChange={(value) => onChangeEdit('alias', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Nivel">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione un nivel"
                    value={editRole.level}
                    onChange={(value) => {
                      onChangeEdit("level", value);
                    }}>
                    {levelList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Perfil de Privilegios">
              <Select
                className="input-style"
                style={{ width: "100%" }}
                placeholder="Seleccione un perfil"
                value={editRole.profile_id}
                onChange={(value) => {
                  onChangeEdit("profile_id", value);
                }}>
                {profileList.map((item) => (
                  <Select.Option key={item.id} label={item.name} value={item.id} />
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={editRole.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => roleUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Role);
