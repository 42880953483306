import React, { useState, useEffect } from "react";
import "element-theme-default";
import {
  Layout,
  Card,
  Breadcrumb,
  Table,
  Tag,
  Pagination,
  Select,
  Button,
} from "element-react";
import date from "date-and-time";
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import Footer from "../../../components/footer/Footer";
import "./DeviceHistory.css";
import {
  paginationDeviceHistoryRequest,
  getContractByDevice,
} from "./DeviceHistoryRequest";
import moment from "moment-timezone";

function DeviceHistory(props) {
  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
    id: null,
  });
  const [query, setQuery] = useState({
    contract_id: null,
  });

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [contract, setContractList] = useState([]);

  var columns = [
    {
      label: "Fecha",
      prop: "date",
      render: (data, column) => {
        let tz = moment.tz.guess();

        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.date), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        //   return (
        //     <p>
        //       <i className="far fa-calendar-alt fa-lg"></i> {dateFormat}
        //     </p>
        //   );
        // } else {
        //   let date = moment.tz(data.date, tz);
        //   return (
        //     <p>
        //       <i className="far fa-calendar-alt fa-lg"></i>{" "}
        //       {date.format("YYYY-MM-DD HH:mm:ss")}
        //     </p>
        //   );
        // }

        let dateObj = new Date(data.date);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return (
          <p>
            <i className="far fa-calendar-alt fa-lg"></i> {dateFormat}
          </p>
        );
      },
    },
    {
      label: "Estado",
      prop: "state",
      render: (data, column) => {
        if (!data.status) {
          return "Sin conexión";
        } else if (!data.alarm_status_id) {
          if (data.status == "00" || data.status == "0") {
            return "Sin error";
          } else if (data.status == "04") {
            return "Batería baja";
          } else if (data.status == "08") {
            return "Error de hardware";
          } else if (data.status == "10") {
            return "Tubería vacía";
          } else if (data.status == "20") {
            return "Fuga";
          } else if (data.status == "A0") {
            return "Alto flujo";
          } else if (data.status == "60") {
            return "Flujo inverso";
          } else if (data.status == "80") {
            return "Baja temperatura";
          } else if (data.status == "70") {
            return "Flujo inverso/Tubería vacía";
          }
        } else {
          return data.alarm_status.name;
        }
      },
    },
    {
      label: "Contrato",
      prop: "contract.contract",
      render: (data, column) => {
        if (data.contract.contract) {
          return data.contract.contract;
        } else {
          return "Sin asignar";
        }
      },
    },
    {
      label: "Última lectura",
      prop: "volume",
      render: (data, column) => {
        return (
          <Tag style={{ width: "100%", textAlign: "center" }}>
            <i className="fas fa-tachometer-alt"></i>{" "}
            {Math.round(data.volume * Math.pow(10, 3)) / Math.pow(10, 3)} m³
          </Tag>
        );
      },
    },
    {
      label: "Válvula",
      prop: "valve_status",
      render: (data, column) => {
        if (!data.valve_status) {
          return "Sin válvula";
        } else if (data.valve_status) {
          if (data.valve_status == "B0") {
            return "Abierta";
          } else if (data.valve_status == "B1") {
            return "Cerrada";
          } else if (data.valve_status == "B2") {
            return "ERROR EN LA VÁLVULA" + " ";
          }
        }
      },
    },
    {
      label: "Comando",
      prop: "status_comand_type",
      render: (data, column) => {
        if (data.status_comand_type) {
          if (data.status_comand_type == "PendienteEntrega") {
            return "Pendiente Entrega";
          } else if (data.status_comand_type == "Entregado") {
            return "Pendiente Actualización";
          } else if (data.status_comand_type == "Ejecutado") {
            return "Ejecutado";
          }
        }
      },
    },
    {
      label: "Gateway",
      prop: "data.gateway.name",
      render: (data, column) => {
        let gateway =
          data.gateway && data.gateway.id ? data.gateway.name : "Sin datos";
        return gateway;
      },
    },
    {
      label: "rssi",
      prop: "rssi",
      render: (data, column) => {
        return data["rssi"] == null || data["rssi"] == undefined
          ? "Sin datos"
          : data["rssi"];
      },
    },
    {
      label: "loRaSNR",
      prop: "lora_snr",
      render: (data, column) => {
        return data["lora_snr"] == null || data["lora_snr"] == undefined
          ? "Sin datos"
          : data["lora_snr"];
      },
    },
  ];

  useEffect(async () => {
    console.log(props.location.row);
    if (props.location.row == null) {
      window.location.href = "/dispositivo";
    } else {
      pagination.id = props.location.row.eui;
      var result = await paginationDeviceHistoryRequest(pagination);
      //console.log(result);
      if (result) {
        pagination.total = result.data.count;
        setData(result.data.rows);
      }

      var result = await getContractByDevice({
        device_id: props.location.row.id,
      });
      if (!result.error) {
        setContractList(result.data);
      }
    }
  }, []);

  async function onCurrentChange(page, contract_id) {
    pagination.page = page;
    pagination.id = props.location.row.eui;

    let value = {
      id: pagination.id,
      page: pagination.page,
      pageSize: pagination.pageSize,
      contract_id: contract_id,
    };
    var result = await paginationDeviceHistoryRequest(value);
    if (!result.error) {
      pagination.total = result.data.count;
      setData(result.data.rows);
    }
  }

  return (
    <div className="container">
      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="2" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
          <br />
        </Layout.Col>

        <Layout.Col
          xs="24"
          sm={stateToolBar ? 19 : 24}
          className="toolbar-margins"
        >
          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Dispositivo</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="/dispositivo">Lista Dispositivos</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Historial</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>
          <Layout.Row style={{ padding: "20px" }}>
            <Card
              className="box-card"
              header={
                <Layout.Row>
                  <p
                    style={{ fontSize: "24px", color: "#20a0ff", margin: "0" }}
                  >
                    Historial de medición
                  </p>
                  <Layout.Row>
                    <Layout.Col xs="24" sm="6">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#20a0ff",
                          float: "left",
                        }}
                      >
                        Dispositivo:
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          float: "left",
                          marginLeft: "10px",
                          color: "#5e6d82",
                        }}
                      >
                        {props.location.row ? props.location.row.name : "N/A"}
                      </p>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="6">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#20a0ff",
                          float: "left",
                        }}
                      >
                        Número de serial:
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          float: "left",
                          marginLeft: "10px",
                          color: "#5e6d82",
                        }}
                      >
                        {props.location.row ? props.location.row.serial : "N/A"}
                      </p>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="6">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#20a0ff",
                          float: "left",
                        }}
                      >
                        {props.location.row.lora_service == 'Sigfox' ? 'ID Sigfox' : 'EUI'}:
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          float: "left",
                          marginLeft: "10px",
                          color: "#5e6d82",
                        }}
                      >
                        {props.location.row ? props.location.row.lora_service == 'Sigfox' ? props.location.row.device_id : props.location.row.eui : "N/A"}
                      </p>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="6">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#20a0ff",
                          float: "left",
                        }}
                      >
                        Sector:
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          float: "left",
                          marginLeft: "10px",
                          color: "#5e6d82",
                        }}
                      >
                        {props.location.row
                          ? props.location.row.sector.name
                          : "N/A"}
                      </p>
                    </Layout.Col>
                  </Layout.Row>
                  <Layout.Row gutter="10">
                    <Layout.Col xs="24" sm="6">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#20a0ff",
                          float: "left",
                        }}
                      >
                        Contratos:
                      </p>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Seleccione un contrato"
                        onChange={(item) => onCurrentChange(0, item)}
                      >
                        <Select.Option
                          key={0}
                          label={`Listar Todos`}
                          value={null}
                        />
                        {contract.map((item) => (
                          <Select.Option
                            key={item.contract_id}
                            label={`${item.contract.contract}`}
                            value={item.contract_id}
                          />
                        ))}
                      </Select>
                    </Layout.Col>
                    {/* <Layout.Col xs="24" sm="6">
                                            <Button style={{ marginTop: '57px'}} className="btn-create" type="primary" onClick={() => onCurrentChange(0, null)}> <i className="fa fa-refresh"></i> Actualizar lista</Button>
                                            </Layout.Col>*/}
                  </Layout.Row>
                </Layout.Row>
              }
            >
              <Layout.Row style={{ padding: "20px" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={columns}
                  data={data}
                  stripe={true}
                  highlightCurrentRow={true}
                  height={400}
                  emptyText="No se encontraron lecturas"
                />
              </Layout.Row>
            </Card>
            <Layout.Row style={{ padding: "20px" }}></Layout.Row>
            <Layout.Row>
              <Pagination
                style={{ float: "right" }}
                layout="total, prev, pager, next"
                total={pagination.total}
                pageSize={pagination.pageSize}
                onCurrentChange={(page) => onCurrentChange(page - 1, 0)}
              />
            </Layout.Row>
            <Footer />
          </Layout.Row>
        </Layout.Col>
      </Layout.Row>
    </div>
  );
}

export default DeviceHistory;
