import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Form, Button, Layout, Breadcrumb, Table, Select, Input, Dialog, Switch, Pagination } from "element-react";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import Footer from "../../components/footer/Footer";
import { getUsers, updateUser, getRoles, getOrganizations, registerUser } from "./UserRequest";
import minBy from "lodash/minBy";


function User(props) {
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};

	// Variables de Estado de Formulario
	const [queryData, setQueryData] = useState({
		"name": null,
		"last_name": null,
		"email": null,
		"roles": [],
		"username": null,
		"page": null,
		"pageSize": null
	});

	// Variables del usuario para la creación
	const [userValues, setUserValues] = useState({
		name: null,
		last_name: null,
		username: null,
		email: null,
		password: null,
		password_reset: false,
		phone: null,
		role_id: null,
		organizations: []
	});

	// Variables del usuario para la edición
	const [editUser, setEditUser] = useState({
		name: null,
		last_name: null,
		username: null,
		email: null,
		password: null,
		password_reset: false,
		is_active: false,
		phone: null,
		role_id: null,
		organizations: [],
		arrayOrganizations: [],
		previous_role: null,
	});

	// Variables de Estado de Alimentación
	const [roleList, setRoleList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);

	// Varibles de Estado de la Interfaz
	const [loadingSearch, setLoading] = useState(false);
	const [sendingData, setSendingData] = useState(false);
	const [dialogEditVisible, setDialogEditVisible] = useState(false);
	const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
	const [organizations, setOrganizatios] = useState([]);
	const [pagination, setPagination] = useState({
		page: 0,
		pageSize: 10,
		total: 0
	});


	const columns = [
		{
			label: "Nombres",
			prop: "name",
		},
		{
			label: "Apellidos",
			prop: "last_name",
		},
		{
			label: "Usuario",
			prop: "username",
		},
		{
			label: "email",
			prop: "email",
		},
		{
			label: "rol",
			render: (data, column) => {
				var role = minBy(data.roles, 'level');
				return role.alias
			},
		},
		{
			label: "Acciones",
			prop: "actions",
			render: (item) => {

				return (<Button.Group>
					<Button disabled={!props.roles.profiles.profile_edit} type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
				</Button.Group>);

			}
		}
	];

	useEffect(async () => {
		//setRoleList(await getRoles());

		var roles = await getRoles();
		if (roles) {
			setRoleList(roles);
		}

		var organizations = await getOrganizations();
		if (organizations) {
			setOrganizationList(organizations);
		}
	}, []);

	function onChangeCreate(key, value) {
		var newValues = { ...userValues };
		newValues[key] = value;
		setUserValues(newValues);
	}

	/**
	* Submit del formulario, dispara la petición de creación de usuario
	* @param {*} values 
	*/
	async function submitCreateUser() {
		let values = userValues;
		setSendingData(true);
		let result = await registerUser(values);
		if (result) {
			setUserValues({
				name: null,
				last_name: null,
				username: null,
				email: null,
				password: null,
				password_reset: false,
				phone: null,
				role_id: null,
				organizations: []
			});
		}
		setSendingData(false);
	}

	function handleQueryChange(key, value) {
		let newQuery = { ...queryData };
		newQuery[key] = value;
		setQueryData(newQuery);
	}

	async function searchUsers() {
		try {
			setLoading(true);
			var values = { ...queryData };

			if (queryData.roles.length > 0) {
				values.roles = queryData.roles;
			} else {
				delete values["roles"];
			}
			values.page = pagination.page;
			values.pageSize = pagination.pageSize;

			var users = await getUsers(values);
			if (users) {
				pagination.total = users.count;
				setUserList(users.rows);
			}


			setLoading(false);
		} catch (error) {
			console.log("Hubo un error al obtener los usuarios");
			console.error(error);
			setLoading(false);
		}
	}

	function copyDataUpdate(item) {
		let newItem = Object.assign({}, item);
		newItem.arrayOrganizations = [];
		newItem.role_id = item.roles[0].id;
		newItem.previous_role = newItem.role_id;

		item.organizations.forEach((organization) => {
			newItem.arrayOrganizations.push(organization.id);
		});
		setEditUser(newItem);
		setDialogEditVisible(true);
	}

	function onChange(key, value) {
		var newValues = { ...editUser };
		newValues[key] = value;
		setEditUser(newValues);
	}

	/**
   * Submit del formulario, dispara la petición de edición de usuario
   * @param {*} values 
   */
	async function submitEditUser() {
		let values = editUser;
		setSendingData(true);

		let result = await updateUser(values);
		if (result) {
			searchUsers();
			cleanForm();
		}
		setSendingData(false);
	}

	function cleanForm() {
		setDialogEditVisible(false);
		setDialogCreateVisible(false);
		setEditUser({
			name: null,
			last_name: null,
			username: null,
			email: null,
			password: null,
			password_reset: false,
			phone: null,
			role_id: null,
			organizations: []
		});
		setEditUser({
			name: null,
			last_name: null,
			username: null,
			email: null,
			password: null,
			password_reset: false,
			is_active: false,
			phone: null,
			role_id: null,
			organizations: []
		});
	}

	async function onCurrentChange(page) {
		pagination.page = page;
		searchUsers();

	}

	return (
		<div className="container">
			<NavBar showDataProfile={true} showToolBar={showToolBar} />
			<ToolBar stateToolBar={stateToolBar} active="6-2" />

			<Layout.Row>
				<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
					<br />
				</Layout.Col>

				<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
					<Layout.Row style={{ padding: "20px" }}>
						<Breadcrumb separator="/">
							<Breadcrumb.Item>Usuarios</Breadcrumb.Item>
							<Breadcrumb.Item>Lista Usuarios</Breadcrumb.Item>
						</Breadcrumb>
					</Layout.Row>

					<Layout.Row style={{ padding: "10px" }}>
						<Layout.Col xs="24" sm="6">
							<Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Usuario del Sistema</Button>
						</Layout.Col>
					</Layout.Row>

					{/* Formulario */}
					<Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top" style={{ padding: "10px" }}>
						<Layout.Row>
							<Layout.Col span="22" offset="1">
								<Layout.Row gutter="10">
									<Layout.Col span="4" xs="24">
										<Form.Item className="label-filter" label="Email">
											<Input value={queryData.email} onChange={(value) => handleQueryChange('email', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="4" xs="24">
										<Form.Item className="label-filter" label="Nombres">
											<Input value={queryData.name} onChange={(value) => handleQueryChange('name', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="4" xs="24">
										<Form.Item className="label-filter" label="Apellidos">
											<Input value={queryData.last_name} onChange={(value) => handleQueryChange('last_name', value)} type="text" />
										</Form.Item>
									</Layout.Col>

									<Layout.Col span="4" xs="24">
										<Form.Item className="label-filter" label="Usuario">
											<Input value={queryData.username} onChange={(value) => handleQueryChange('username', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="4" xs="24">
										<Form.Item className="label-filter" label="Roles">
											<Select
												value={queryData.roles}
												multiple={true}
												onChange={(value) => handleQueryChange('roles', value)}>
												{(roleList) ? roleList.map((role) =>
													<Select.Option key={role.id} value={role.id} label={`${role.level} | ${role.alias}`} />
												) : null}
											</Select>
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="2" xs="24">
										<Button
											disabled={!props.roles.profiles.profile_see}
											className="btn-create"
											type="primary"
											icon="search"
											loading={loadingSearch}
											onClick={() => searchUsers()}
											style={{ marginTop: '23px' }}

										>
											Buscar
										</Button>
									</Layout.Col>
								</Layout.Row>
							</Layout.Col>
						</Layout.Row>
					</Form>

					<Layout.Row style={{ padding: "10px" }}>
						<Table
							style={{ width: "100%" }}
							columns={columns}
							data={userList}
							stripe={true}
							height={400}
							highlightCurrentRow={true}
							emptyText="No se encontraron usuarios del sistema"
						/>
					</Layout.Row>
					<Layout.Row>
						<Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
					</Layout.Row>
					<Footer />
				</Layout.Col>
			</Layout.Row>

			{/* Modal crear */}
			<Dialog
				size="small"
				title="CREAR USUARIO DEL SISTEMA"
				visible={dialogCreateVisible}
				onCancel={() => setDialogCreateVisible(false)}
				lockScroll={true}
				showClose={false}>
				<Dialog.Body>
					<Form className="es-CO" model={userValues} labelWidth="120" labelPosition="top">
						<Layout.Row gutter="20">
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Nombres">
									<Input className="input-style" value={userValues.name} onChange={(value) => onChangeCreate('name', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Apellidos">
									<Input className="input-style" value={userValues.last_name} onChange={(value) => onChangeCreate('last_name', value)}></Input>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20">
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Nombre de Usuario">
									<Input className="input-style" value={userValues.username} onChange={(value) => onChangeCreate('username', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Correo electrónico">
									<Input className="input-style" value={userValues.email} onChange={(value) => onChangeCreate('email', value)}></Input>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20">
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Número telefónico">
									<Input className="input-style" value={userValues.phone} onChange={(value) => onChangeCreate('phone', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Contraseña">
									<Input className="input-style" value={userValues.password} type="password" onChange={(value) => onChangeCreate('password', value)}></Input>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20">
							<Layout.Col xs="24" sm="12" >
								<Form.Item label="Rol">
									<Select className="input-style" style={{ width: "100%" }} value={userValues.role_id} onChange={(value) => onChangeCreate('role_id', value)}>
										{
											roleList.map((role) => {
												return <Select.Option key={role.id} label={`${role.level} | ${role.alias}`} value={role.id}></Select.Option>
											})
										}
									</Select>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Organizaciones">
									<Select className="input-style" style={{ width: "100%" }}
										value={userValues.organizations}
										multiple={true}
										onChange={(value) => onChangeCreate('organizations', value)}>
										{
											organizationList.map((organization) => {
												return <Select.Option key={organization.id} label={organization.name} value={organization.id}></Select.Option>
											})
										}
									</Select>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>
						<Form.Item label="Forzar cambio de Contraseña">
							<Switch value={userValues.password_reset} onText="Si" offText="No" onChange={(value) => onChangeCreate('password_reset', value)}></Switch>
						</Form.Item>
					</Form>
				</Dialog.Body>
				<Dialog.Footer className="dialog-footer">
					<Button className="btn-save" type="primary" loading={sendingData} onClick={() => submitCreateUser()}>Guardar</Button>
					<Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
				</Dialog.Footer>
			</Dialog>


			{/* Modal editar */}
			<Dialog
				size="small"
				title="EDITAR USUARIO DEL SISTEMA"
				visible={dialogEditVisible}
				onCancel={() => setDialogEditVisible(false)}
				lockScroll={true}
				showClose={false}>
				<Dialog.Body>
					<Form className="es-CO" model={editUser} labelWidth="120" labelPosition="top">
						<Layout.Row gutter="20">
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Nombres">
									<Input className="input-style" value={editUser.name} onChange={(value) => onChange('name', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Apellidos">
									<Input className="input-style" value={editUser.last_name} onChange={(value) => onChange('last_name', value)}></Input>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20" style={{ marginTop: "10px" }}>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Nombre de Usuario">
									<Input className="input-style" value={editUser.username} onChange={(value) => onChange('username', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Correo electrónico">
									<Input className="input-style" value={editUser.email} onChange={(value) => onChange('email', value)}></Input>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20" style={{ marginTop: "10px" }}>

							<Layout.Col xs="24" sm="12">
								<Form.Item label="Número telefónico">
									<Input className="input-style" value={editUser.phone} onChange={(value) => onChange('phone', value)}></Input>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Rol">
									<Select className="input-style" style={{ width: "100%" }} value={editUser.role_id} onChange={(value) => onChange('role_id', value)}>
										{
											roleList.map((role) => {
												return <Select.Option key={role.id} label={`${role.level} | ${role.alias}`} value={role.id}></Select.Option>
											})
										}
									</Select>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20" style={{ marginTop: "10px" }}>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Forzar cambio de Contraseña">
									<Switch value={editUser.password_reset} onText="Si" offText="No" onChange={(value) => onChange('password_reset', value)}></Switch>
								</Form.Item>
							</Layout.Col>
							<Layout.Col xs="24" sm="12">
								<Form.Item label="Usuario activo">
									<Switch value={editUser.is_active} onText="Si" offText="No" onChange={(value) => onChange('is_active', value)}></Switch>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter="20" style={{ marginTop: "10px" }}>
							<Form.Item label="Organizaciones">
								<Select className="input-style" style={{ width: "100%" }}
									value={editUser.arrayOrganizations}
									multiple={true}
									onChange={(value) => onChange('organizations', value)}>
									{
										organizationList.map((organization) => {
											return <Select.Option key={organization.id} label={organization.name} value={organization.id}></Select.Option>
										})
									}
								</Select>
							</Form.Item>
						</Layout.Row>
					</Form>
				</Dialog.Body>
				<Dialog.Footer className="dialog-footer">
					<Button className="btn-save"  type="primary" loading={sendingData} onClick={() => submitEditUser()}>Guardar</Button>
					<Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
				</Dialog.Footer>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => ({
	organizationId: state.auth.organizationId,
	roles: state.auth.roles,
});

export default connect(mapStateToProps)(User);
