import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR NOTIFICACIONES***************
-------------------------------------*/
export async function sectorListRequest(value) {
    try {
        let { data } = await axios.get(`/sector`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de notificaciones",
            type: 'error',
            customClass :"message-position"
        });
    }

}

/*------------------------------------
********CREAR NOTIFICACIÓN******************
-------------------------------------*/
export async function notificationCreateRequest(value) {
    try {
        let { data } = await axios.post(`/notification`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear la notificación",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR NOTIFICACIÓN***************
-------------------------------------*/
export async function notificationUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/notification`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar la notificación",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR NOTIFICACION***************
-------------------------------------*/
export async function notificationDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/notification?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar la notificación",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationNotificationListRequest(value) {
    try {
        let { data } = await axios.get("/notification/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de notificaciones",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********LISTAR NOTIFICACIONES POR EMPRESA***************
-------------------------------------*/
export async function getNotificationByOrganization(value) {
    try {
        let { data } = await axios.get(`/notificationByOrganization`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de notificaciones por organización",
            type: 'error',
            customClass :"message-position"
        });
    }

}