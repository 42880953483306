import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { Layout, Breadcrumb, Table, Button, DateRangePicker, Select, Pagination, Tag, Dialog, Card, Form, Input, Switch } from "element-react";
import { URL_API } from "../../../constant";
import Footer from "../../../components/footer/Footer"
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import { getAlarmsChart, } from "./AlarmChartRequest";


function AlarmChart(props) {

	// Varibles de Estado de la Interfaz
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};

	// Varibles de Estado del gráfico
	const [data, setData] = useState([]);
	const [options, setOptions] = useState(
		{
			minColor: "#0d0",
			midColor: "#ddd",
			maxColor: "#f00",
			headerHeight: 15,
			fontColor: "black",
			showScale: true,
			generateTooltip: (_row, _size, value) => {
				return (
					'<div style="background:#fd9; padding:10px; border-style:solid"> '
					+ _row + ' ' +
					+ _size + ' ' +
					+ value + ' ' +
					"</div>"
				);
			},
		}
	);

	useEffect(async () => {
		var chart = await getAlarmsChart();
		if (chart) {
			setData(chart);
		}

	}, []);


	return (
		<div className="container">
			<NavBar showDataProfile={true} showToolBar={showToolBar} />
			<ToolBar stateToolBar={stateToolBar} active="9-2" />
			<Layout.Row>
				<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
					<br />
				</Layout.Col>

				<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
					<Layout.Row style={{ padding: "20px" }}>
						<Breadcrumb separator="/">
							<Breadcrumb.Item>Gráfico de Alarmas</Breadcrumb.Item>
						</Breadcrumb>
					</Layout.Row>
					<Layout.Row style={{ padding: "20px", overflow: "visible" }}>
						<Chart
							chartType="TreeMap"
							width="100%"
							height="600px"
							data={data}
							options={options}
						/>
					</Layout.Row>

					<Layout.Row>
					</Layout.Row>
					<Footer />
				</Layout.Col>
			</Layout.Row>

		</div>
	);
}

const mapStateToProps = (state) => ({
	roles: state.auth.roles,
	organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(AlarmChart);
