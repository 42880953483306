import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import date from "date-and-time";
import {
  Layout,
  Breadcrumb,
  Table,
  Button,
  DateRangePicker,
  Select,
  Pagination,
  Tag,
  Dialog,
  Card,
  Form,
  Input,
  Switch,
} from "element-react";
import { URL_API } from "../../../constant";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navBar/NavBar";
import ToolBar from "../../../components/toolBar/ToolBar";
import {
  getAlarms,
  getAlarmStatus,
  getDevices,
  createAlarmAction,
} from "./AlarmListRequest";
import {
  departmentListRequest,
  citiesByDepartment,
  sectorByCity,
} from "../../Sector/SectorRequest";
import { getDevicesBySector } from "../../consumptionProfile/ConsumptionProfileRequest";
import moment from "moment-timezone";

function AlarmList(props) {
  // Variables de Estado de Formulario
  const [dateStart, setDateStart] = useState(date.addMonths(new Date(), -1));
  const [dateEnd, setDateEnd] = useState(new Date());
  const [devices, setDevices] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [disabledSwitch, setDisabledSwitch] = useState(false);
  const [valuesQuery, setValuesQuery] = useState({});
  const [status, setStatus] = useState([]);

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    department_id: null,
    city_id: null,
    sector_id: null,
  });

  // Variables de Estado de Alimentación
  const [statusList, setStatusList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [alarmsList, setAlarmsList] = useState([]);
  const notificationList = [
    {
      value: "10",
      label: "Temporal",
    },
    {
      value: "08",
      label: "Permanente",
    },
  ];

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const [loadingSearch, setLoading] = useState(false);
  const [loadingSave, setloadingSave] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
  });

  const [editAlarm, setEditAlarm] = useState({
    id: null,
    alarm_id: null,
    alarm_status_id: null,
    alarm_name: "",
    device_id: null,
    device_name: "",
    action: "",
    applyToAll: false,
    completed: false,
  });

  const columns = [
    {
      label: "Dispositivo",
      prop: "device.name",
    },
    {
      label: "EUI",
      prop: "device.eui",
    },
    {
      label: "Fecha",
      prop: "datetime",
      render: (data, column) => {
        //let tz = moment.tz.guess();
        // if (tz == "America/Mexico_City") {
        //   let dateObj = date.addHours(new Date(data.datetime), 0);
        //   let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");

        //   return dateFormat;
        // } else {
        //   let date = moment.tz(data.datetime, tz);
        //   return date.format("YYYY-MM-DD HH:mm:ss");
        // }
        let dateObj = new Date(data.datetime);
        let dateFormat = date.format(dateObj, "YYYY-MM-DD HH:mm:ss");
        return dateFormat;
      },
    },
    {
      label: "Estado",
      render: (data, column) => {
        var statusString = `${data.alarmStatus.code} / ${data.alarmStatus.name}`;
        return statusString;
      },
    },
    {
      label: "",
      prop: "action",
      render: (data, column) => {
        if (data.action) {
          return (
            <Tag type="success" style={{ width: "100%", textAlign: "center" }}>
              Revisado
            </Tag>
          );
        } else {
          return (
            <Tag type="danger" style={{ width: "100%", textAlign: "center" }}>
              Revisar
            </Tag>
          );
        }
      },
    },
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (
          <Button.Group>
            <Button
              disabled={!props.roles.profiles.profile_edit}
              type="primary"
              icon="edit"
              size="mini"
              onClick={() => copyDataUpdate(item)}
            ></Button>
          </Button.Group>
        );
      },
    },
  ];

  useEffect(async () => {
    var auxStatus = await getAlarmStatus();
    if (auxStatus) {
      setStatusList(auxStatus);
    }

    var result = await departmentListRequest();
    if (result) {
      setDepartments(result);
    }
  }, []);

  async function listCities(key, value) {
    var result = await citiesByDepartment(value);
    if (result) {
      setCities(result);
    }
  }

  async function listSectorByCity(key, value) {
    var result = await sectorByCity(value);
    if (result) {
      setSectors(result);
    }
  }

  async function listDeviceBySector(key, value) {
    let query = { sector_id: value };
    var result = await getDevicesBySector(query);
    if (result) {
      setDevicesList(result);
    }
  }

  async function copyDataUpdate(item) {
    let obj = {
      id: item.alarmActions ? item.alarmActions.id : null,
      alarm_id: item.id,
      alarm_name: item.alarmStatus.name,
      alarm_status_id: item.alarmStatus.id,
      device_id: item.device.id,
      device_name: item.device.name,
      action: item.alarmActions ? item.alarmActions.action : "",
      applyToAll: false,
      completed: item.alarmActions ? item.alarmActions.completed : false,
    };

    if (item.alarmActions) {
      setDisabledSwitch(item.alarmActions.completed);
    }

    setEditAlarm(obj);
    setDialogEditVisible(true);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editAlarm };
    newValues[key] = value;
    setEditAlarm(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function profileUpdateApi() {
    var values = editAlarm;
    setloadingSave(true);
    var result = await createAlarmAction(values);
    if (result) {
      setloadingSave(false);
      cleanForm();
      searchAlarms();
    }
  }

  async function searchAlarms() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;

      if (devices.length > 0) {
        values.devices = devices;
      }

      if (status.length > 0) {
        values.status = status;
      }

      if (notificationStatus) {
        values.notification_status = notificationStatus;
      }

      if (dateStart instanceof Date && dateEnd instanceof Date) {
        values.date_start = date.format(dateStart, "YYYY-MM-DD HH:mm:ss.SSS");
        values.date_end = date.format(dateEnd, "YYYY-MM-DD HH:mm:ss.SSS");
      }

      setValuesQuery(values);
      var alarms = await getAlarms(values);
      setAlarmsList(alarms.rows);
      pagination.total = alarms.count;

      setLoading(false);
    } catch (error) {
      console.log("Hubo un error al obtener las alarmas");
      console.error(error);
      setLoading(false);
    }
  }

  function cleanForm() {
    setDialogEditVisible(false);
    setEditAlarm({
      id: null,
      alarm_id: null,
      alarm_status_id: null,
      alarm_name: "",
      device_id: null,
      device_name: "",
      action: "",
      applyToAll: false,
      completed: false,
    });
    setQueryData({
      department_id: null,
      city_id: null,
      sector_id: null,
    });
  }

  function fixDates(dateStart, dateEnd) {
    if (dateStart && dateEnd) {
      var dateDiff = date.subtract(dateEnd, dateStart);
      var newDateStart = dateStart;
      var newDateEnd = dateEnd;
      if (dateDiff.toMilliseconds < 0) {
        newDateEnd = date.addMonths(dateStart, 1);
      }

      return [newDateStart, newDateEnd];
    }
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchAlarms();
  }

  async function downloadAlarmApi() {
    try {
      setLoadingCSV(true);

      if (props.organizationId) {
        var organization_id = props.organizationId;
      }

      if (devices.length > 0) {
        var _devices = devices;
      }

      if (status.length > 0) {
        var _status = status;
      }

      if (notificationStatus) {
        var notification_status = notificationStatus;
      }

      if (dateStart instanceof Date && dateEnd instanceof Date) {
        var date_start = date.format(dateStart, "YYYY-MM-DD HH:mm:ss.SSS");
        var date_end = date.format(dateEnd, "YYYY-MM-DD HH:mm:ss.SSS");
      }

      var sector_id = queryData.sector_id;
      var city_id = queryData.city_id;
      var department_id = queryData.department_id;

      var win = window.open(
        URL_API +
          "/alarm/downloadAlarms" +
          "?organization_id=" +
          organization_id +
          "&devices=" +
          _devices +
          "&status=" +
          _status +
          "&notification_status=" +
          notification_status +
          "&date_start=" +
          date_start +
          "&date_end=" +
          date_end +
          "&sector_id=" +
          sector_id +
          "&city_id=" +
          city_id +
          "&department_id=" +
          department_id,
        "_blank"
      );

      win.focus();

      setLoadingCSV(false);
    } catch (error) {
      console.log("Hubo un error al descargar el archivo CSV");
      setLoadingCSV(false);
    }
  }

  return (
    <div className="container">
      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="9-1" />
      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
          <br />
        </Layout.Col>

        <Layout.Col
          xs="24"
          sm={stateToolBar ? 19 : 24}
          className="toolbar-margins"
        >
          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Lista Alarmas</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form
            className="es-CO"
            model={queryData}
            labelWidth="120"
            labelPosition="top"
          >
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col sm="4" xs="24">
                    <Form.Item className="label-filter" label="Rango de fechas">
                      <DateRangePicker
                        isShowTime={true}
                        value={[dateStart, dateEnd]}
                        placeholder="Selecciona un rango de fechas"
                        onChange={(dates) => {
                          if (dates) {
                            var fixedDates = fixDates(dates[0], dates[1]);
                            setDateStart(fixedDates[0]);
                            setDateEnd(fixedDates[1]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>

                  <Layout.Col sm="4" xs="24">
                    <Form.Item className="label-filter" label="Departamento">
                      <Select
                        style={{ marginTop: "10px", width: "100%" }}
                        clearable={true}
                        filterable={true}
                        onChange={(value) => {
                          listCities("id", value);
                          handleQueryChange("department_id", value);
                        }}
                      >
                        {departments.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Ciudad">
                      <Select
                        style={{ marginTop: "10px", width: "100%" }}
                        value={queryData.city_id}
                        clearable={true}
                        filterable={true}
                        onChange={(value) => {
                          listSectorByCity("id", value);
                          handleQueryChange("city_id", value);
                        }}
                      >
                        {cities.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>

                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Sector">
                      <Select
                        style={{ marginTop: "10px", width: "100%" }}
                        value={queryData.sector_id}
                        clearable={true}
                        filterable={true}
                        onChange={(value) => {
                          listDeviceBySector("sector_id", value);
                          handleQueryChange("sector_id", value);
                        }}
                      >
                        {sectors.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>

                  {/*<Layout.Col sm="4" xs="24">
										<Form.Item label="Tipo de Notificación">
											<Select
												value={notificationStatus}
												clearable={true}
												onChange={(newNotification) => setNotificationStatus(newNotification)}
												style={{ marginTop: "10px", width: "100%" }}
											>
												{notificationList.map((entry) => (
													<Select.Option key={entry.value} label={entry.label} value={entry.value} />
												))}
											</Select>
										</Form.Item>
									</Layout.Col>*/}

                  <Layout.Col sm="4" xs="24">
                    <Form.Item className="label-filter" label="Dispositivos">
                      <Select
                        value={devices}
                        multiple={true}
                        filterable={true}
                        onChange={(newDevices) => setDevices(newDevices)}
                        style={{ marginTop: "10px", width: "100%" }}
                      >
                        {devicesList.map((entry) => (
                          <Select.Option
                            key={entry.id}
                            label={entry.name}
                            value={entry.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>

                  <Layout.Col sm="4" xs="24">
                    <Form.Item className="label-filter" label="Estado">
                      <Select
                        value={status}
                        multiple={true}
                        filterable={true}
                        onChange={(newStatus) => setStatus(newStatus)}
                        style={{ marginTop: "10px", width: "100%" }}
                      >
                        {statusList.map((entry) => (
                          <Select.Option
                            key={entry.id}
                            label={`${entry.code} / ${entry.name}`}
                            value={entry.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="10">
                  <Layout.Col sm="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchAlarms()}
                      style={{ marginTop: "33px", width: "100%" }}
                    >
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col sm="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      loading={loadingCSV}
                      onClick={() => downloadAlarmApi()}
                      style={{ marginTop: "33px", width: "100%" }}
                    >
                      <i className="fa fa-file-excel-o"></i> Excel{" "}
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px", overflow: "visible" }}>
            <Table
              style={{ width: "100%" }}
              columns={columns}
              data={alarmsList}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron alarmas"
            />
          </Layout.Row>

          <Layout.Row>
            <Pagination
              style={{ float: "right" }}
              layout="total, prev, pager, next"
              total={pagination.total}
              pageSize={pagination.pageSize}
              onCurrentChange={(page) => onCurrentChange(page - 1)}
            />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal editar */}
      <Dialog
        size="small"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}
      >
        <Dialog.Body>
          <Card
            className="box-card"
            style={{ overflow: "visible" }}
            header={
              <p style={{ fontSize: "24px", color: "#20a0ff", margin: "0" }}>
                Análisis de la Alarma
              </p>
            }
          >
            <Form
              className="es-CO"
              model={editAlarm}
              labelWidth="120"
              labelPosition="top"
            >
              <Layout.Row className="div-header-style">
                <Layout.Col xs="24" sm="3">
                  <p className="label-item">Dispositivo:</p>
                </Layout.Col>
                <Layout.Col xs="24" sm="9">
                  <p className="item-value">{editAlarm.device_name}</p>
                </Layout.Col>
                <Layout.Col xs="24" sm="3">
                  <p className="label-item">Alarma:</p>
                </Layout.Col>
                <Layout.Col xs="24" sm="9">
                  <p className="item-value">{editAlarm.alarm_name}</p>
                </Layout.Col>
              </Layout.Row>

              <Form.Item label="Acción">
                <Input
                  placeholder="Ingrese una acción"
                  type="textarea"
                  autosize={{ minRows: 4, maxRows: 10 }}
                  value={editAlarm.action}
                  onChange={(value) => onChangeEdit("action", value)}
                ></Input>
              </Form.Item>
              <Layout.Row>
                <Layout.Col xs="24" sm="12">
                  <Form.Item label="Aplicar a todas">
                    <Switch
                      value={editAlarm.applyToAll}
                      onText="Si"
                      offText="No"
                      disabled={disabledSwitch}
                      onChange={(value) => onChangeEdit("applyToAll", value)}
                    ></Switch>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="12">
                  <Form.Item label="Finalizado">
                    <Switch
                      value={editAlarm.completed}
                      onText="Si"
                      offText="No"
                      disabled={disabledSwitch}
                      onChange={(value) => onChangeEdit("completed", value)}
                    ></Switch>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            </Form>
          </Card>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button
            type="primary"
            loading={loadingSave}
            onClick={() => profileUpdateApi()}
          >
            Guardar
          </Button>
          <Button onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
  organizationId: state.auth.organizationId,
});

export default connect(mapStateToProps)(AlarmList);
