import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********CREAR PERFIL******************
-------------------------------------*/
export async function profileCreateRequest(value) {
    try {
        let { data } = await axios.post(`/auth/profiles`, { data: value });
    
        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el perfil",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR PERFIL***************
-------------------------------------*/
export async function profileUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/auth/profiles`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el perfil",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationProfileListRequest(value) {
    try {
        let { data } = await axios.get("/auth/profiles/pagination", { params: value });
     
        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {        
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de perfiles",
            type: 'error',
            customClass :"message-position"
        });
    }
}