import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { Layout, Card, Breadcrumb, Carousel, Tag, } from 'element-react';
import date from 'date-and-time';
import NavBar from '../../../components/navBar/NavBar';
import ToolBar from '../../../components/toolBar/ToolBar';
import Footer from "../../../components/footer/Footer"
import GoogleMap from '../../../components/map/Map';
import './DeviceDetail.css'
import { paginationDeviceHistoryRequest } from './DeviceDetailRequest';
import { getFilesByDevice } from '../DeviceRequest';

function DeviceDetail(props) {

    // Varibles de Estado de la Interfaz
    const [stateToolBar, setStateToolBar] = useState(true);
    const showToolBar = () => { setStateToolBar(!stateToolBar) };

    // Variables de Estado de Alimentación
    const [images, setImages] = useState([]);
    const [detail, setDetail] = useState({});
    const [data, setData] = useState({
        eui: "",
        name: "",
        serial: "",
        app_key: "",
        organization_id: null,
        sector_id: null,
        contract_id: null,
        address: "",
        latitud: 0,
        longitud: 0,
        description: "",
        additional_address: "",
        postal_code: null,
        fileList: [],
        model_id: null,
    });

    const [meterTypeList, setMeterTypeList] = useState([
        { meter_type_id: 1, name: 'Chorro múltiple' },
        { meter_type_id: 2, name: 'Chorro único' },
        { meter_type_id: 3, name: 'Electromagnético' },
        { meter_type_id: 4, name: 'Ultrasónico' },
        { meter_type_id: 5, name: 'volumétrico' },
        { meter_type_id: 6, name: 'Woltman' },
        { meter_type_id: 7, name: 'Medidor de Pulsos' },
    ]);
    const [technicalNormsList, setTechnicalNormsList] = useState([
        { technical_norm_id: 1, name: 'NTC 1063-1:2007' },
        { technical_norm_id: 2, name: 'NTC ISO 4064-1:2016' },
    ]);
    const [flowList, setFlowList] = useState([
        { flow_id: 1, value: '1' },
        { flow_id: 2, value: '1.6' },
        { flow_id: 3, value: '2.5' },
        { flow_id: 4, value: '4' },
        { flow_id: 5, value: '6.3' },
        { flow_id: 6, value: '10' },
        { flow_id: 7, value: '16' },
        { flow_id: 8, value: '25' },
        { flow_id: 9, value: '40' },
        { flow_id: 10, value: '63' },
        { flow_id: 11, value: '100' },
        { flow_id: 12, value: '160' },
        { flow_id: 13, value: '250' },
        { flow_id: 14, value: '400' },
        { flow_id: 15, value: '630' },
        { flow_id: 16, value: '1000' },
        { flow_id: 17, value: '1600' },
        { flow_id: 18, value: '2500' },
        { flow_id: 19, value: '4000' },
        { flow_id: 20, value: '6300' },
    ]);

    const [relationQList, setRelationQList] = useState([
        { relationq_id: 1, value: '40' },
        { relationq_id: 2, value: '50' },
        { relationq_id: 3, value: '63' },
        { relationq_id: 4, value: '80' },
        { relationq_id: 5, value: '100' },
        { relationq_id: 6, value: '125' },
        { relationq_id: 7, value: '160' },
        { relationq_id: 8, value: '200' },
        { relationq_id: 9, value: '250' },
        { relationq_id: 10, value: '315' },
        { relationq_id: 11, value: '400' },
        { relationq_id: 12, value: '500' },
        { relationq_id: 13, value: '630' },
        { relationq_id: 14, value: '800' },
        { relationq_id: 15, value: '1000' },
        { relationq_id: 16, value: '1250' },
        { relationq_id: 17, value: '1600' },
        { relationq_id: 18, value: '2000' },
    ]);

    const [presicionClassList, setPresicionClassList] = useState([
        { presicion_id: 1, name: 'CP 1' },
        { presicion_id: 2, name: 'CP 2' },
    ]);

    const [intervalList, setIntervalList] = useState([
        { interval_id: 1, value: '9 999' },
        { interval_id: 2, value: '99 999' },
        { interval_id: 1, value: '999 999' },
        { interval_id: 2, value: '9 999 999' },
    ]);

    useEffect(async () => {
        if (props.location.row == null) {
            window.location.href = "/dispositivo"
        } else {

            let result = await getFilesByDevice({ device_id: props.location.row.id });
            setImages(result)
            setData(props.location.row);
            getDetail();
        }
    }, []);

    function getDetail() {
        let model = props.location.row.model;
        console.log(model);
        let meter_type_id = meterTypeList.find(element => element.meter_type_id === model.meter_type_id);
        let technical_norm_id = technicalNormsList.find(element => element.technical_norm_id === model.technical_norm_id);
        let flow_id = flowList.find(element => element.flow_id === model.flow_id);
        let relationq_id = relationQList.find(element => element.relationq_id === model.relationq_id);
        let presicion_id = presicionClassList.find(element => element.presicion_id === model.presicion_id);
        let interval_id = intervalList.find(element => element.interval_id === model.interval_id);

        let obj = {
            meter_type: meter_type_id.name,
            technical_norm: technical_norm_id.name,
            flow: flow_id.value,
            relationq: relationq_id.value,
            presicion: presicion_id.name,
            interval: interval_id.value
        }

        setDetail(obj);
    }

    return (
        <div className="container">

            <NavBar showDataProfile={true} showToolBar={showToolBar} />
            <ToolBar stateToolBar={stateToolBar} active="2" />

            <Layout.Row>
                <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

                <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
                    <Layout.Row style={{ padding: "20px" }}>
                        <Breadcrumb separator="/">
                            <Breadcrumb.Item>Dispositivo</Breadcrumb.Item>
                            <Breadcrumb.Item><a href="/dispositivo">Lista Dispositivos</a></Breadcrumb.Item>
                            <Breadcrumb.Item>detalle</Breadcrumb.Item>
                        </Breadcrumb>
                    </Layout.Row>
                    <Layout.Row style={{ padding: "20px" }}>
                        <Card className="box-card"
                            header={
                                <Layout.Row>
                                    <p style={{ fontSize: "24px", color: "#20a0ff", margin: "0" }}>Detalles del Medidor</p>
                                    <Layout.Row>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Dispositivo:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.name ? data.name : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Marca:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.model ? data.model.trademark.name : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Modelo:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.model ? data.model.model + '-' + data.model.version : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Número de serial:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.serial ? data.serial : 'N/A'}</p>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Tipo de medidor:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.meter_type ? detail.meter_type : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Norma técnica:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.technical_norm ? detail.technical_norm : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Caudal permantente Q3 m³/h:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.flow ? detail.flow : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Relación Q3/Q1 “R”:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.relationq ? detail.relationq : 'N/A'}</p>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Clase de presición:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.presicion ? detail.presicion : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Intervalo de indicación m³:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{detail.interval ? detail.interval : 'N/A'}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>{data.lora_service == 'Sigfox' ? 'ID Sigfox' : 'EUI'}:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.lora_service == 'Sigfox' ? data.device_id : data.eui}</p>
                                        </Layout.Col>
                                        <Layout.Col xs="24" sm="6">
                                            <p style={{ fontSize: "14px", color: "#20a0ff", float: 'left' }}>Sector:</p>
                                            <p style={{ fontSize: "14px", float: 'left', marginLeft: '10px', color: "#5e6d82" }}>{data.name ? data.sector.name : 'N/A'}</p>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Row>
                            } >

                            <Layout.Row className="row-bg" justify="space-around" gutter="20" >
                                <Layout.Col xs="24" sm="12" md="12" lg="12" >
                                    <Layout.Row className="row-bg" justify="space-around" gutter="20">
                                        <GoogleMap coordinates={{ lat: data.latitud, lng: data.longitud }} />
                                    </Layout.Row>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="12" md="12" lg="12" className="carousel">
                                    <Layout.Row className="box">
                                        <Carousel interval="400000" trigger="click" height="500px" >
                                            {
                                                images.map((item, index) => {
                                                    return (
                                                        <Carousel.Item key={index}>
                                                            {<img src={item.url} />}
                                                        </Carousel.Item>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </Layout.Row>
                                </Layout.Col>
                            </Layout.Row>
                        </Card>
                        <Footer />
                    </Layout.Row>
                </Layout.Col>
            </Layout.Row>

        </div>
    );
}

export default DeviceDetail;