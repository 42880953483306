import { Message } from "element-react";
import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";

export async function getConsumptionPeriods(values) {
    try {
        let { data } = await axios.get('/consumptionPeriods', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener el comparativo de consumo",
            type: 'error',
            customClass: "message-position"
        });
    }
}

// OBTIENE LA GRAFICA DE CONSUMOS POR AÑO MES SEMANA DIA Y HORA
export async function getMaxAndMinConsuption(values) {
    try {
        let { data } = await axios.get('/chartMaxAndMinConsuption', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener el comparativo de consumo",
            type: 'error',
            customClass: "message-position"
        });
    }
}

// OBTIENE LOS DISPOSITIVOS POR SECTORES
export async function getDevicesBySector(values) {
    try {
        let { data } = await axios.get('/devicesBySector', { params: values });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener los dispositivos por sector",
            type: 'error',
            customClass: "message-position"
        });
    }
}

