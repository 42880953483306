import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";


/*------------------------------------
********LISTAR GRUPOS DE USUARIOS***************
-------------------------------------*/
export async function userGroupListRequest(value) {
    try {
        let { data } = await axios.get("/groups");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de grupos de usuarios",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CREAR GRUPO DE USUARIOS************  
-------------------------------------*/
export async function userGroupCreateRequest(value) {
    try {
        let { data } = await axios.post('/groups', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el grupo de usuario",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR GRUPOS DE USUARIOS************  
-------------------------------------*/
export async function userGroupUpdateRequest(value) {
    try {
        let { data } = await axios.put('/groups', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el grupo de usuario",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR GRUPO DE USUARIOS***************
-------------------------------------*/
export async function userGroupDeleteRequest(value) {
    try {        
        let { data } = await axios.delete(`/groups?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el grupo de usuario",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION GRUPOS DE USUARIOS****************
----------------------------------------------------*/
export async function userGroupPaginationRequest(value) {
    try {
        let { data } = await axios.get("/groups/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de grupos de usuarios",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER USUARIOS POR ORGANIZACION****************
----------------------------------------------------*/
export async function userByOrganizationRequest(value) {
    try {
        let { data } = await axios.get("/auth/users_by_organization");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista de usuarios por organziación",
            type: 'error',
            customClass :"message-position"
        });
    }
}
