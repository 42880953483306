import "element-theme-default";
import {  Layout,  } from "element-react";
import "./Footer.css";
import { connect } from "react-redux";
import general_logo from '../../components/images/general_logo.svg';

function Footer(props) {


	return (
		<div>
			<Layout.Row type="flex" justify="center" align="bottom">
				<img src={general_logo} className="general_logo" />
			</Layout.Row>
			<Layout.Row type="flex" justify="center" align="bottom">
				<p style={{ color: "#000000" }}>
					{" "}
					<b style={{ color: "#ffffff" }}>VITALES.</b> Una cocreación de Ingeniería Apropiada, Eurekia y Acuatubos{" "}
				</p>
			</Layout.Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	userFullName: state.auth.userFullName,
	isLoggedIn: state.auth.isLoggedIn,
	organizationId: state.auth.organizationId,
	userId: state.auth.userId
});


export default connect(mapStateToProps)(Footer);
