import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR MARCAS***************
-------------------------------------*/
export async function trademarkListRequest(value) {
    try {
        let { data } = await axios.get(`/trademark`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de marcas",
            type: 'error',
            customClass: "message-position"
        });
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationTrademarkListRequest(value) {
    try {
        let { data } = await axios.get("/trademark/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de marcas",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********CREAR MARCA******************
-------------------------------------*/
export async function trademarkCreateRequest(value) {
    try {
        let { data } = await axios.post(`/trademark`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear la marca",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR MARCA***************
-------------------------------------*/
export async function trademarkUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/trademark`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar la marca",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR MARCA***************
-------------------------------------*/
export async function trademarkDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/trademark?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar la marca",
            type: 'error',
            customClass: "message-position"
        });
    }
}