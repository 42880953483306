import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationHolderListRequest(value) {
    try {
        let { data } = await axios.get("/holders/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de titulares",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE CONVENIOS****************
----------------------------------------------------*/
export async function getAgreementList() {
    try {
        let { data } = await axios.get("/holders/agreement");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener los perfiles",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE TIPOS DE IDENTIFICACION****************
----------------------------------------------------*/
export async function getIdentificationTypeList(value) {
    try {
        let { data } = await axios.get(`/holders/identification_type?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener los perfiles",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE OBLIGACIONES****************
----------------------------------------------------*/
export async function getObligationList() {
    try {
        let { data } = await axios.get("/holders/obligations");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener la lista de obligaciones",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE ACTIVIDADES ECONOMICAS****************
----------------------------------------------------*/
export async function getEconomicActivitiesList() {
    try {
        let { data } = await axios.get("/holders/economic_activities");

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un error al obtener la lista de actividades economicas",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********CREAR TITULAR******************
-------------------------------------*/
export async function holderCreateRequest(value) {
    try {
        let { data } = await axios.post(`/holders`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el sector",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR TITULAR***************
-------------------------------------*/
export async function holderUpdateRequest(value) {
    try {
      
        let { data } = await axios.put(`/holders`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el titular",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR TITULAR***************
-------------------------------------*/
export async function holderDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/holders?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el titular",
            type: 'error',
            customClass :"message-position"
        });
    }

}

