import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********CREAR ROL******************
-------------------------------------*/
export async function roleCreateRequest(value) {
    try {
        let { data } = await axios.post(`/auth/roles`, { data: value });
       
        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el sector",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR ROL***************
-------------------------------------*/
export async function roleUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/auth/roles`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el Rol",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationRoleListRequest(value) {
    try {
        let { data } = await axios.get("/auth/roles/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de sectores",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER LISTA DE PERFILES****************
----------------------------------------------------*/
 export async function getProfiles(){
    try {
        let {data} = await axios.get("/auth/profiles");
      
        if (handleResponse(data)){
            let values = data.data;
            return values;
        }else{
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message:"Hubo un error al obtener los perfiles",
            type: 'error',
            customClass :"message-position"
        });
    }
}