import { React, useState, useEffect } from "react";
import "element-theme-default";
import { Card, Layout, Input, Button, } from "element-react";
import "./Login.css";
import { connect } from "react-redux";
import { logIn } from "../../redux/auth/actions";
import logo_login from '../../components/images/logo_login.svg';
import gota_login from '../../components/images/gota_login.svg';
import Footer from "../footer/Footer";

function Login(props) {

	// useEffect(async () => {
	// 	var resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
	// 	console.log(resolvedOptions);

	// 	let evento = new Date();
	// 	console.log(evento.toUTCString());
	// 	let data = new Date(evento.toUTCString());

	// 	console.log(data.toLocaleDateString(resolvedOptions.locale));
	// 	console.log(data.toLocaleTimeString(resolvedOptions.locale));
	// 	console.log(data.toLocaleString(resolvedOptions.locale));
	// 	console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

	// }, []);

	const [data, setData] = useState({
		username: "",
		password: "",
	});

	const handleInputChange = (key, value) => {
		setData({
			...data,
			[key]: value,
		});
	};

	async function loginApi() {
		var result = await props.logIn(data);

		if (!result) {
			setData({
				username: "",
				password: ""
			});
		}
	}

	return (
		<div className="container">
			<Layout.Row type="flex" className="row-login" justify="center">
				<Card className="box-card-login">
					<Layout.Row>
						<img src={logo_login} className="logo_login" />
					</Layout.Row>
					<Layout.Row style={{ marginBottom: "10px" }}>
						<span style={{ marginBottom: "10px", color: "#000000" }}>Usuario:</span>
					</Layout.Row>

					<Layout.Row
						type="flex"
						className="row-bg"
						justify="center"
						style={{ marginBottom: "20px" }}
					>
						<Input
							className="login-inputs"
							value={data.username}
							type="text"
							name="username"
							onChange={(value) => {
								handleInputChange("username", value);
							}}
						/>
					</Layout.Row>

					<Layout.Row style={{ marginBottom: "10px" }}>
						<span style={{ marginBottom: "10px", color: "#000000" }}>Contraseña:</span>
					</Layout.Row>

					<Layout.Row
						type="flex"
						className="row-bg"
						justify="center"
						style={{ marginBottom: "20px" }}
					>
						<Input
							className="login-inputs"
							value={data.password}
							type="password"
							name="password"
							onChange={(value) => {
								handleInputChange("password", value);
							}}
						/>
					</Layout.Row>

					<Layout.Row type="flex" className="row-bg" style={{ marginBottom: "20px" }}>
						<Button type="primary" className="btn-login" onClick={() => loginApi()}>
							Ingresar <i className="fas fa-sign-in-alt" style={{ color: "white" }} ></i>
						</Button>
					</Layout.Row>
					<Layout.Row className="circle"></Layout.Row>
				</Card>
			</Layout.Row>

			<Layout.Row type="flex" className="row-login" justify="center" >
				<img src={gota_login} className="gota-login" />
			</Layout.Row>

			<Footer />
		</div>
	);
}

export default connect(null, { logIn })(Login);
