import axios from "../../../utilities/axiosInstance";
import React, { useState } from 'react';


/*------------------------------------
*******LISTAR UNA FACTURA**************
-------------------------------------*/
export async function billOneRequest(value) {
    try {

        let response = await axios.get(`/bill?id=${value}`);

        return response.data;
    } catch (error) {
        console.log(error);
        return {
            data: null,
            error: true,
            message: "Hubo un problema al obtener el listado de faturas"
        }
    }

}

/*------------------------------------
*******LISTAR FACTURAS****************
-------------------------------------*/
export async function billListRequest(value) {


    try {

        let response = await axios.get('/bill', {
            headers: {
                'x-access-token': localStorage.getItem('jwt')
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        return {
            data: null,
            error: true,
            message: "Hubo un problema al obtener el listado de faturas"
        }
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationBillListRequest(value) {
    try {
        let response = await axios.get("/bill/pagination", { params: value });

        return response.data;
    } catch (error) {
        console.log(error);
        return {
            data: null,
            error: true,
            message: "Hubo un problema al obtener el listado paginado de faturas",
        };
    }
}

/*------------------------------------
*******DESCARGAR FAcTURAS**************
-------------------------------------*/
export async function billDownloadRequest(id) {
    try {

        let response = await axios.get(`/bill/download?id=${id}`);

        console.log(response.data);

    } catch (error) {
        console.log(error);
        return {
            data: null,
            error: true,
            message: "Hubo un problema al obtener el listado de la fatura"
        }
    }

}