import React, { useState, useEffect, useRef } from "react";
import { Message } from "element-react";
import "element-theme-default";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Layout,
  Breadcrumb,
  Table,
  DateRangePicker,
  Select,
  Tooltip,
} from "element-react";
import { Bar } from "react-chartjs-2";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import "./ConsumtionProfile.css";
import { URL_API } from "../../constant";
import {
  getMaxAndMinConsuption,
  getDevicesBySector,
} from "./ConsumptionProfileRequest";
import { sectorListRequest } from "../../pages/Sector/SectorRequest";
import { contractListRequest } from "../Contract/ContractRequest";
import moment from "moment-timezone";

function ConsumptionProfile(props) {
  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const [dialogDownloadVisible, setDialogDownloadVisible] = useState(false);
  const [loadingSearch, setLoading] = useState(false);
  const showToolBar = () => {
    setStateToolBar(!stateToolBar);
  };
  const chartRef = useRef(null);
  const [title, setTitle] = useState("");

  // Variables de Estado de Alimentación
  const [accumulated, setAccumulated] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [contractList, setContracts] = useState([]);

  // Variables de Estado de Formulario
  const [devices, setDevices] = useState([]);
  const [periodTime, setPeriodTime] = useState([
    {
      id: "year",
      label: "Año",
    },
    {
      id: "month",
      label: "Mes",
    },
    /*{
			id: 'week',
			label: 'Semana'
		},*/
    {
      id: "day",
      label: "Día",
    },
    {
      id: "hour",
      label: "Hora",
    },
  ]);

  const [rangeDate, setRangeDate] = useState({
    date_start: new Date(),
    date_end: new Date(),
  });
  const [downloadHistory, setDownloadHistory] = useState({
    sector: "",
    device_id: "",
    date_start: new Date(),
    date_end: new Date(),
  });

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    devices: [],
    sector_id: null,
    contract_id: null,
    date: null,
  });

  useEffect(async () => {
    var result = await sectorListRequest();
    if (result) {
      setSectors(result);
    }
  }, []);

  async function listDevices(value) {
    let query = { sector_id: value };
    var result = await getDevicesBySector(query);

    if (result) {
      setDevicesList(result);
    }
  }

  async function listContractBySector(value) {
    if (value) {
      let query = { sector_id: value };
      var result = await contractListRequest(query);

      if (result) {
        setContracts(result);
      }
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  function createTitle() {
    var values = { ...queryData };
    //let sector, device, contract = '';

    if (values.sector_id) {
      var sector = sectors.filter(function (element) {
        return element.id === values.sector_id;
      });
    }
    let devicesFiltered = [];
    if (devices.length > 0) {
      devicesFiltered = devicesList.filter(function (e) {
        if (this.indexOf(e.id) >= 0) {
          return e;
        }
      }, devices);
    }

    if (values.contract_id) {
      var contract = contractList.filter(function (element) {
        return element.id === values.contract_id;
      });
    }
    let deviceName = "";

    devicesFiltered.forEach((element) => {
      deviceName += element.name + ", ";
    });

    let sectorName = sector ? `, Sector: ${sector[0].name}` : "";
    let deviceNames = devices.length > 0 ? `, Dispositivo: ${deviceName}` : "";
    let contractNumber = contract ? `, Contrato: ${contract[0].contract}` : "";

    return sectorName + deviceNames + contractNumber;
  }

  async function searchConsumption() {
    try {
      var values = { ...queryData };
      if (values.date && values.period) {
        values.devices = devices;
        let myDate = new Date();
        let myOffset = myDate.getTimezoneOffset() / -60;
        values.tz = myOffset;

        setLoading(true);
        var result = await getMaxAndMinConsuption(values);
        if (result) {
          setAccumulated(result);
          let title = createTitle();
          setTitle(result.title + title);
        }
        setLoading(false);
      } else {
        Message({
          showClose: true,
          message:
            "Debe seleccionar un rango de fecha y un periodo para graficar",
          type: "error",
          customClass: "message-position",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function downloadExcel() {
    try {
      let values = { ...queryData };
      if (values.date) {
        setLoading(true);

        let organization_id = props.organizationId
          ? props.organizationId
          : null;
        let contract_id = values.contract_id ? values.contract_id : "";
        let sector_id = values.sector_id ? values.sector_id : "";

        let date0 = values.date[0];
        let date1 = values.date[1];
        let date_start = moment(date0);
        let date_end = moment(date1);

        let period = values.period;
        let array_devices = devices;
        let myDate = new Date();
        let myOffset = myDate.getTimezoneOffset() / -60;
        let tz = myOffset;

        var win = window.open(
          URL_API +
            "/consumptionPeriods/downloadExcel" +
            "?organization_id=" +
            organization_id +
            "&contract_id=" +
            contract_id +
            "&sector_id=" +
            sector_id +
            "&period=" +
            period +
            "&date_start=" +
            date_start.format("YYYY-MM-DD HH:mm:ss") +
            "&date_end=" +
            date_end.format("YYYY-MM-DD HH:mm:ss") +
            "&array_devices=" +
            array_devices +
            "&tz=" +
            tz,
          "_blank"
        );
        win.focus();
        setLoading(false);
      } else {
        Message({
          showClose: true,
          message: "Debe seleccionar un rango de fecha para generar excel",
          type: "error",
          customClass: "message-position",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function cleanForm() {
    setDialogDownloadVisible(false);
    setQueryData({
      device_id: null,
      sector_id: null,
      contract_id: null,
      date: null,
    });
    setRangeDate({
      date_start: new Date(),
      date_end: new Date(),
    });
    setDevicesList([]);
  }

  async function downloadChart() {
    let chart = chartRef.current;
    let image_base64 = chart.toBase64Image();
    var a = document.createElement("a");
    a.href = image_base64;
    a.download = "Comparativo de consumo.png";

    // Trigger the download
    a.click();
  }

  return (
    <div className="container">
      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="7" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
          <br />
        </Layout.Col>

        <Layout.Col
          xs="24"
          sm={stateToolBar ? 19 : 24}
          className="toolbar-margins"
        >
          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Reportes</Breadcrumb.Item>
              <Breadcrumb.Item>Comparativo de consumos</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form
            className="es-CO"
            model={queryData}
            labelWidth="120"
            labelPosition="top"
          >
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col xs="24" sm="4">
                    <Form.Item
                      className="label-filter"
                      label="Rango de fecha"
                      style={{ marginTop: "-10px" }}
                    >
                      <DateRangePicker
                        isShowTime={true}
                        value={rangeDate}
                        placeholder="Seleccione"
                        onChange={(date) => {
                          handleQueryChange("date", date);
                          setRangeDate(date);
                        }}
                      />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="4">
                    <Form.Item className="label-filter" label="Sector">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                        value={queryData.sector_id}
                        filterable={true}
                        clearable={true}
                        onChange={(value) => {
                          handleQueryChange("sector_id", value);
                          listDevices(value);
                          listContractBySector(value);
                        }}
                      >
                        {sectors.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.name}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="4">
                    <Form.Item className="label-filter" label="Dispositivo">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                        value={devices}
                        filterable={true}
                        clearable={true}
                        multiple={true}
                        onChange={(newDevices) => setDevices(newDevices)}
                        /*onChange={(value) => {
													handleQueryChange("device_id", value);
												}}*/
                      >
                        {devicesList.map((entry) => (
                          <Select.Option
                            key={entry.id}
                            label={entry.name}
                            value={entry.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="4">
                    <Form.Item className="label-filter" label="Contrato">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                        value={queryData.device_id}
                        filterable={true}
                        clearable={true}
                        onChange={(value) => {
                          handleQueryChange("contract_id", value);
                        }}
                      >
                        {contractList.map((entry) => (
                          <Select.Option
                            key={entry.id}
                            label={`${entry.contract} | ${entry.holder.first_name} ${entry.holder.second_name} ${entry.holder.first_surname} ${entry.holder.second_surname}`}
                            value={entry.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="3">
                    <Form.Item className="label-filter" label="Periodo">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                        value={queryData.period}
                        clearable={true}
                        filterable={true}
                        onChange={(value) => {
                          handleQueryChange("period", value);
                        }}
                      >
                        {periodTime.map((item) => (
                          <Select.Option
                            key={item.id}
                            label={item.label}
                            value={item.id}
                          />
                        ))}
                      </Select>
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="2">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchConsumption()}
                      style={{ marginTop: "23px" }}
                    >
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="1">
                    <Tooltip
                      className="item"
                      effect="dark"
                      content="Descargar imágenes"
                      placement="top"
                    >
                      <Button
                        disabled={!props.roles.profiles.profile_see}
                        className="btn-delete"
                        type="primary"
                        onClick={() => downloadChart()}
                        style={{ marginTop: "23px" }}
                      >
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                      </Button>
                    </Tooltip>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="1">
                    <Tooltip
                      className="item"
                      effect="dark"
                      content="Descargar excel"
                      placement="top"
                    >
                      <Button
                        disabled={!props.roles.profiles.profile_see}
                        className="btn-delete"
                        type="primary"
                        onClick={() => downloadExcel()}
                        style={{ marginTop: "23px" }}
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </Button>
                    </Tooltip>
                  </Layout.Col>
                  <Layout.Col xs="24" sm="1">
                    <Tooltip
                      className="item"
                      effect="dark"
                      content="Borrar datos de busqueda"
                      placement="top"
                    >
                      <Button
                        disabled={!props.roles.profiles.profile_see}
                        className="btn-delete"
                        type="primary"
                        icon="close"
                        onClick={() => cleanForm()}
                        style={{ marginTop: "23px" }}
                      ></Button>
                    </Tooltip>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row className="accumulated-graph">
            <Bar
              data={accumulated}
              options={options}
              height={500}
              ref={chartRef}
            />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  organizationId: state.auth.organizationId,
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(ConsumptionProfile);
