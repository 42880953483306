import { CHANGE_PASSWORD, LOG_IN, LOG_OUT, SET_ORGANIZATION } from "../auth/types";

const initialState = {
    isLoggedIn: false,
    needsPasswordChange: false,
    userToken: null,
    userId: null,
    userFullName: null,
    organizationId: null,
    roles: {
        codes: [],
        names: [],
        levels: [],
        profiles: {}
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                userToken: action.payload.userToken,
                userId: action.payload.userId,
                userFullName: action.payload.userFullName,
                needsPasswordChange: action.payload.needsPasswordChange,
                isLoggedIn: true,
                roles: action.payload.roles
            };

        case LOG_OUT:
            return {
                ...state,
                isLoggedIn: false,
                needsPasswordChange: false,
                userToken: null,
                userId: null,
                organizationId: null,
                roles: {
                    codes: [],
                    names: [],
                    levels: [],
                    profiles: {}
                }
            };

        case CHANGE_PASSWORD:
            return {
                ...state,
                needsPasswordChange: false,
            };

        case SET_ORGANIZATION:
            return {
                ...state,
                organizationId: action.payload.organizationId,
            };
        default:
            return state;
    }
}