import React, { useState, useEffect } from "react";
import "element-theme-default";
import { connect } from "react-redux";
import { Form, Button, Layout, Breadcrumb, Table, Input, Dialog, Card, Pagination } from "element-react";
import date from 'date-and-time';
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import ToolBar from "../../components/toolBar/ToolBar";
import { getPaginationRequestBin } from "./RequestBinRequest";

function RequestBin(props) {

	// Variables de Estado de Alimentación
	const [data, setData] = useState([]);

	// Varibles de Estado de la Interfaz
	const [stateToolBar, setStateToolBar] = useState(true);
	const showToolBar = () => {
		setStateToolBar(!stateToolBar);
	};
	const [loadingSearch, setLoading] = useState(false);
	const [dialogJsonVisible, setDialogJsonVisible] = useState(false);
	const [json, setJson] = useState("");
	const [pagination, setPagination] = useState({
		page: 0,
		pageSize: 10,
		total: 0
	});

	// Variables de Estado de Formulario de Busqueda
	const [queryData, setQueryData] = useState({
		"device_name": "",
		"eui": "",
	});

	const columns = [
		{
			label: "Aplicación",
			prop: "application_name",
		},
		{
			label: "Nombre dispositivo",
			prop: "device_name",
		},
		{
			label: "EUI",
			prop: "eui",
		},
		{
			label: "Trama larga",
			prop: "long_data",
			render: (data, column) => {
				if (data.long_data) {
					let personString = 'SI';
					return personString;
				} else if (!data.long_data) {
					let personString = 'NO';
					return personString;
				}
			}
		},
		{
			label: "Descripción",
			prop: "description",
		},
		{
			label: "Fecha",
			render: (data, column) => {
				if (data.created_at) {
					let newDate = new Date(data.created_at);
					let dateFormat = date.format(newDate, "YYYY-MM-DD HH:mm:ss");
					return dateFormat
				} else {
					return "-";
				}

			}
		},
		{
			label: "Acciones",
			prop: "actions",
			render: (item) => {
				return (<Button.Group>
					<Button disabled={!props.roles.profiles.profile_see} className="btn-edit" type="primary" icon="search" size="mini" onClick={() => showJson(item)} >Json</Button>
				</Button.Group>);

			}
		}
	];


	useEffect(async () => {
		var result = await getPaginationRequestBin(pagination);
		if (result) {
			pagination.total = result.count;
			setData(result.rows);
		}

	}, []);

	async function showJson(item) {
		let newItem = Object.assign({}, item);
		var jsonStr = JSON.stringify(newItem.request, null, 2);
		setJson(jsonStr);
		setDialogJsonVisible(true);
	}

	async function onCurrentChange(page) {
		pagination.page = page;
		searchJson();
	}

	function handleQueryChange(key, value) {
		let newQuery = { ...queryData };
		newQuery[key] = value;
		setQueryData(newQuery);
	}

	async function searchJson() {
		try {
			setLoading(true);
			var values = { ...queryData };
			values.page = pagination.page;
			values.pageSize = pagination.pageSize;
			var result = await getPaginationRequestBin(values);
			if (result) {
				pagination.total = result.count;
				setData(result.rows);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function cleanForm() {
		setQueryData({
			"device_name": "",
			"eui": "",
		})
	}

	async function listJson() {
		let values = {};
		values.page = 0;
		values.pageSize = 10;
		var result = await getPaginationRequestBin(values);
		if (result) {
			pagination.total = result.count;
			setData(result.rows);
		}
	}

	return (
		<div className="container">
			<NavBar showDataProfile={true} showToolBar={showToolBar} />
			<ToolBar stateToolBar={stateToolBar} active="6-6" />

			<Layout.Row>
				<Layout.Col xs="0" sm={stateToolBar ? 5 : 0}>
					<br />
				</Layout.Col>

				<Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">
					<Layout.Row style={{ padding: "20px" }}>
						<Breadcrumb separator="/">
							<Breadcrumb.Item>Request</Breadcrumb.Item>
						</Breadcrumb>
					</Layout.Row>

					{/* Formulario de busqueda */}
					<Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
						<Layout.Row>
							<Layout.Col span="22" offset="1">
								<Layout.Row gutter="10">
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="Nombre dispositivo">
											<Input value={queryData.device_name} onChange={(value) => handleQueryChange('device_name', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="5" xs="24">
										<Form.Item className="label-filter" label="EUI/ID Sigfox">
											<Input value={queryData.eui} onChange={(value) => handleQueryChange('eui', value)} type="text" />
										</Form.Item>
									</Layout.Col>
									<Layout.Col span="2" xs="24">
										<Button
											disabled={!props.roles.profiles.profile_see}
											className="btn-create"
											type="primary"
											icon="search"
											loading={loadingSearch}
											onClick={() => searchJson()}
											style={{ marginTop: '23px' }}>
											Buscar
										</Button>
									</Layout.Col>
									<Layout.Col span="2" xs="24">
										<Button
											disabled={!props.roles.profiles.profile_see}
											className="btn-create"
											type="primary"
											icon="close"
											onClick={() => { cleanForm(); listJson() }}
											style={{ marginTop: '23px' }}>
										</Button>
									</Layout.Col>
								</Layout.Row>
							</Layout.Col>
						</Layout.Row>
					</Form>

					<Layout.Row style={{ padding: "10px" }}>
						<Table
							style={{ width: "100%" }}
							columns={columns}
							data={data}
							stripe={true}
							height={500}
							highlightCurrentRow={true}
							emptyText="No se encontraron logs"
						/>
					</Layout.Row>
					<Layout.Row>
						<Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
					</Layout.Row>
					<Footer />
				</Layout.Col>
			</Layout.Row>

			{/* Modal Json */}
			<Dialog
				size="small"
				visible={dialogJsonVisible}
				onCancel={() => setDialogJsonVisible(false)}
				lockScroll={true}
				showClose={false}>
				<Dialog.Body>
					<Card className="box-card" style={{ overflow: "visible" }}>
						<div>
							<pre>
								{json}
							</pre>
						</div>
					</Card>
				</Dialog.Body>
				<Dialog.Footer className="dialog-footer">
					<Button onClick={() => setDialogJsonVisible(false)}>Cancelar</Button>
				</Dialog.Footer>
			</Dialog>
		</div>
	);
}

const mapStateToProps = (state) => ({
	organizationId: state.auth.organizationId,
	roles: state.auth.roles,
});

export default connect(mapStateToProps)(RequestBin);
