import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";

/*------------------------------------
********LISTAR SECTORES***************
-------------------------------------*/
export async function sectorListRequest(value) {
    try {
        let { data } = await axios.get(`/sector`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de sectores",
            type: 'error',
            customClass: "message-position"
        });
    }

}

/*------------------------------------
********LISTAR SECTORES POR CIUDAD***************
-------------------------------------*/
export async function sectorByCity(value) {
    try {
        let { data } = await axios.get(`/sectorByCity?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de sectores por ciudad",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********CREAR SECTOR******************
-------------------------------------*/
export async function sectorCreateRequest(value) {
    try {
        let { data } = await axios.post(`/sector`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el sector",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ACTUALIZAR SECTOR***************
-------------------------------------*/
export async function sectorUpdateRequest(value) {
    try {
        let { data } = await axios.put(`/sector`, { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el sector",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********LISTAR DEPARTAMENTOS***************
-------------------------------------*/
export async function departmentListRequest(value) {
    try {
        let { data } = await axios.get(`/departments`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de departamentos",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********LISTAR CIUDADES***************
-------------------------------------*/
export async function cityListRequest(value) {
    try {
        let { data } = await axios.get(`/cities`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de ciudades",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********LISTAR CIUDADES POR DEPARTAMENTO***************
-------------------------------------*/
export async function citiesByDepartment(value) {
    try {
        let { data } = await axios.get(`/cities?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el listado de ciudades por departamento",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR SECTOR***************
-------------------------------------*/
export async function sectorDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/sector?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el sector",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationSectorListRequest(value) {
    try {
        let { data } = await axios.get("/sector/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de sectores",
            type: 'error',
            customClass: "message-position"
        });
    }
}