import { Button, Card, Input, Layout, Message } from "element-react";
import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavBar from "../../../components/navBar/NavBar";
import { changePassword } from "../../../redux/auth/actions";
import logo_login from '../../../components/images/logo_login.svg';
import gota_login from '../../../components/images/gota_login.svg';
import "./ChangePasswordPage.css";
import Footer from "../../../components/footer/Footer";

/**
 * Página de Cambio de Contraseña
 * @param {*} props 
 */
function ChangePasswordPage(props) {

	const [password, setPassword] = useState(null);
	const [confirmation, setConfirmation] = useState(null);


	const [enableRedirect, setRedirect] = useState(false);

	async function submitPassword() {
		if (password === confirmation) {
			let result = await props.changePassword(password);
			if (result) {
				setRedirect(true);
			}
		} else {
			Message({
				type: 'error',
				showClose: true,
				message: "Las contraseñas no coinciden",
				customClass: "message-position"
			});
		}
	}


	return (
		<div className="container">
			<NavBar showDataProfile={false} />
			<Layout.Row type="flex" className="row-login-change-password" justify="center">
				<Card className="box-card-change-password">

					<Layout.Row>
						<img src={logo_login} className="logo_login-change-password" />
					</Layout.Row>

					<Layout.Row justify="center">
						<p style={{ fontSize: "25px", color: "#5e6d82" }} >Actualización de Contraseña</p>
					</Layout.Row>

					<Layout.Row style={{ marginBottom: "10px" }}>
						<span style={{ marginBottom: "10px", color: "#5e6d82" }}>Nueva contraseña:</span>
					</Layout.Row>

					<Layout.Row
						type="flex"
						className="row-bg"
						justify="center"
						style={{ marginBottom: "20px" }}
					>
						<Input
							className="login-inputs"
							value={password}
							type="password"
							name="password"
							onChange={(value) => {
								setPassword(value);
							}}
						/>
					</Layout.Row>

					<Layout.Row style={{ marginBottom: "10px" }}>
						<span style={{ marginBottom: "10px", color: "#5e6d82" }}>Confirmar nueva contraseña:</span>
					</Layout.Row>

					<Layout.Row
						type="flex"
						className="row-bg"
						justify="center"
						style={{ marginBottom: "20px" }}
					>
						<Input
							className="login-inputs"
							value={confirmation}
							type="password"
							name="confirm_password"
							onChange={(value) => {
								setConfirmation(value);
							}}
						/>
					</Layout.Row>

					<Layout.Row type="flex" className="row-bg" style={{ marginBottom: "20px" }}>
						<Button
							type="primary"
							className="btn-login-change-password"
							onClick={() => submitPassword()}
						>
							Confirmar <i className="fas fa-check" style={{ color: "white" }} ></i>
						</Button>
					</Layout.Row>
					<Layout.Row className="circle-change-password"></Layout.Row>
				</Card>
			</Layout.Row>

			<Layout.Row type="flex" className="row-login-change-password" justify="center" >
				<img src={gota_login} className="gota-login-change-password" />
			</Layout.Row>

			<Footer />

			{/* Redireccionar a la página inicial */}
			{(enableRedirect) ? <Redirect to="/home" push /> : null}
		</div >
	);
}


export default connect(null, { changePassword })(ChangePasswordPage);
