import React from "react";
import "element-theme-default";
import { i18n } from "element-react";
import locale from "element-react/src/locale/lang/es";
import LoginPage from "./pages/Auth/LoginPage/LoginPage";
import HomePage from "./pages/home/HomePage";
import Organization from "./pages/Organization/Organization";
import GatewayProfile from "./pages/Gateway/GatewayProfile";
import Device from "./pages/Device/Device";
import DeviceHistory from "./pages/Device/DeviceHistory/DeviceHistory";
import Sector from "./pages/Sector/Sector";
import BillCreate from "./pages/Bill/BillCreate/BillCreate";
import BillList from "./pages/Bill/BillList/BillList";
import BillDetail from "./pages/Bill/BillDetail/BillDetail";
import AlarmList from "./pages/Alarms/AlarmList/AlarmList";
import AlarmChart from "./pages/Alarms/AlarmChart/AlarmChart";
import UserGroups from "./pages/UserGroups/UserGroups";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Users from "./routes/users";
import Roles from "./pages/Roles/Roles";
import Notifications from "./pages/Notifications/Notifications";
import Profiles from "./pages/Profiles/Profiles";
import Logs from "./pages/Logs/Logs";
import ConsumptionProfile from "./pages/consumptionProfile/ConsumptionProfile";
import ChangePasswordPage from "./pages/Auth/ChangePassword/ChangePasswordPage";
import Holders from "./pages/Holders/Holders";
import Contract from "./pages/Contract/Contract";
import RequestBin from "./pages/RequestBin/RequestBin";
import VirtualMeter from "./pages/VirtualMeter/VirtualMeter";
import BillingCycle from "./pages/BillingCycle/BillingCycle";
import Trademark from "./pages/Trademark/Trademark";
import Model from "./pages/Model/Model";
import DeviceDetail from "./pages/Device/DeviceDetail/DeviceDetail";

function App(props) {
	i18n.use(locale);


	return (

		<Router>
		{/*	<div className="container"> */}
				<Switch>
					{/*RUTAS ORGANIZACION*/}
					<ProtectedRoute path="/organizacion" {...props}>
						<Organization />
					</ProtectedRoute>

					{/*RUTAS MARCA MEDIDORES*/}
					<ProtectedRoute path="/trademark" {...props}>
						<Trademark />
					</ProtectedRoute>

					{/*RUTAS MODELO MEDIDORES*/}
					<ProtectedRoute path="/model" {...props}>
						<Model />
					</ProtectedRoute>

					{/*RUTAS GATEWAY_PROFILE*/}
					<ProtectedRoute path="/perfil_gateway" {...props}>
						<GatewayProfile />
					</ProtectedRoute>

					{/*RUTAS DISPOSITIVOS*/}
					<ProtectedRoute path="/dispositivo" {...props}>
						<Device />
					</ProtectedRoute>

					<ProtectedRoute path="/historial_dispositivo" {...props}>
						<DeviceHistory />
					</ProtectedRoute>

					<ProtectedRoute path="/device_detail" {...props}>
						<DeviceDetail />
					</ProtectedRoute>

					{/*RUTAS MEDIDOR VIRTUAL*/}
					<ProtectedRoute path="/virtual_meter" {...props}>
						<VirtualMeter />
					</ProtectedRoute>

					{/*RUTAS PERFIL DE CONSUMO*/}
					<ProtectedRoute path="/consumption_profile" {...props}>
						<ConsumptionProfile />
					</ProtectedRoute>

					{/*RUTAS SECTOR*/}
					<ProtectedRoute path="/sector" {...props}>
						<Sector />
					</ProtectedRoute>

					{/*RUTAS GRUPOS DE USUARIOS*/}
					<ProtectedRoute path="/grupo_usuarios" {...props}>
						<UserGroups />
					</ProtectedRoute>

					{/*RUTAS FACTURACIÓN*/}
					<ProtectedRoute path="/holders" {...props}>
						<Holders />
					</ProtectedRoute>

					<ProtectedRoute path="/cycles" {...props}>
						<BillingCycle />
					</ProtectedRoute>

					<ProtectedRoute path="/contracts" {...props}>
						<Contract />
					</ProtectedRoute>

					<ProtectedRoute path="/factura/crear" {...props}>
						<BillCreate />
					</ProtectedRoute>

					<ProtectedRoute path="/factura/lista" {...props}>
						<BillList />
					</ProtectedRoute>

					<ProtectedRoute path="/factura/detalle"	{...props}>
						<BillDetail />
					</ProtectedRoute>

					{/*RUTAS ALARMAS*/}
					<ProtectedRoute path="/alarm/list" {...props}>
						<AlarmList />
					</ProtectedRoute>

					<ProtectedRoute path="/alarm/chart" {...props}>
						<AlarmChart />
					</ProtectedRoute>

					{/* RUTAS USUARIOS */}
					<ProtectedRoute path="/users" roleLevel={3} {...props}>
						<Users />
					</ProtectedRoute>

					{/* RUTAS ROLES */}
					<ProtectedRoute path="/roles" roleLevel={1} {...props}>
						<Roles />
					</ProtectedRoute>

					{/* RUTAS PERFILES */}
					<ProtectedRoute path="/profiles" roleLevel={1} {...props}>
						<Profiles />
					</ProtectedRoute>

					{/* RUTAS LOGS */}
					<ProtectedRoute path="/logs" roleLevel={2} {...props}>
						<Logs />
					</ProtectedRoute>

					{/* RUTAS REQUESTBIN */}
					<ProtectedRoute path="/request" roleLevel={1} {...props}>
						<RequestBin />
					</ProtectedRoute>

					{/* PÁGINA INICIAL */}
					<ProtectedRoute path="/home" {...props}>
						<HomePage />
					</ProtectedRoute>

					{/* CAMBIO DE CONTRASEÑA */}
					<ProtectedRoute path="/changepassword" {...props}>
						<ChangePasswordPage />
					</ProtectedRoute>

					{/*RUTAS NOTIFICACIONES*/}
					<ProtectedRoute path="/notification" {...props}>
						<Notifications />
					</ProtectedRoute>

					{/* LOG IN */}
					<Route path="/">
						{(props.isLoggedIn) ? <Redirect to={{ pathname: "/home", state: { from: '/' } }} /> : <LoginPage />}
					</Route>
				</Switch>
			{/*</div>*/}
		</Router>
	);
}

/**
 * Envuelve las rutas para redireccionar en caso de que no se haya autenticado,
 * necesite cambio de contraseña o no cuente con el nivel de rol necesario para una vista
 */
/*export function ProtectedRoute({ children, isLoggedIn, needsPasswordChange, roles, roleLevel, ...routeProps }) {
	return (
		<Route
			{...routeProps}
			render={({ location }) => {
				console.log("Rendering "  + isLoggedIn);
				if (!isLoggedIn) {

					// Enviar a la página de Log in
					return <Redirect to={{ pathname: "/", state: { from: location } }} />;
				} else if (needsPasswordChange && location.pathname != "/changepassword") {
					// Enviar a la página de cambio de contraseña
					return <Redirect to={{ pathname: "/changepassword", state: { from: location } }} />;
				} else if (roleLevel) {
					console.log("Redireccionamiento de rol");
					if (Math.min(roles.levels) <= roleLevel) {
						// Si cuenta con el rol necesario, permitir acceso a la página
						return children;
					} else {
						return <Redirect to={{ pathname: "/home", state: { from: location } }} />;
					}
				} else {
					// En cualquier otro caso permitir el acceso
					return children;
				}
			}}
		/>
	);
}*/

export function ProtectedRoute({ children, isLoggedIn, needsPasswordChange, roles, roleLevel, ...routeProps }) {
	return (
		<Route
			{...routeProps}
			//render={({ location }) => {
			render={(renderProps) => {
				if (!isLoggedIn) {
					// Enviar a la página de Log in
					return <Redirect to={{ pathname: "/", state: { from: renderProps.location } }} />;
				} else if (needsPasswordChange && renderProps.location.pathname != "/changepassword") {
					// Enviar a la página de cambio de contraseña
					return <Redirect to={{ pathname: "/changepassword", state: { from: renderProps.location } }} />;
				} else if (roleLevel) {
					console.log("Redireccionamiento de rol");
					if (Math.min(roles.levels) <= roleLevel) {
						// Si cuenta con el rol necesario, permitir acceso a la página
						//return children(renderProps);
						return React.cloneElement(children, { ...renderProps });
					} else {
						return <Redirect to={{ pathname: "/home", state: { from: renderProps.location } }} />;
					}
				} else {
					// En cualquier otro caso permitir el acceso
					return React.cloneElement(children, { ...renderProps });
				}
			}}
		/>
	);
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.auth.isLoggedIn,
	needsPasswordChange: state.auth.needsPasswordChange,
	roles: state.auth.roles,
});
export default connect(mapStateToProps)(App);
