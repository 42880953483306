import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Breadcrumb, Select, Input, Table, Button, MessageBox, Dialog, Switch } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import Footer from '../../components/footer/Footer';
import './Model.css';
import { modelCreateRequest, modelUpdateRequest, modelDeleteRequest, paginationModelListRequest } from './ModelRequest';
import { trademarkListRequest } from '../Trademark/TrademarkRequest';

function Model(props) {

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [trademarkList, setTrademarkList] = useState([]);

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [loadingSearch, setLoading] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createModel, setCreateModel] = useState({
    code: "",
    model: "",
    version: "",
    trademark_id: null,
    meter_type_id: null,
    technical_norm_id: null,
    flow_id: null,
    relationq_id: null,
    presicion_id: null,
    interval_id: null,
    is_pulse: false,
    is_valve: false,
    is_macro: false,
    description: ""
  });

  const [editModel, setEditModel] = useState({
    id: null,
    code: "",
    model: "",
    version: "",
    trademark_id: null,
    meter_type_id: null,
    technical_norm_id: null,
    flow_id: null,
    relationq_id: null,
    presicion_id: null,
    interval_id: null,
    is_pulse: false,
    is_valve: false,
    is_macro: false,
    description: ""
  });

  const [meterTypeList, setMeterTypeList] = useState([
    { meter_type_id: 1, name: 'Chorro múltiple' },
    { meter_type_id: 2, name: 'Chorro único' },
    { meter_type_id: 3, name: 'Electromagnético' },
    { meter_type_id: 4, name: 'Ultrasónico' },
    { meter_type_id: 5, name: 'volumétrico' },
    { meter_type_id: 6, name: 'Woltman' },
    { meter_type_id: 7, name: 'Medidor de Pulsos' },
  ]);
  const [technicalNormsList, setTechnicalNormsList] = useState([
    { technical_norm_id: 1, name: 'NTC 1063-1:2007' },
    { technical_norm_id: 2, name: 'NTC ISO 4064-1:2016' },
  ]);
  const [flowList, setFlowList] = useState([
    { flow_id: 1, value: '1' },
    { flow_id: 2, value: '1.6' },
    { flow_id: 3, value: '2.5' },
    { flow_id: 4, value: '4' },
    { flow_id: 5, value: '6.3' },
    { flow_id: 6, value: '10' },
    { flow_id: 7, value: '16' },
    { flow_id: 8, value: '25' },
    { flow_id: 9, value: '40' },
    { flow_id: 10, value: '63' },
    { flow_id: 11, value: '100' },
    { flow_id: 12, value: '160' },
    { flow_id: 13, value: '250' },
    { flow_id: 14, value: '400' },
    { flow_id: 15, value: '630' },
    { flow_id: 16, value: '1000' },
    { flow_id: 17, value: '1600' },
    { flow_id: 18, value: '2500' },
    { flow_id: 19, value: '4000' },
    { flow_id: 20, value: '6300' },
  ]);

  const [relationQList, setRelationQList] = useState([
    { relationq_id: 1, value: '40' },
    { relationq_id: 2, value: '50' },
    { relationq_id: 3, value: '63' },
    { relationq_id: 4, value: '80' },
    { relationq_id: 5, value: '100' },
    { relationq_id: 6, value: '125' },
    { relationq_id: 7, value: '160' },
    { relationq_id: 8, value: '200' },
    { relationq_id: 9, value: '250' },
    { relationq_id: 10, value: '315' },
    { relationq_id: 11, value: '400' },
    { relationq_id: 12, value: '500' },
    { relationq_id: 13, value: '630' },
    { relationq_id: 14, value: '800' },
    { relationq_id: 15, value: '1000' },
    { relationq_id: 16, value: '1250' },
    { relationq_id: 17, value: '1600' },
    { relationq_id: 18, value: '2000' },
  ]);

  const [presicionClassList, setPresicionClassList] = useState([
    { presicion_id: 1, name: 'CP 1' },
    { presicion_id: 2, name: 'CP 2' },
  ]);

  const [intervalList, setIntervalList] = useState([
    { interval_id: 1, value: '9 999' },
    { interval_id: 2, value: '99 999' },
    { interval_id: 1, value: '999 999' },
    { interval_id: 2, value: '9 999 999' },
  ]);

  var columns = [
    {
      label: "Código",
      prop: "code",
    },
    {
      label: "Modelo",
      prop: "model",
    },
    {
      label: "Versión",
      prop: "version",
    },
    {
      label: "Marca",
      prop: "trademark.name",
    },
    {
      label: "Tipo",
      prop: "meter_type_id",
      render: (data, column) => {
        let found = meterTypeList.find(element => element.meter_type_id === data.meter_type_id);
        return (found.name) ? found.name : 'Sin dato';
      }
    },
    {
      label: "Norma",
      prop: "technical_norm_id",
      render: (data, column) => {
        let found = technicalNormsList.find(element => element.technical_norm_id === data.technical_norm_id);
        return (found.name) ? found.name : 'Sin dato';
      }
    },
    {
      label: "Caudal",
      prop: "flow_id",
      render: (data, column) => {
        let found = flowList.find(element => element.flow_id === data.flow_id);
        return (found.value) ? found.value : 'Sin dato';
      }
    },
    {
      label: "Relación",
      prop: "relationq_id",
      render: (data, column) => {
        let found = relationQList.find(element => element.relationq_id === data.relationq_id);
        return (found.value) ? found.value : 'Sin dato';
      }
    },
    {
      label: "Presición",
      prop: "presicion_id",
      render: (data, column) => {
        let found = presicionClassList.find(element => element.presicion_id === data.presicion_id);
        return (found.name) ? found.name : 'Sin dato';
      }
    },
    {
      label: "Intervalo",
      prop: "interval_id",
      render: (data, column) => {
        let found = intervalList.find(element => element.interval_id === data.interval_id);
        return (found.value) ? found.value : 'Sin dato';
      }
    },
    /*{
      label: "Descripción",
      prop: "description",
      render: (data, column) => {
        return ((data['description'] == null || data['description'] == null) ? "Sin Descripción" : data['description'])
      }
    },*/
    {
      label: "Acciones",
      prop: "actions",
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => modelDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    model: "",
  });

  useEffect(async () => {

    var result = await paginationModelListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var result = await trademarkListRequest();
    if (result) {
      setTrademarkList(result);
    }

  }, []);

  function onChangeCreate(key, value) {
    let newValues = { ...createModel };
    newValues[key] = value;
    setCreateModel(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editModel };
    newValues[key] = value;
    setEditModel(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function copyDataUpdate(item) {
    setEditModel(item);
    setDialogEditVisible(true);
  }

  async function modelCreateApi() {
    let values = createModel;
    var result = await modelCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationModelListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function modelUpdateApi(value) {
    var values = editModel;
    var result = await modelUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationModelListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function modelDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar esta marca de medidor?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await modelDeleteRequest(id);
      if (result) {
        var result = await paginationModelListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false)
    setDialogEditVisible(false)
    setCreateModel({
      code: "",
      model: "",
      version: "",
      trademark_id: null,
      meter_type_id: null,
      technical_norm_id: null,
      flow_id: null,
      relationq_id: null,
      presicion_id: null,
      interval_id: null,
      is_pulse: false,
      is_valve: false,
      is_macro: false,
      description: ""
    });
    setEditModel({
      code: "",
      id: null,
      model: "",
      version: "",
      trademark_id: null,
      meter_type_id: null,
      technical_norm_id: null,
      flow_id: null,
      relationq_id: null,
      presicion_id: null,
      interval_id: null,
      is_pulse: false,
      is_valve: false,
      is_macro: false,
      description: ""
    });
    setQueryData({
      name: "",
    });
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchModel();
  }

  async function searchModel() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;
      var result = await paginationModelListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listModel() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationModelListRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="4-5" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Configuración</Breadcrumb.Item>
              <Breadcrumb.Item>Modelo Medidor</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Modelo</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="10" xs="24">
                    <Form.Item className="label-filter" label="Modelo">
                      <Input value={queryData.model} onChange={(value) => handleQueryChange('model', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="10" xs="24">
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchModel()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listModel() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron modelos de dispositivos" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      <Dialog
        size="small"
        title="CREAR MODELO"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createModel} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Modelo">
                  <Input className="input-style" placeholder="Ingrese un modelo" value={createModel.model} onChange={(value) => onChangeCreate('model', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Versión">
                  <Input className="input-style" placeholder="Ingrese una versión" value={createModel.version} onChange={(value) => onChangeCreate('version', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Marca">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    placeholder="Marca"
                    value={createModel.trademark_id}
                    onChange={(value) => {
                      onChangeCreate("trademark_id", value);
                    }}>
                    {trademarkList.map((item) => (
                      <Select.Option key={item.name + '_' + item.id} label={`${item.name}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Tipo de medidor">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    placeholder="Tipo de medidor"
                    value={createModel.meter_type_id}
                    onChange={(value) => {
                      onChangeCreate("meter_type_id", value);
                    }}>
                    {meterTypeList.map((item) => (
                      <Select.Option key={item.name + '_' + item.meter_type_id} label={`${item.name}`} value={item.meter_type_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Norma técnica">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Norma"
                    filterable={true}
                    clearable={true}
                    value={createModel.technical_norm_id}
                    onChange={(value) => {
                      onChangeCreate("technical_norm_id", value);
                    }}>
                    {technicalNormsList.map((item) => (
                      <Select.Option key={item.technical_norm_id + '_' + item.name} label={`${item.name}`} value={item.technical_norm_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Caudal permantente Q3 m³/h">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Caudal"
                    filterable={true}
                    clearable={true}
                    value={createModel.flow_id}
                    onChange={(value) => {
                      onChangeCreate("flow_id", value);
                    }}>
                    {flowList.map((item) => (
                      <Select.Option key={item.flow_id + '_' + item.value} label={`${item.value}`} value={item.flow_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Relación Q3/Q1 “R” ">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Relación"
                    filterable={true}
                    clearable={true}
                    value={createModel.relationq_id}
                    onChange={(value) => {
                      onChangeCreate("relationq_id", value);
                    }}>
                    {relationQList.map((item) => (
                      <Select.Option key={item.relationq_id + '_' + item.value} label={`${item.value}`} value={item.relationq_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Clase de presición">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Presición"
                    filterable={true}
                    clearable={true}
                    value={createModel.presicion_id}
                    onChange={(value) => {
                      onChangeCreate("presicion_id", value);
                    }}>
                    {presicionClassList.map((item) => (
                      <Select.Option key={item.presicion_id + '_' + item.name} label={`${item.name}`} value={item.presicion_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Intervalo de indicación m³">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Intervalo"
                    filterable={true}
                    clearable={true}
                    value={createModel.interval_id}
                    onChange={(value) => {
                      onChangeCreate("interval_id", value);
                    }}>
                    {intervalList.map((item) => (
                      <Select.Option key={item.interval_id + '_' + item.value} label={`${item.value}`} value={item.interval_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Código">
                  <Input className="input-style" placeholder="Ingrese un código" value={createModel.code} onChange={(value) => onChangeCreate('code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Pulsos?">
                  <Switch value={createModel.is_pulse} onText="Si" offText="No" onChange={(value) => onChangeCreate('is_pulse', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Válvula?">
                  <Switch value={createModel.is_valve} onText="Si" offText="No" onChange={(value) => onChangeCreate('is_valve', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Macro?">
                  <Switch value={createModel.is_macro} onText="Si" offText="No" onChange={(value) => onChangeCreate('is_macro', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Descripción">
              <Input className="text-area-style" placeholder="Ingrese una descripción" type="textarea" value={createModel.description} onChange={(value) => onChangeCreate('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => modelCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog
        size="small"
        title="EDITAR MODELO"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editModel} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              {/* <Layout.Col xs="24" sm="6">
                <Form.Item label="Código">
                  <Input className="input-style" value={editModel.id} disabled></Input>
                </Form.Item>
              </Layout.Col> */}
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Modelo">
                  <Input className="input-style" placeholder="Ingrese un modelo" value={editModel.model} onChange={(value) => onChangeEdit('model', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Versión">
                  <Input className="input-style" placeholder="Ingrese una versión" value={editModel.version} onChange={(value) => onChangeEdit('version', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Marca">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    placeholder="Marca"
                    value={editModel.trademark_id}
                    onChange={(value) => {
                      onChangeEdit("trademark_id", value);
                    }}>
                    {trademarkList.map((item) => (
                      <Select.Option key={item.name + '_' + item.id} label={`${item.name}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Tipo de medidor">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    filterable={true}
                    clearable={true}
                    placeholder="Tipo de medidor"
                    value={editModel.meter_type_id}
                    onChange={(value) => {
                      onChangeEdit("meter_type_id", value);
                    }}>
                    {meterTypeList.map((item) => (
                      <Select.Option key={item.name + '_' + item.meter_type_id} label={`${item.name}`} value={item.meter_type_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Norma técnica">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Norma"
                    filterable={true}
                    clearable={true}
                    value={editModel.technical_norm_id}
                    onChange={(value) => {
                      onChangeEdit("technical_norm_id", value);
                    }}>
                    {technicalNormsList.map((item) => (
                      <Select.Option key={item.technical_norm_id + '_' + item.name} label={`${item.name}`} value={item.technical_norm_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Caudal permantente Q3 m³/h">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Caudal"
                    filterable={true}
                    clearable={true}
                    value={editModel.flow_id}
                    onChange={(value) => {
                      onChangeEdit("flow_id", value);
                    }}>
                    {flowList.map((item) => (
                      <Select.Option key={item.flow_id + '_' + item.value} label={`${item.value}`} value={item.flow_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Relación Q3/Q1 “R” ">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Relación"
                    filterable={true}
                    clearable={true}
                    value={editModel.relationq_id}
                    onChange={(value) => {
                      onChangeEdit("relationq_id", value);
                    }}>
                    {relationQList.map((item) => (
                      <Select.Option key={item.relationq_id + '_' + item.value} label={`${item.value}`} value={item.relationq_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Clase de presición">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Presición"
                    filterable={true}
                    clearable={true}
                    value={editModel.presicion_id}
                    onChange={(value) => {
                      onChangeEdit("presicion_id", value);
                    }}>
                    {presicionClassList.map((item) => (
                      <Select.Option key={item.presicion_id + '_' + item.name} label={`${item.name}`} value={item.presicion_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Intervalo de indicación m³">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Intervalo"
                    filterable={true}
                    clearable={true}
                    value={editModel.interval_id}
                    onChange={(value) => {
                      onChangeEdit("interval_id", value);
                    }}>
                    {intervalList.map((item) => (
                      <Select.Option key={item.interval_id + '_' + item.value} label={`${item.value}`} value={item.interval_id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Código">
                  <Input className="input-style" placeholder="Ingrese un código" value={editModel.code} onChange={(value) => onChangeEdit('code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Pulsos?">
                  <Switch value={editModel.is_pulse} onText="Si" offText="No" onChange={(value) => onChangeEdit('is_pulse', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Válvula?">
                  <Switch value={editModel.is_valve} onText="Si" offText="No" onChange={(value) => onChangeEdit('is_valve', value)}></Switch>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="6">
                <Form.Item label="Macro?">
                  <Switch value={editModel.is_macro} onText="Si" offText="No" onChange={(value) => onChangeEdit('is_macro', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item label="Descripción">
              <Input className="text-area-style" type="textarea" placeholder="Ingrese una descripción" value={editModel.description} onChange={(value) => onChangeEdit('description', value)}></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => modelUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div>
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Model);
