import axios from "../../../utilities/axiosInstance";

/*------------------------------------
********CREAR FACTURA*****************
-------------------------------------*/
export async function billCreateRequest(value) {
	try {
		let response = await axios.post("/bill", { data: value });

        return response.data;
	} catch (error) {
		console.log(error);
		return {
			data: null,
			error: true,
			message: "Hubo un problema al crear la facturación",
		};
	}
}
