import { Message } from "element-react";
import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";

export async function getTreeTotalValues(values) {
    try {
        let { data } = await axios.get('/getTreeTotalValues');

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        }
    } catch (error) {
        console.error(error);
        Message({
            showClose: true,
            message: "Hubo un error al obtener los totales del arbol",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********OBTENER GRAFICO DE ALARMAS*******
-------------------------------------*/
export async function getChartAlarmByStatus(value) {
    try {
        let { data } = await axios.get("/chartAlarmByStatus", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el frafico de alarmas",
            type: 'error',
            customClass: "message-position"
        });
    }
}

/*------------------------------------
********OBTENER GRAFICO DE CONSUMO HORARIO*******
-------------------------------------*/
export async function getChartConsumptionByHour(value) {
    try {
        let { data } = await axios.get("/chartConsumptionByHour", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener el gráfico de consumo por dispositivo",
            type: 'error',
            customClass: "message-position"
        });
    }
}

