import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { connect } from "react-redux";
import { Pagination, Layout, Form, Card, Breadcrumb, Select, Input, Table, Button, MessageBox, Dialog, Alert, Switch } from 'element-react';
import NavBar from '../../components/navBar/NavBar';
import ToolBar from '../../components/toolBar/ToolBar';
import './Holders.css';
import { paginationHolderListRequest, getAgreementList, getIdentificationTypeList, getObligationList, getEconomicActivitiesList, holderCreateRequest, holderUpdateRequest, holderDeleteRequest } from './HoldersRequest';
import { departmentListRequest, citiesByDepartment, } from '../Sector/SectorRequest';
import Footer from '../../components/footer/Footer';

function Holder(props) {  

  // Variables de Estado de Alimentación
  const [data, setData] = useState([]);
  const [agreementList, setAgreementList] = useState([]);
  const [identificationTypeList, setIdentificationTypeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [obligationList, setObligationList] = useState([]);
  const [economicActivitiesList, setEconomicActivitiesList] = useState([]);

  // Variables de Estado de Formulario
  const [personList, setPersonList] = useState([{ id: 0, type: 'NATURAL' }, { id: 1, type: 'JURIDICA' }]);
  const [roadList, setRoadList] = useState(["Anillo", "Autopista", "Avenida", "Calle", "Carrera", "Circular", "Diagonal", "Transversal", "Kilometro"]);
  const [propertyType, setPropertyType] = useState(["Apartamento", "Bloque", "Casa", "Oficina"]);
  const [dv, setDV] = useState(null);
  const [economicActivity, setEconomicActivity] = useState("");

  // Varibles de Estado de la Interfaz
  const [stateToolBar, setStateToolBar] = useState(true);
  const showToolBar = () => { setStateToolBar(!stateToolBar) }
  const [loadingSearch, setLoading] = useState(false);
  const [haveErrors, setHaveErrors] = useState(false);
  const [textErrors, setTextErrors] = useState("");
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [dialogCreateVisible, setDialogCreateVisible] = useState(false);
  const [dialogAddressVisible, setDialogAddressVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0
  });

  const [createHolder, setCreateHolder] = useState({
    person_id: null,
    agreement_id: null,
    organization_id: null,
    economic_activity_id: null,
    economic_activity: '',
    identification_type_id: null,
    identification: null,
    first_name: '',
    second_name: '',
    first_surname: '',
    second_surname: '',
    department_id: null,
    city_id: null,
    address: '',
    postal_code: null,
    contact: '',
    email: '',
    phone: '',
    optional_email: '',
    withhold: false,
    obligations: [],
  });

  const [editHolder, setEditHolder] = useState({
    person_id: null,
    agreement_id: null,
    organization_id: null,
    economic_activity_id: null,
    economic_activity: '',
    identification_type_id: null,
    identification: null,
    first_name: '',
    second_name: '',
    first_surname: '',
    second_surname: '',
    department_id: null,
    city_id: null,
    address: '',
    postal_code: null,
    contact: '',
    email: '',
    phone: '',
    optional_email: '',
    withhold: false,
    obligations: [],
    arrayObligations: [],
  });

  const [createAddress, setCreateAddress] = useState({
    road: "",
    propertyType: "",
    number1: "",
    number2: "",
    number3: "",
    number4: "",
    apto: "",
  });

  var columns = [
    {
      label: "Titular",
      width: 300,
      render: (data, column) => {
        var statusString = `${data.first_name} ${data.second_name} ${data.first_surname} ${data.second_surname}`;
        return statusString;
      },
    },
    {
      label: "Tipo Persona",
      prop: "person_id",
      width: 150,
      render: (data, column) => {
        if (data.person_id == 0) {
          let personString = 'NATURAL';
          return personString;
        } else if (data.person_id) {
          let personString = 'JURIDICA';
          return personString;
        }
      }
    },
    {
      label: "Tipo ID",
      prop: "identification_type.abbreviation",
      width: 150,
    },
    {
      label: "ID",
      prop: "identification",
      width: 150,
    },
    {
      label: "Telefono",
      prop: "phone",
      width: 150,
    },
    {
      label: "Email",
      prop: "email",
      width: 300,
    },
    {
      label: "Acciones",
      prop: "actions",
      width: 150,
      render: (item) => {
        return (<Button.Group>
          <Button disabled={!props.roles.profiles.profile_edit} className="btn-edit" type="primary" icon="edit" size="mini" onClick={() => copyDataUpdate(item)} ></Button>
          <Button disabled={!props.roles.profiles.profile_delete} className="btn-delete" type="primary" icon="delete" size="mini" onClick={() => holderDeleteApi(item.id)} ></Button>
        </Button.Group>);

      }
    }
  ];

  // Variables de Estado de Formulario de Busqueda
  const [queryData, setQueryData] = useState({
    identification: "",
    first_name: "",
    second_name: "",
    first_surname: "",
    second_surname: "",
  });

  useEffect(async () => {
    var result = await paginationHolderListRequest(pagination);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }

    var agreement = await getAgreementList();
    if (agreement) {
      setAgreementList(agreement);
    }

    var economicActivities = await getEconomicActivitiesList();
    if (economicActivities) {
      setEconomicActivitiesList(economicActivities);
    }

    var departments = await departmentListRequest();
    if (departments) {
      setDepartmentList(departments);
    }

    var obligations = await getObligationList();
    if (obligations) {
      setObligationList(obligations);
    }

  }, []);

  async function listCities(value) {
    var result = await citiesByDepartment(value);
    if (result) {
      setCityList(result);
    }
  }

  async function listIdentificationType(value) {
    var identificationType = await getIdentificationTypeList(value);
    if (identificationType) {
      setIdentificationTypeList(identificationType);
    }
  }

  function onChangeAddres(key, value) {
    let newValues = { ...createAddress };
    newValues[key] = value;
    setCreateAddress(newValues);
  }

  function saveAddress() {
    if (validation()) {
      createHolder.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      editHolder.address =
        createAddress.road + " " +
        createAddress.number1 + " # " +
        createAddress.number2 + " - " +
        createAddress.number3 + " " +
        createAddress.propertyType + " " +
        createAddress.number4 + " " +
        createAddress.apto

      cleanFormAdres();
    }
  }

  function validation() {
    createAddress.number1 = createAddress.number1.replace(/ /g, "");
    createAddress.number2 = createAddress.number2.replace(/ /g, "");
    createAddress.number3 = createAddress.number3.replace(/ /g, "");
    createAddress.propertyType = createAddress.propertyType.replace(/ /g, "");
    createAddress.number4 = createAddress.number4.replace(/ /g, "");
    createAddress.apto = createAddress.apto.replace(/ /g, "");

    if (createAddress.road.length == 0) {
      setTextErrors("La via no puede estar vacía.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number1.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number2.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else if (createAddress.number3.length == 0) {
      setTextErrors("El número no puede estar vacío.");
      setHaveErrors(true);
      return false;
    } else {
      setTextErrors("");
      setHaveErrors(false);
      return true;

    }
  }

  function copyAdresToUpdate(address) {
    if (address) {
      let stringAddress = address
      let arrrayAddress = stringAddress.split(' ')

      setCreateAddress({
        road: arrrayAddress[0],
        number1: arrrayAddress[1],
        number2: arrrayAddress[3],
        number3: arrrayAddress[5],
        propertyType: arrrayAddress[6],
        number4: arrrayAddress[7],
        apto: arrrayAddress[8],
      });
    }

    setDialogAddressVisible(true);
  }

  function cleanFormAdres() {
    setCreateAddress({
      road: "",
      propertyType: "",
      number1: "",
      number2: "",
      number3: "",
      number4: "",
      apto: "",
    });

    setDialogAddressVisible(false)
  }

  function onChangeCreate(key, value) {
    let newValues = { ...createHolder };
    newValues[key] = value;
    setCreateHolder(newValues);
  }

  function onChangeEdit(key, value) {
    let newValues = { ...editHolder };
    newValues[key] = value;
    setEditHolder(newValues);
  }

  function handleQueryChange(key, value) {
    let newQuery = { ...queryData };
    newQuery[key] = value;
    setQueryData(newQuery);
  }

  async function holderCreateApi() {
    let values = createHolder;
    var result = await holderCreateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationHolderListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function copyDataUpdate(item) {
    let newItem = Object.assign({}, item);
    newItem.arrayObligations = [];
    item.obligations.forEach((obligation) => {
      newItem.arrayObligations.push(obligation.id);
    });
    listIdentificationType(newItem.person_id);
    listCities(newItem.department_id);
    verificationCode(item.identification, item.person_id);
    filter(item.economic_activity_id);
    setEditHolder(newItem);
    setDialogEditVisible(true);

  }

  async function holderUpdateApi(value) {
    var values = editHolder;
    var result = await holderUpdateRequest(values);
    if (result) {
      cleanForm();
      var result = await paginationHolderListRequest(pagination);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
    }
  }

  async function holderDeleteApi(id) {
    MessageBox.confirm('Está seguro que desea borrar este titular?', 'Advertencia', {
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      type: 'warning'
    }).then(async () => {
      var result = await holderDeleteRequest(id);
      if (result) {
        var result = await paginationHolderListRequest(pagination);
        if (result) {
          pagination.total = result.count;
          setData(result.rows);
        }
      } else {
        setTextErrors(result.message);
        setHaveErrors(true);
      }
    }).catch(() => {
    });
  }

  function cleanForm() {
    setDialogCreateVisible(false);
    setDialogEditVisible(false);
    setDV(null);
    setEconomicActivity("");
    setCreateHolder({
      person_id: null,
      agreement_id: null,
      organization_id: null,
      economic_activity_id: null,
      economic_activity: '',
      identification_type_id: null,
      identification: null,
      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      department_id: null,
      city_id: null,
      address: '',
      postal_code: null,
      contact: '',
      email: '',
      phone: '',
      optional_email: '',
      withhold: false,
      obligations: [],
    });
    setEditHolder({
      person_id: null,
      agreement_id: null,
      organization_id: null,
      economic_activity_id: null,
      economic_activity: '',
      identification_type_id: null,
      identification: null,
      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      department_id: null,
      city_id: null,
      address: '',
      postal_code: null,
      contact: '',
      email: '',
      phone: '',
      optional_email: '',
      withhold: false,
      obligations: [],
      arrayObligations: [],
    });
    setQueryData({
      identification: "",
      first_name: "",
      second_name: "",
      first_surname: "",
      second_surname: "",
    });
  }

  //calcula el digito de verificación de la Dian
  function verificationCode(nit, person_id) {
    if (nit.length <= 9) {
      if (createHolder.person_id == 1 || editHolder.person_id == 1 || person_id == 1) {
        var vpri, x, y, z;
        vpri = new Array(16);
        z = nit.length;

        vpri[1] = 3;
        vpri[2] = 7;
        vpri[3] = 13;
        vpri[4] = 17;
        vpri[5] = 19;
        vpri[6] = 23;
        vpri[7] = 29;
        vpri[8] = 37;
        vpri[9] = 41;
        vpri[10] = 43;
        vpri[11] = 47;
        vpri[12] = 53;
        vpri[13] = 59;
        vpri[14] = 67;
        vpri[15] = 71;

        x = 0;
        y = 0;
        for (var i = 0; i < z; i++) {
          y = (nit.substr(i, 1));
          x += (y * vpri[z - i]);
        }

        y = x % 11;
        let dv = (y > 1) ? 11 - y : y;

        setDV(dv);
        setTextErrors("");
        setHaveErrors(false);
      }
    } else {
      setTextErrors("El Nit no puede tener mas de 9 números");
      setHaveErrors(true);
    }
  }

  function filter(id) {
    const result = economicActivitiesList.filter(activity => activity.id == id);
    if(result[0] && result[0].description){
      setEconomicActivity(result[0].description);
    }
  }

  async function onCurrentChange(page) {
    pagination.page = page;
    searchHolder();
  }

  async function searchHolder() {
    try {
      setLoading(true);
      var values = { ...queryData };
      var pagination_values = { ...pagination };
      values.page = pagination_values.page;
      values.pageSize = pagination_values.pageSize;

      var result = await paginationHolderListRequest(values);
      if (result) {
        pagination.total = result.count;
        setData(result.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function listHolders() {
    let values = {};
    values.page = 0;
    values.pageSize = 10;
    var result = await paginationHolderListRequest(values);
    if (result) {
      pagination.total = result.count;
      setData(result.rows);
    }
  }

  return (
    <div className="container">

      <NavBar showDataProfile={true} showToolBar={showToolBar} />
      <ToolBar stateToolBar={stateToolBar} active="3-3" />

      <Layout.Row>
        <Layout.Col xs="0" sm={stateToolBar ? 5 : 0}><br /></Layout.Col>

        <Layout.Col xs="24" sm={stateToolBar ? 19 : 24} className="toolbar-margins">

          <Layout.Row style={{ padding: "20px" }}>
            <Breadcrumb separator="/">
              <Breadcrumb.Item>Facturación</Breadcrumb.Item>
              <Breadcrumb.Item>Titulares</Breadcrumb.Item>
            </Breadcrumb>
          </Layout.Row>

          <Layout.Row style={{ padding: "20px" }}>
            <Layout.Col xs="24" sm="6">
              <Button disabled={!props.roles.profiles.profile_create} className="btn-create" type="primary" onClick={() => setDialogCreateVisible(true)}>Crear Nuevo Titular</Button>
            </Layout.Col>
          </Layout.Row>

          {/* Formulario de busqueda */}
          <Form className="es-CO" model={queryData} labelWidth="120" labelPosition="top">
            <Layout.Row>
              <Layout.Col span="22" offset="1">
                <Layout.Row gutter="10">
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Identificación">
                      <Input value={queryData.identification} onChange={(value) => handleQueryChange('identification', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Razón S/ Primer Nombre">
                      <Input value={queryData.first_name} onChange={(value) => handleQueryChange('first_name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Segundo Nombre">
                      <Input value={queryData.second_name} onChange={(value) => handleQueryChange('second_name', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Primer Apellido">
                      <Input value={queryData.first_surname} onChange={(value) => handleQueryChange('first_surname', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="4" xs="24">
                    <Form.Item className="label-filter" label="Segundo Apellido">
                      <Input value={queryData.second_surname} onChange={(value) => handleQueryChange('second_surname', value)} type="text" />
                    </Form.Item>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-create"
                      type="primary"
                      icon="search"
                      loading={loadingSearch}
                      onClick={() => searchHolder()}
                      style={{ marginTop: '23px' }}>
                      Buscar
                    </Button>
                  </Layout.Col>
                  <Layout.Col span="2" xs="24">
                    <Button
                      disabled={!props.roles.profiles.profile_see}
                      className="btn-delete"
                      type="primary"
                      icon="close"
                      onClick={() => { cleanForm(); listHolders() }}
                      style={{ marginTop: '23px' }}>
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Layout.Col>
            </Layout.Row>
          </Form>

          <Layout.Row style={{ padding: "20px" }}>

            <Table
              style={{ width: '100%' }}
              columns={columns}
              data={data}
              stripe={true}
              highlightCurrentRow={true}
              height={400}
              emptyText="No se encontraron titulares" />

          </Layout.Row>

          <Layout.Row>
            <Pagination style={{ float: "right" }} layout="total, prev, pager, next" total={pagination.total} pageSize={pagination.pageSize} onCurrentChange={(page) => onCurrentChange(page - 1)} />
          </Layout.Row>
          <Footer />
        </Layout.Col>
      </Layout.Row>

      {/* Modal crear */}
      <Dialog
        title="CREAR TITULAR"
        size="small"
        visible={dialogCreateVisible}
        onCancel={() => setDialogCreateVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={createHolder} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="4">
                <Form.Item label="Persona">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione persona"
                    value={createHolder.person_id}
                    onChange={(value) => {
                      listIdentificationType(value);
                      onChangeCreate("person_id", value);
                    }}>
                    {personList.map((item) => (
                      <Select.Option key={item.id} label={item.type} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Convenio">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Convenio"
                    value={createHolder.agreement_id}
                    onChange={(value) => {
                      onChangeCreate("agreement_id", value);
                    }}>
                    {agreementList.map((item) => (
                      <Select.Option key={item.id} label={`${item.code} . ${item.name}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Cod A.E.">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione Cod A.E."
                    value={createHolder.economic_activity_id}
                    onChange={(value) => {
                      filter(value)
                      onChangeCreate("economic_activity_id", value);
                    }}>
                    {economicActivitiesList.map((item) => (
                      <Select.Option key={item.id} label={item.code} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Actividad Económica">
                  <Input className="input-style" placeholder="Actividad económica" value={economicActivity}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Tipo ID">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione Tipo ID"
                    value={createHolder.identification_type_id}
                    onChange={(value) => {
                      onChangeCreate("identification_type_id", value);
                    }}>
                    {identificationTypeList.map((item) => (
                      <Select.Option key={item.id} label={`(${item.abbreviation}) ${item.description}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              {(createHolder.identification_type_id == 6 && createHolder.person_id == 1) ? (
                <Layout.Col xs="24" sm="9">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" type="number" value={createHolder.identification} onChange={(value) => {
                      verificationCode(value, null);
                      onChangeCreate("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              ) : (
                <Layout.Col xs="24" sm="9">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" maxLength={10} value={createHolder.identification} onChange={(value) => {
                      onChangeCreate("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              )}

              <Layout.Col xs="24" sm="3">
                <Form.Item label="D.V">
                  <Input className="input-style" placeholder="D.V" value={dv} readOnly></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>

            {(createHolder.person_id == 0 || createHolder.person_id == null) ? (
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Razón S/ Primer Nombre">
                    <Input className="input-style" placeholder="Ingrese razón s/ primer nombre" value={createHolder.first_name} onChange={(value) => onChangeCreate('first_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Segundo Nombre (Otros)">
                    <Input className="input-style" placeholder="Ingrese segundo nombre (Otros)" value={createHolder.second_name} onChange={(value) => onChangeCreate('second_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Primer Apellido">
                    <Input className="input-style" placeholder="Ingrese primer apellido" value={createHolder.first_surname} onChange={(value) => onChangeCreate('first_surname', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Segundo Apellido">
                    <Input className="input-style" placeholder="Ingrese segundo pellido" value={createHolder.second_surname} onChange={(value) => onChangeCreate('second_surname', value)}></Input>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            ) : (
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="24">
                  <Form.Item label="Razón Social">
                    <Input className="input-style" placeholder="Ingrese razón social" value={createHolder.first_name} onChange={(value) => onChangeCreate('first_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            )}
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Departamento">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione un departamento"
                    value={createHolder.department_id}
                    onChange={(value) => {
                      listCities(value);
                      onChangeCreate("department_id", value);
                    }}>
                    {departmentList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Ciudad">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione una ciudad"
                    value={createHolder.city_id}
                    onChange={(value) => {
                      onChangeCreate("city_id", value);
                    }}>
                    {cityList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Dirección">
                  <Input className="input-style" onChange={(value) => { onChangeCreate("address", value) }} value={createHolder.address} />
                </Form.Item>
              </Layout.Col>
              {/* <Layout.Col xs="24" sm="2">
                <Button className="btn-create" type="primary" icon="edit" onClick={() => setDialogAddressVisible(true)} style={{ marginTop: '23px' }} ></Button>
              </Layout.Col> */}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Código Postal">
                  <Input className="input-style" placeholder="Ingrese código postal" type="number" value={createHolder.postal_code} onChange={(value) => onChangeCreate('postal_code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Contacto">
                  <Input className="input-style" placeholder="Ingrese contacto" value={createHolder.contact} onChange={(value) => onChangeCreate('contact', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail">
                  <Input className="input-style" placeholder="Ingrese email" type="email" value={createHolder.email} onChange={(value) => onChangeCreate('email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Telefono">
                  <Input className="input-style" placeholder="Ingrese telefono" type="number" value={createHolder.phone} onChange={(value) => onChangeCreate('phone', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail #2 (Opcional)">
                  <Input className="input-style" placeholder="Ingrese email" type="optional_email" value={createHolder.optional_email} onChange={(value) => onChangeCreate('optional_email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="¿Retiene?">
                  <Switch onText="Si" offText="No" value={createHolder.withhold} onChange={(value) => onChangeCreate('withhold', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="24">
                <Form.Item label="Obligaciones">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    value={createHolder.obligations}
                    multiple={true}
                    onChange={(value) => onChangeCreate('obligations', value)}>
                    {
                      obligationList.map((item) => {
                        return <Select.Option key={item.id} label={`(${item.code}) ${item.description}`} value={item.id}></Select.Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => holderCreateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal editar */}
      <Dialog
        title="EDITAR TITULAR"
        size="small"
        visible={dialogEditVisible}
        onCancel={() => setDialogEditVisible(false)}
        lockScroll={false}
        showClose={false}>
        <Dialog.Body>
          <Form className="es-CO" model={editHolder} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="4">
                <Form.Item label="Persona">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione persona"
                    value={editHolder.person_id}
                    onChange={(value) => {
                      listIdentificationType(value);
                      onChangeEdit("person_id", value);
                    }}>
                    {personList.map((item) => (
                      <Select.Option key={item.id} label={item.type} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="8">
                <Form.Item label="Convenio">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Convenio"
                    value={editHolder.agreement_id}
                    onChange={(value) => {
                      onChangeEdit("agreement_id", value);
                    }}>
                    {agreementList.map((item) => (
                      <Select.Option key={item.id} label={`${item.code} . ${item.name}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Cod A.E.">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione Cod A.E."
                    value={editHolder.economic_activity_id}
                    onChange={(value) => {
                      filter(value)
                      onChangeEdit("economic_activity_id", value);
                    }}>
                    {economicActivitiesList.map((item) => (
                      <Select.Option key={item.id} label={item.code} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Actividad Económica">
                  <Input className="input-style" placeholder="Actividad económica" value={economicActivity}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Tipo ID">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione Tipo ID"
                    value={editHolder.identification_type_id}
                    onChange={(value) => {
                      onChangeEdit("identification_type_id", value);
                    }}>
                    {identificationTypeList.map((item) => (
                      <Select.Option key={item.id} label={`(${item.abbreviation}) ${item.description}`} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              {(editHolder.identification_type_id == 6 && editHolder.person_id == 1) ? (
                <Layout.Col xs="24" sm="9">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" type="number" value={editHolder.identification} onChange={(value) => { onChangeEdit('identification', value); verificationCode(value, null) }}></Input>
                  </Form.Item>
                </Layout.Col>
              ) : (
                <Layout.Col xs="24" sm="9">
                  <Form.Item label="Identificación">
                    <Input className="input-style" placeholder="Ingrese identificación" maxLength={10} value={editHolder.identification} onChange={(value) => {
                      onChangeEdit("identification", value);
                    }}></Input>
                  </Form.Item>
                </Layout.Col>
              )}

              <Layout.Col xs="24" sm="3">
                <Form.Item label="D.V">
                  <Input className="input-style" placeholder="D.V" value={dv} readOnly></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>

            {(editHolder.person_id == 0 || editHolder.person_id == null) ? (
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Razón S/ Primer Nombre">
                    <Input className="input-style" placeholder="Ingrese razón s/ primer nombre" value={editHolder.first_name} onChange={(value) => onChangeEdit('first_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Segundo Nombre (Otros)">
                    <Input className="input-style" placeholder="Ingrese segundo nombre (Otros)" value={editHolder.second_name} onChange={(value) => onChangeEdit('second_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Primer Apellido">
                    <Input className="input-style" placeholder="Ingrese primer apellido" value={editHolder.first_surname} onChange={(value) => onChangeEdit('first_surname', value)}></Input>
                  </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="6">
                  <Form.Item label="Segundo Apellido">
                    <Input className="input-style" placeholder="Ingrese segundo pellido" value={editHolder.second_surname} onChange={(value) => onChangeEdit('second_surname', value)}></Input>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            ) : (
              <Layout.Row gutter="10">
                <Layout.Col xs="24" sm="24">
                  <Form.Item label="Razón Social">
                    <Input className="input-style" placeholder="Ingrese razón social" value={editHolder.first_name} onChange={(value) => onChangeEdit('first_name', value)}></Input>
                  </Form.Item>
                </Layout.Col>
              </Layout.Row>
            )}

            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Departamento">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione un departamento"
                    value={editHolder.department_id}
                    onChange={(value) => {
                      listCities(value);
                      onChangeEdit("department_id", value);
                    }}>
                    {departmentList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Ciudad">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    clearable={true} filterable={true}
                    placeholder="Seleccione una ciudad"
                    value={editHolder.city_id}
                    onChange={(value) => {
                      onChangeEdit("city_id", value);
                    }}>
                    {cityList.map((item) => (
                      <Select.Option key={item.id} label={item.name} value={item.id} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Dirección">
                  <Input className="input-style" onChange={(value) => { onChangeEdit("address", value) }} value={editHolder.address} />
                </Form.Item>
              </Layout.Col>
              {/* <Layout.Col xs="24" sm="2">
                <Button className="btn-create" type="primary" icon="edit" onClick={() => copyAdresToUpdate(editHolder.address)} style={{ marginTop: '23px' }} ></Button>
              </Layout.Col> */}
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Código Postal">
                  <Input className="input-style" placeholder="Ingrese código postal" type="number" value={editHolder.postal_code} onChange={(value) => onChangeEdit('postal_code', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="9">
                <Form.Item label="Contacto">
                  <Input className="input-style" placeholder="Ingrese contacto" value={editHolder.contact} onChange={(value) => onChangeEdit('contact', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail">
                  <Input className="input-style" placeholder="Ingrese email" type="email" value={editHolder.email} onChange={(value) => onChangeEdit('email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Telefono">
                  <Input className="input-style" placeholder="Ingrese telefono" type="number" value={editHolder.phone} onChange={(value) => onChangeEdit('phone', value)}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="12">
                <Form.Item label="E-mail #2 (Opcional)">
                  <Input className="input-style" placeholder="Ingrese email" type="optional_email" value={editHolder.optional_email} onChange={(value) => onChangeEdit('optional_email', value)}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="12">
                <Form.Item label="Retiene?">
                  <Switch onText="Si" offText="No" value={editHolder.withhold} onChange={(value) => onChangeEdit('withhold', value)}></Switch>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="10">
              <Layout.Col xs="24" sm="24">
                <Form.Item label="Obligaciones">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    value={editHolder.arrayObligations}
                    multiple={true}
                    onChange={(value) => onChangeEdit('obligations', value)}>
                    {
                      obligationList.map((item) => {
                        return <Select.Option key={item.id} label={`(${item.code}) ${item.description}`} value={item.id}></Select.Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => holderUpdateApi()}>Guardar</Button>
          <Button className="btn-cancel" onClick={() => cleanForm()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

      {/* Modal formato de dirección */}
      <Dialog
        title={haveErrors ?
          <Alert title={textErrors} type="error" showIcon={true} style={{ background: "#FF6A6A", width: "340px", float: "right" }} /> : ""
        }
        size="small"
        visible={dialogAddressVisible}
        onCancel={() => setDialogAddressVisible(false)}
        lockScroll={true}
        showClose={false}>
        <Dialog.Body>
          <Layout.Row style={{ marginBottom: "41px" }}>
            <p style={{ fontSize: "16px", color: "#ffffff", margin: "0" }}>INGRESE LA DIRECCIÓN</p>
          </Layout.Row>
          <Form className="es-CO" model={createAddress} labelWidth="120" labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Vía">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.road}
                    onChange={(value) => {
                      onChangeAddres("road", value);
                    }}>
                    {roadList.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="3">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number1} onChange={(value) => { onChangeAddres("number1", value); }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="1" sm="1">
                <label style={{ marginTop: "300px" }} >#</label>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number2} onChange={(value) => { onChangeAddres("number2", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="1">
                <Form.Item label="-">
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Número">
                  <Input className="input-style" value={createAddress.number3} onChange={(value) => { onChangeAddres("number3", value) }}></Input>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="5">
                <Form.Item label="Tipo inmueble">
                  <Select
                    className="input-style"
                    style={{ width: "100%" }}
                    placeholder="Seleccione"
                    value={createAddress.propertyType}
                    onChange={(value) => {
                      onChangeAddres("propertyType", value);
                    }}>
                    {propertyType.map((item) => (
                      <Select.Option key={item} label={item} value={item} />
                    ))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2" >
                <Form.Item label="Número">
                  <Input className="input-style" disabled={createAddress.propertyType != "Bloque"} value={createAddress.number4} onChange={(value) => { onChangeAddres("number4", value) }} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs="24" sm="2">
                <Form.Item label="Interior">
                  <Input className="input-style" value={createAddress.apto} onChange={(value) => { onChangeAddres("apto", value) }}></Input>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button className="btn-save" type="primary" onClick={() => saveAddress()}>Crear</Button>
          <Button className="btn-cancel" onClick={() => cleanFormAdres()}>Cancelar</Button>
        </Dialog.Footer>
      </Dialog>

    </div >
  );
}

const mapStateToProps = (state) => ({
  roles: state.auth.roles,
});

export default connect(mapStateToProps)(Holder);
