import axios from "../../utilities/axiosInstance";
import handleResponse from "../../utilities/responseReceiver";
import { Message } from "element-react";


/*------------------------------------
********CREAR GATEWAY************  
-------------------------------------*/
export async function gatewayProfileCreateRequest(value) {
    try {
        let { data } = await axios.post('/gateways', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al crear el gateway",
            type: 'error',
            customClass :"message-position"
        });
    }

}

/*------------------------------------
********ACTUALIZAR GATEWAY************  
-------------------------------------*/
export async function gatewayProfileUpdateRequest(value) {
    try {
        let { data } = await axios.put('/gateways', { data: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al actualizar el gateway",
            type: 'error',
            customClass :"message-position"
        });
    }

}

/*------------------------------------
********ELIMINAR GATEWAY***************
-------------------------------------*/
export async function gatewayDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/gateways?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el gateway",
            type: 'error',
            customClass :"message-position"
        });
    }

}

/*---------------------------------------------------
*****OBTENER PAGINACION****************
----------------------------------------------------*/
export async function paginationGatewayListRequest(value) {
    try {
        let { data } = await axios.get("/gateways/pagination", { params: value });

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener la lista paginada de gateways",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*---------------------------------------------------
*****OBTIENE LOS ARCHIVOS POR DISPOSITIVO****************
----------------------------------------------------*/
export async function getFilesByGateway(value) {
    try {
        let { data } = await axios.get("/getFilesByGateway", { params: value }); 

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al obtener los archivos por dispositivos",
            type: 'error',
            customClass :"message-position"
        });
    }
}

/*------------------------------------
********ELIMINAR IMAGEN***************
-------------------------------------*/
export async function fileDeleteRequest(value) {
    try {
        let { data } = await axios.delete(`/gateways/delete_file?id=${value}`);

        if (handleResponse(data)) {
            let values = data.data;
            return values;
        } else {
            return false;
        }
    } catch (error) {
        Message({
            showClose: true,
            message: "Hubo un problema al eliminar el archivo",
            type: 'error',
            customClass :"message-position"
        });
    }
}
